import { Select, Spin } from 'antd';
import { useCurrencies } from '@gowgates/utils';

type AntSelectProps = React.ComponentProps<typeof Select>;
type CurrencySelectProps = AntSelectProps & {
  showFullNameInLabel?: boolean;
};

export const CurrencySelect = ({
  showFullNameInLabel = false,
  onChange,
  disabled = false,
  ...props
}: CurrencySelectProps) => {
  const { currencies, getCurrencyLabel } = useCurrencies();

  if (!currencies) return <Spin size="small" />;

  return (
    <Select
      showSearch
      optionLabelProp="label"
      optionFilterProp="children"
      popupMatchSelectWidth={false}
      onChange={onChange}
      {...props}
      disabled={disabled}
    >
      {currencies.map((currency) => (
        <Select.Option
          value={currency.key}
          label={getCurrencyLabel(currency, { showFullNameInLabel })}
          key={currency.key}
        >
          {getCurrencyLabel(currency, { showFullNameInLabel: true })}
        </Select.Option>
      ))}
    </Select>
  );
};
