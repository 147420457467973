import { DynamicForm } from '@gowgates/dynamic-fields';

import useFileConfig from '../../../hooks/useFileConfig';

type PolicyDetailsFormProps = {
  nested?: boolean;
};

const PolicyDetailsForm = ({ nested = false }: PolicyDetailsFormProps) => {
  const { coverStructure } = useFileConfig();
  const namespace = nested ? ['cover', 'data'] : ['data'];

  return <DynamicForm fields={coverStructure} namespace={namespace} />;
};

export default PolicyDetailsForm;
