import { Divider, Flex, Form, Radio, Select } from 'antd';

import { t } from '@gowgates/utils';
import { useResourceUsersQuery } from '@gowgates/core';

type FiltersProps = {
  taskNameList: string[];
};

const sortByKeys = ['createdAt', 'dueAt', 'completedAt'];

export const Filters = ({ taskNameList }: FiltersProps) => {
  const { userFilters = [] } = useResourceUsersQuery();
  const form = Form.useFormInstance();

  return (
    <div style={{ width: 300 }}>
      <Form.Item name="userId" label={t('activerecord.attributes.task.userId')}>
        <Select
          mode="multiple"
          optionFilterProp="label"
          options={userFilters.map(({ text, value }) => ({ label: text, value: String(value) }))}
        />
      </Form.Item>

      <Divider />

      <Form.Item name="name" label={t('activerecord.attributes.task.name')}>
        <Select
          mode="multiple"
          optionFilterProp="label"
          options={taskNameList.map((text) => ({ label: text, value: text }))}
        />
      </Form.Item>

      <Divider />

      <Flex vertical gap={8}>
        <Form.Item name="sortBy" label={t('globals.sortBy')} className="no-margin">
          <Select
            optionFilterProp="label"
            options={sortByKeys.map((key) => ({
              label: t(`activerecord.attributes.task.${key}`),
              value: key
            }))}
            allowClear
            onChange={(value) => {
              if (!value) {
                form.setFieldValue('order', null);
              } else if (!form.getFieldValue('order')) form.setFieldValue('order', 'asc');
            }}
          />
        </Form.Item>

        <Form.Item name="order">
          <Radio.Group>
            <Radio value="asc">{t('globals.oldest')}</Radio>
            <Radio value="desc">{t('globals.mostRecent')}</Radio>
          </Radio.Group>
        </Form.Item>
      </Flex>
    </div>
  );
};
