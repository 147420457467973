import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { Table } from 'antd';

import { t } from '@gowgates/utils';
import { AvatarAndName, User } from '@gowgates/core';

import { getPermission } from '../../api/endpoints';
import FullLoader from '../../components/FullLoader';
import UserCol from '../users/UserCol';

const PermissionUsersTable = () => {
  const permissionId = Number(useParams().permissionId);

  const { isLoading, data: permission } = useQuery({
    queryKey: ['permission', permissionId],
    queryFn: () => getPermission(permissionId)
  });

  if (isLoading) {
    return <FullLoader />;
  }

  if (!permission) return;

  return (
    <Table
      dataSource={permission.members}
      rowKey={(record) => record.id}
      loading={isLoading}
      pagination={false}
      className="box-table"
    >
      <Table.Column
        title={t('activerecord.attributes.user.name')}
        key="name"
        render={(record) => (
          <UserCol user={record}>
            <AvatarAndName user={record} />
          </UserCol>
        )}
      />
      <Table.Column<User>
        title={t('activerecord.attributes.user.email')}
        dataIndex="email"
        render={(text, record) => <UserCol user={record}>{text}</UserCol>}
      />
    </Table>
  );
};

export default PermissionUsersTable;
