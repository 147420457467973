import numeral, { Numeral } from 'numeral';
import currencies from '../resources/currencies.json';
import { DEFAULT_CURRENCY, PRICE_FORMAT } from './constants';
import { t } from './I18n';

export const EMPTY_TEXT = '-';

type Currency = {
  key: string;
  name: string;
  symbol: string;
};

export const currenciesHash = currencies.reduce(function (
  map: Record<string, Currency>,
  currency: Currency
) {
  map[currency.key] = currency;
  return map;
}, {});

export const displayText = (text?: string) => {
  if (!text) {
    return EMPTY_TEXT;
  }

  return text;
};

export const findCurrency = (currency?: string) => currenciesHash[currency || ''];

export const formatPrice = ({
  value,
  currency = DEFAULT_CURRENCY,
  hideSymbol = false
}: {
  value?: string | number | Numeral;
  currency?: string;
  hideSymbol?: boolean;
}) => {
  if (value === undefined || value === null) {
    return EMPTY_TEXT;
  }

  const formattedValue = numeral(value).format(PRICE_FORMAT);

  return hideSymbol ? formattedValue : `${findCurrency(currency)?.symbol} ${formattedValue}`;
};

export const formatBoolean = (value?: boolean) => {
  if (value === null || value === undefined) {
    return '-';
  }

  return t(`globals.yesNo.${value}`);
};

export const isHtmlContentEmpty = (htmlString?: string) => {
  if (!htmlString) return true;

  // remove all html tags
  const strippedString = htmlString.replace(/<[^>]*>/g, '');

  // remove blank spaces, break lines, tabs
  const cleanedString = strippedString.replace(/\s+/g, '');

  return !cleanedString;
};
