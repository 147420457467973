import { App } from 'antd';
import { AxiosError } from 'axios';

import { isUnprocessableEntity } from '@gowgates/api-client';
import { ExtendedDangerPopconfirm } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';

import { deleteDocumentStructure } from '../../../api/endpoints';

type DeleteDocumentStructureProps = {
  id: number;
  claimStructureId: number;
};
const DeleteDocumentStructure = ({ id, claimStructureId }: DeleteDocumentStructureProps) => {
  const { message } = App.useApp();
  const onError = (error: AxiosError<{ errors: { base: string } }>) => {
    if (isUnprocessableEntity(error) && error?.response?.data.errors.base) {
      message.error(error.response.data.errors.base);
    }
  };

  const onSuccess = () => {
    message.success(t('documentStructure.deletedMessage'));
  };

  return (
    <div className="d-flex justify-content-end">
      <ExtendedDangerPopconfirm
        deleteFn={() => deleteDocumentStructure(claimStructureId, id)}
        invalidateQueries={['documentStructures', claimStructureId]}
        permission
        onSuccess={onSuccess}
        onError={onError}
      />
    </div>
  );
};

export default DeleteDocumentStructure;
