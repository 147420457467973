import { ModelWithData } from '@gowgates/utils';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Col, Space, Tooltip, Typography } from 'antd';
import { camelCase } from 'change-case';
import { DynamicFormatter } from '../../formatters/DynamicFormatter';
import { ConditionalField } from '../ConditionalField';
import { Field } from '../../types';

type ConditionalDescriptionProps = {
  model?: ModelWithData;
  field: Field;
};

export const ConditionalDescription = ({ model, field }: ConditionalDescriptionProps) => {
  if (!model) return;

  const errors = (model.data?.errors?.[camelCase(field.name)] || []).join(', ');

  return (
    <ConditionalField model={model} field={field}>
      <Col span={field.columns || 24}>
        <dt>
          <Space>
            <span>{field.label}</span>
            {errors && (
              <Tooltip title={errors}>
                <Typography.Text type="danger">
                  <ExclamationCircleFilled />
                </Typography.Text>
              </Tooltip>
            )}
          </Space>
        </dt>
        <dd>
          <DynamicFormatter data={model.data} field={field} />
        </dd>
      </Col>
    </ConditionalField>
  );
};

export default ConditionalDescription;
