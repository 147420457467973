import { useMemo, useRef, useState } from 'react';
import { Select, Spin } from 'antd';
import debounce from 'lodash/debounce';

import { FormItem } from '@gowgates/dynamic-fields';

import { getClaims } from '../../../api/endpoints';
import useClaim from '../../../hooks/useClaim';
import { ClaimWithStructure } from '../../../types';

type DebounceSelectProps = {
  claim: ClaimWithStructure;
  fetchOptions: (q: string) => Promise<{ label: string; value: number }[]>;
  debounceTimeout?: number;
};

const DebounceSelect = ({ claim, fetchOptions, debounceTimeout = 500 }: DebounceSelectProps) => {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([
    { value: claim.conflictClaimId, label: claim.conflictClaim?.label }
  ]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <FormItem name="conflictClaimId" model="claim" required>
      <Select
        filterOption={false}
        allowClear
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        showSearch
        placeholder="Type claim ID or claimant name"
      >
        {options.map((option) => (
          <Select.Option value={option.value} key={option.value}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </FormItem>
  );
};

const SearchClaims = () => {
  const { claim } = useClaim();

  const fetchClaims = (query: string) =>
    getClaims({ q: query }).then((data) =>
      data.rows.map((c) => ({
        label: `Claim #${c.id}${c.name ? ` - ${c.name}` : ''} - managed by ${c.assignee.name}`,
        value: c.id
      }))
    );

  return <DebounceSelect fetchOptions={fetchClaims} claim={claim} />;
};

export default SearchClaims;
