import { useQuery } from '@tanstack/react-query';

import { t } from '@gowgates/utils';

import { ClaimWithStructure } from '../../../types';
import { getClaimDocuments } from '../../../api/endpoints';
import DocumentDescription from './Document';

type DocumentsDescriptionProps = {
  claim: ClaimWithStructure;
};
const DocumentsDescription = ({ claim }: DocumentsDescriptionProps) => {
  const { data: documents } = useQuery({
    queryKey: ['claim', claim.id, 'documents'],
    queryFn: () => getClaimDocuments(claim.id)
  });

  return (
    <dl className="cg-descriptions">
      {claim.structure.documents.map((documentStructure) => (
        <DocumentDescription
          documents={documents}
          structure={documentStructure}
          key={documentStructure.name}
        />
      ))}

      <dt>{t('document.other')}</dt>

      <dd>
        {documents
          ?.filter((document) => !document.type)
          .map((document) => (
            <p key={document.id}>
              <span>{document.name}</span>
              <span> - </span>
              {document.file && (
                <a href={document.file.url} target="_blank" rel="noopener noreferrer">
                  {document.file.name}
                </a>
              )}
            </p>
          ))}
      </dd>
    </dl>
  );
};

export default DocumentsDescription;
