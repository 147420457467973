import { useParams } from 'react-router';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { t } from '@gowgates/utils';

import { Claim, ClaimWithStructure, Section } from '../types';
import { getClaim } from '../api/endpoints';
import useStructures from './useStructures';

const useClaim = () => {
  const claimId = Number(useParams().claimId);
  const queryClient = useQueryClient();
  const { structures } = useStructures();

  const findStructure = (data: Claim) => structures?.find((s) => s.id === data.structureId)?.data;

  const getClaimAndStructure = () =>
    getClaim(claimId)
      .then(
        (data) =>
          ({
            ...data,
            structure: findStructure(data),
            conflict: false
          }) as ClaimWithStructure
      )
      .catch((error) => {
        if (error.response?.status === 409) {
          return { id: claimId, conflict: true } as ClaimWithStructure;
        }

        throw error;
      });

  const {
    isLoading,
    isFetched,
    data: claim
  } = useQuery({
    queryKey: ['claim', claimId],
    queryFn: getClaimAndStructure,
    enabled: !Number.isNaN(claimId),
    staleTime: 120 * 1000 // claim withh refetch every 2 minutes
  });

  const refreshClaim = (data: Claim) => {
    if (Number.isNaN(claimId)) return;

    const updatedClaim = { ...data, structure: findStructure(data) };
    queryClient.setQueryData(['claim', claimId], updatedClaim);
  };

  // Keep it in a function to avoid processing this every time a claim is fetched
  const allItems = () =>
    Object.values(claim?.sections || [])
      .map((section: Section) => section?.items)
      .flat()
      .filter((section) => !!section)
      .map((item) => ({
        ...item,
        label: `${t('item.id', { id: item.id })}${item.data.name ? ` - ${item.data.name}` : ''}`
      }));

  return {
    isLoading,
    isFetched,
    /* TODO: review this -> for now the ! is forcing typescript to always expect it not be undefined */
    claim: claim!,
    refreshClaim,
    claimId,
    allItems
  };
};

export default useClaim;
