import { useContext } from 'react';
import { Button, Space } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import { t } from '@gowgates/utils';

import { CreateStepsContext } from '../../../contexts/CreateStepsContext';

const NextStep = () => {
  const { currentTab } = useContext(CreateStepsContext);

  const nextLabel =
    currentTab.key === 'declaration' ? t('globals.submit') : t('globals.saveAndNext');

  return (
    <Button type="primary" htmlType="submit">
      <Space>
        {nextLabel}
        <ArrowRightOutlined />
      </Space>
    </Button>
  );
};

export default NextStep;
