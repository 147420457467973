import { Table, TableProps } from 'antd';
import { Link } from 'react-router-dom';

import { useTable } from '@gowgates/core';
import { AnyObject } from '@gowgates/utils';
import { DataPagination } from '@gowgates/api-client';

import useLegacyTableSearch from '../../../hooks/useLegacyTableSearch';
import useAppConfigs from '../../../hooks/useAppConfigs';
import { convertToFilter, TableSorter } from '../../../utils/tables';
import { Claim } from '../../../types';

type ClaimsTableProps = {
  claims?: Claim[];
  loading: boolean;
  pagination?: DataPagination;
  sorter: TableSorter;
  filters: AnyObject;
};

const ClaimsTable = ({ claims = [], loading, pagination, sorter, filters }: ClaimsTableProps) => {
  const { appConfigs } = useAppConfigs();

  const useEllipsis = true;
  const { handleTableChange } = useTable<Claim>();
  const { columnProps } = useLegacyTableSearch({ useEllipsis, columnWidth: 150 });

  const columns: TableProps<Claim>['columns'] = [
    { ...columnProps('id', 'claim'), render: (text) => <Link to={`/claims/${text}`}>{text}</Link> },
    {
      ...columnProps('status', 'claim', {
        sorter,
        filters,
        filterOptions: convertToFilter(appConfigs.claim.statuses),
        type: 'enum'
      })
    },
    {
      ...columnProps('liability', 'claim', {
        sorter,
        filters,
        filterOptions: convertToFilter(appConfigs.claim.liabilities),
        type: 'enum'
      })
    },
    { ...columnProps('clientName', 'payment', { sorter, filters, textFilter: true }) },
    { ...columnProps('claimantName', 'payment', { sorter, filters, textFilter: true }) },
    { ...columnProps('createdAt', 'claim', { type: 'datetime' }) },
    { ...columnProps('approvedAt', 'claim', { type: 'datetime' }) },
    { ...columnProps('approvedBy', 'claim') },
    { ...columnProps('declinedAt', 'claim', { type: 'datetime' }) },
    { ...columnProps('declinedBy', 'claim') },
    { ...columnProps('closedAt', 'claim', { type: 'datetime' }) },
    { ...columnProps('closedBy', 'claim') }
  ];

  return (
    <Table
      dataSource={claims}
      rowKey={(record) => record.id}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      scroll={{
        x: 1800
      }}
      columns={columns}
    />
  );
};

export default ClaimsTable;
