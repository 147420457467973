import { Form, Input, Select } from 'antd';

import { FormItem } from '@gowgates/dynamic-fields';

import useAppConfigs from '../../hooks/useAppConfigs';
import { Integration } from '../../types';

const IntegrationForm = () => {
  const { appConfigs } = useAppConfigs();

  const providerUpdated = (prevValues: Integration, currentValues: Integration) =>
    prevValues.provider !== currentValues.provider;

  return (
    <>
      <FormItem name="provider" model="integration">
        <Select options={appConfigs.integration.providers} />
      </FormItem>

      <Form.Item noStyle shouldUpdate={providerUpdated}>
        {({ getFieldValue }) =>
          getFieldValue('provider') === 'insight' && (
            <>
              <FormItem name={['settings', 'ledger']} model="integration">
                <Input />
              </FormItem>
              <FormItem name={['settings', 'username']} model="integration">
                <Input />
              </FormItem>
              <FormItem name={['settings', 'password']} model="integration">
                <Input.Password />
              </FormItem>
            </>
          )
        }
      </Form.Item>
    </>
  );
};

export default IntegrationForm;
