import { Skeleton, Divider } from 'antd';

import { t } from '@gowgates/utils';

import Note from '../../../../../../components/Notes/Note';
import { Note as NoteType } from '../../../../../../types';

type ItemNotesProps = {
  notesCount: number;
  notes: NoteType[];
};
const ItemNotes = ({ notesCount, notes = [] }: ItemNotesProps) => {
  // No notes in item
  if (notesCount === 0) {
    return null;
  }

  // item has notes but they are not loaded
  if (notesCount !== 0 && notes.length === 0) {
    return (
      <>
        <Divider />

        <h4>{t('activerecord.models.note.other')}</h4>

        <Skeleton avatar paragraph={{ rows: 4 }} />
      </>
    );
  }

  return (
    <>
      <Divider />

      <h4>{t('activerecord.models.note.other')}</h4>

      {notes.map((note) => (
        <Note note={note} key={note.id} />
      ))}
    </>
  );
};

export default ItemNotes;
