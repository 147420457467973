import React, { useState } from 'react';
import { Button, Modal, Form, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ExtendedDangerPopconfirm } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';

import { Integration } from '../../types';
import { deleteIntegration, updateIntegration } from '../../api/endpoints';
import ModalFooter from '../../components/ModalFooter';
import IntegrationForm from './Form';

type ShowIntegrationModalProps = {
  isOpen?: boolean;
  setOpen: (open: boolean) => void;
  integration?: Integration;
};

const ShowIntegrationModal = ({
  isOpen = false,
  setOpen,
  integration
}: ShowIntegrationModalProps) => {
  const queryClient = useQueryClient();
  const [isEditing, setEditing] = useState(false);
  const [form] = Form.useForm();

  const closeModal = () => {
    setOpen(false);
    setEditing(false);
    form.resetFields();
  };

  const startEdit = () => {
    setTimeout(() => {
      form.resetFields();
    }, 50);
    setEditing(true);
  };

  const stopEdit = () => {
    setEditing(false);
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: Integration) =>
      integration ? updateIntegration(integration.id, values) : Promise.reject(),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({ queryKey: ['integrations'] });
    }
  });

  return (
    <Modal title="Integration details" open={isOpen} onCancel={closeModal} footer={null}>
      {isEditing ? (
        <Form
          form={form}
          layout="vertical"
          onFinish={mutate}
          disabled={isLoading || !integration}
          initialValues={integration}
        >
          <IntegrationForm />

          <ModalFooter spaceBetween>
            <div>
              {integration?.permissions?.destroy && (
                <ExtendedDangerPopconfirm
                  title={t('integration.confirmDelete')}
                  deleteFn={() => deleteIntegration(integration.id)}
                  onSuccess={closeModal}
                  invalidateQueries={['integrations']}
                  permission={integration.permissions?.destroy}
                  tooltip={false}
                >
                  <Button danger type="default" icon={<DeleteOutlined />}>
                    {t('integration.delete')}
                  </Button>
                </ExtendedDangerPopconfirm>
              )}
            </div>
            <Space>
              <Button onClick={stopEdit}>{t('globals.cancel')}</Button>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {t('globals.save')}
              </Button>
            </Space>
          </ModalFooter>
        </Form>
      ) : (
        integration && (
          <>
            <dl className="cg-descriptions">
              <dt>{t('activerecord.attributes.integration.provider')}</dt>
              <dd>{integration.provider}</dd>

              <dt>{t('activerecord.attributes.integration.ledger')}</dt>
              <dd>{integration.settings?.ledger}</dd>

              <dt>{t('activerecord.attributes.integration.username')}</dt>
              <dd>{integration.settings?.username}</dd>
            </dl>

            {integration.permissions?.update && (
              <ModalFooter>
                <Button onClick={startEdit} key="start-edit">
                  {t('globals.edit')}
                </Button>
              </ModalFooter>
            )}
          </>
        )
      )}
    </Modal>
  );
};

export default ShowIntegrationModal;
