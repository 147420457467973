import { DynamicDescriptions } from '@gowgates/dynamic-fields';

import useFileConfig from '../../../hooks/useFileConfig';
import useClaim from '../../../hooks/useClaim';

const ClaimantDescriptions = () => {
  const { claimantStructure } = useFileConfig();
  const { claim } = useClaim();

  return <DynamicDescriptions model={claim.claimant} fields={claimantStructure} />;
};

export default ClaimantDescriptions;
