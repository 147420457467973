import { Tag } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import { t } from '@gowgates/utils';
import { User } from '@gowgates/core';

type UserBlockedTagProps = {
  status: User['status'];
};

const UserBlockedTag = ({ status }: UserBlockedTagProps) => {
  if (status === 'active') {
    return null;
  }

  return (
    <Tag icon={<CloseCircleOutlined />} color="error">
      {t('user.statuses.blocked')}
    </Tag>
  );
};

export default UserBlockedTag;
