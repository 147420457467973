import { Table, TableProps } from 'antd';
import { camelCase } from 'change-case';

import { t, EMPTY_TEXT, formatDate, AnyObject } from '@gowgates/utils';
import { useTable } from '@gowgates/core';
import { DataPagination } from '@gowgates/api-client';

import useLegacyTableSearch from '../../../hooks/useLegacyTableSearch';
import useAppConfigs from '../../../hooks/useAppConfigs';
import { convertToFilter, TableSorter } from '../../../utils/tables';
import ItemStatusTag from '../../claim/components/Items/StatusTag';
import { AppConfigs } from '../../../types/app-configs';
import { Item } from '../../../types';

type PaymentsTableProps = {
  payments?: AnyObject[];
  loading: boolean;
  pagination?: DataPagination;
  sorter: TableSorter;
  filters: AnyObject;
};

const PaymentsTable = ({
  payments = [],
  loading,
  pagination,
  sorter,
  filters
}: PaymentsTableProps) => {
  const { appConfigs } = useAppConfigs();

  const useEllipsis = true;

  const { handleTableChange } = useTable<AnyObject>();
  const { columnProps } = useLegacyTableSearch({ useEllipsis, columnWidth: 150 });

  const columns: TableProps<AnyObject>['columns'] = [
    { ...columnProps('claimId', 'payment', { sorter }) },
    {
      ...columnProps('status', 'item', {
        sorter,
        filters,
        filterOptions: convertToFilter(appConfigs.item.statuses)
      }),
      render: (_text, record: Item) => <ItemStatusTag item={record} />
    },
    { ...columnProps('clientName', 'payment', { sorter, filters, textFilter: true }) },
    { ...columnProps('claimantName', 'payment', { sorter, filters, textFilter: true }) },
    { ...columnProps('bsb', 'payment') },
    { ...columnProps('accountNumber', 'payment') },
    { ...columnProps('swift', 'payment') },
    { ...columnProps('iban', 'payment') },
    {
      ...columnProps('paymentAmount', 'item', {
        title: t('activerecord.attributes.item.amount'),
        sorter,
        type: 'money'
      })
    },
    {
      ...columnProps('paidAt', 'item', {
        sorter,
        type: 'date'
      })
    }
  ];

  const structureNames = (appConfigs as AppConfigs).taskStructure.itemTaskNames.map(
    (taskStructure) => taskStructure.value
  );

  [...new Set(structureNames)].forEach((structureName) => {
    columns.push({
      ...columnProps(structureName, 'item', { title: structureName }),
      render: (_text, record) => {
        const value = record.history[camelCase(structureName)];

        return value ? formatDate(value, 'minute') : EMPTY_TEXT;
      }
    });
  });

  return (
    <Table
      dataSource={payments}
      rowKey={(record) => record.id}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      scroll={{
        x: 2500
      }}
      columns={columns}
    />
  );
};

export default PaymentsTable;
