import { DynamicDescriptions } from '@gowgates/dynamic-fields';

import useClaim from '../../../hooks/useClaim';

const ClaimDescriptions = () => {
  const { claim } = useClaim();

  return <DynamicDescriptions model={claim} fields={claim.structure.claim} />;
};

export default ClaimDescriptions;
