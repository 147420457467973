import React from 'react';
import { Button, Space, App } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t } from '@gowgates/utils';
import { assignTasks, assignTasksToMe } from '../../api/endpoints';
import ReassignBtn from '../claim/components/ReassignBtn';
import usePermissions from '../../hooks/usePermissions';

type Props = {
  selectedRows: React.Key[];
  cacheKey: (string | number)[];
  addControlHeightOnEmpty?: boolean;
};

export const ReassignTasks = ({
  selectedRows,
  cacheKey,
  addControlHeightOnEmpty = true
}: Props) => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  const { permissions } = usePermissions();

  const refreshTasks = () => queryClient.invalidateQueries({ queryKey: cacheKey });

  const assignMutation = useMutation({
    mutationFn: (userId: string) => assignTasks(selectedRows, userId),
    onSuccess: (data) => {
      refreshTasks();
      if (data.name) {
        message.success(t('task.reassignedBulk', { count: data.count, name: data.name }));
      } else {
        message.success(t('task.unassignedBulk', { count: data.count }));
      }
    }
  });

  const assignToMeMutation = useMutation({
    mutationFn: () => assignTasksToMe(selectedRows),
    onSuccess: (data) => {
      refreshTasks();
      message.success(t('task.reassignedToMeBulk', { count: data.count }));
    }
  });

  if (selectedRows.length === 0) {
    return addControlHeightOnEmpty ? <div className="spacer control-height" /> : null;
  }

  return (
    <Space>
      {permissions.assignTasksBulk && (
        <ReassignBtn
          onChange={assignMutation.mutate}
          label={t('task.reassignSelected')}
          dropdown="button"
          showRole
        />
      )}

      {permissions.assignTasksToMeBulk && (
        <Button onClick={() => assignToMeMutation.mutate()}>
          {t('task.reassignSelectedToMe')}
        </Button>
      )}
    </Space>
  );
};
