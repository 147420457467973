import { Col, Divider, Row, Space, Typography } from 'antd';
import { PlusOutlined, InfoCircleTwoTone } from '@ant-design/icons';

import { t } from '@gowgates/utils';

import { SectionStructure } from '../../../../types';

type SectionHeaderProps = {
  sectionStructure: SectionStructure;
  showSectionAttributes: () => void;
  addItem: () => void;
  canCreateItems: boolean;
};

const SectionHeader = ({
  sectionStructure,
  showSectionAttributes,
  addItem,
  canCreateItems
}: SectionHeaderProps) => (
  <header className="box-header">
    <Row align="middle">
      <Col span={20}>
        <Divider orientation="left">
          <Space>
            <span>{sectionStructure.name}</span>

            <Typography.Link onClick={showSectionAttributes}>
              <InfoCircleTwoTone />
            </Typography.Link>
          </Space>
        </Divider>
      </Col>
      <Col span={4} className="d-flex justify-content-end">
        {canCreateItems && (
          <Typography.Link onClick={addItem}>
            <Space>
              <PlusOutlined />
              {t('item.add')}
            </Space>
          </Typography.Link>
        )}
      </Col>
    </Row>
  </header>
);

export default SectionHeader;
