import { useState } from 'react';
import { Button, Form, Input, Row, Col, Select, Space, Typography } from 'antd';
import { PlusOutlined, DownloadOutlined, SearchOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';

import { CgDatePicker } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';

import { getClaimNotes } from '../../../../api/endpoints';
import useResourceUsers from '../../../../hooks/useResourceUsers';
import { backendURL } from '../../../../utils/tenants';
import useAppConfigs from '../../../../hooks/useAppConfigs';
import { ClaimWithStructure, Note } from '../../../../types';

type NotesHeaderProps = {
  claim: ClaimWithStructure;
  notes: Note[];
  showModal: () => void;
};

const NotesHeader = ({ claim, notes, showModal }: NotesHeaderProps) => {
  const [isFilterOpen, setFilterOpen] = useState(false);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { appConfigs } = useAppConfigs();

  const { users } = useResourceUsers();

  const onChangeQueries = () => {
    getClaimNotes(claim.id, form.getFieldsValue()).then((data) => {
      queryClient.setQueryData(['claim', claim.id, 'notes'], data);
    });
  };

  return (
    <Form form={form} layout="vertical" initialValues={{}}>
      <Row gutter={10} className="spacer-bottom" align="middle">
        <Col span={9}>
          <Form.Item name="q" className="no-margin">
            <Input
              prefix={<SearchOutlined />}
              onPressEnter={onChangeQueries}
              onChange={(event) => {
                if (!event.target.value) {
                  onChangeQueries();
                }
              }}
              placeholder="Search in notes"
              allowClear
            />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Typography.Link onClick={() => setFilterOpen(!isFilterOpen)}>
            Advanced search
          </Typography.Link>
        </Col>
        <Col span={11} className="d-flex justify-content-end">
          <Space>
            {claim.permissions.exportNotes && notes.length > 0 && (
              <Button
                href={`${backendURL}/claims/${claim.id}/notes/export`}
                target="_blank"
                icon={<DownloadOutlined />}
              >
                Download all notes
              </Button>
            )}

            {claim.permissions.createNote && (
              <Button type="primary" ghost icon={<PlusOutlined />} onClick={showModal}>
                {t('note.new')}
              </Button>
            )}
          </Space>
        </Col>
      </Row>

      <div className={`notes-filters${isFilterOpen ? ' open' : ''}`}>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item name="direction">
              <Select
                options={appConfigs.note.allDirections}
                placeholder={t('activerecord.attributes.note.direction')}
                onChange={onChangeQueries}
                allowClear
                mode="multiple"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="nature">
              <Select
                options={appConfigs.note.natures}
                placeholder={t('activerecord.attributes.note.nature')}
                onChange={onChangeQueries}
                allowClear
                mode="multiple"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="recipient">
              <Select
                options={appConfigs.note.recipients}
                placeholder={t('activerecord.attributes.note.recipient')}
                onChange={onChangeQueries}
                allowClear
                mode="multiple"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="user_id">
              <Select
                options={users}
                fieldNames={{ value: 'id', label: 'name' }}
                placeholder={t('activerecord.attributes.note.user')}
                onChange={onChangeQueries}
                allowClear
                mode="multiple"
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item name="created_at_from">
              <CgDatePicker placeholder="Created from" onChange={onChangeQueries} allowClear />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item name="created_at_until">
              <CgDatePicker placeholder="Created until" onChange={onChangeQueries} allowClear />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="sort">
              <Select
                options={[
                  { value: 'createdAt', label: 'Show most recent first' },
                  { value: '-createdAt', label: 'Show most recent last' }
                ]}
                onChange={onChangeQueries}
              />
            </Form.Item>
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default NotesHeader;
