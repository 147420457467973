import { Space } from 'antd';

import { t } from '@gowgates/utils';
import { useTenantQuery } from '@gowgates/claims-gateway-shared';

export const Summary = () => {
  const { tenant } = useTenantQuery();

  if (!tenant) {
    return;
  }

  return (
    <aside className="page-summary">
      <Space direction="vertical" size="middle" className="w-100">
        <Space direction="vertical" className="text-center w-100">
          <img src={tenant.logo?.url} alt={t('app.name')} style={{ maxWidth: 150 }} />
          <p>{tenant.name}</p>
        </Space>
      </Space>
    </aside>
  );
};
