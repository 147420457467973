import React, { useEffect, useRef, useState } from 'react';
import { Form, Input, InputRef, Space, Tooltip, Typography } from 'antd';
import { EditOutlined, EnterOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';

import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';

import { sendClaimAction } from '../../../../api/endpoints';
import useClaim from '../../../../hooks/useClaim';
import { Claim, ClaimWithStructure } from '../../../../types';

type ClaimEditableFieldProps = {
  claim: ClaimWithStructure;
  field: string;
  prefix?: string;
  defaultValue?: string;
  permission?: boolean;
  formatWith?: (o: { value: string | number }) => string | number;
  action: string;
  infoElement?: React.ReactNode;
};

const ClaimEditableField = ({
  claim,
  field,
  prefix = '',
  defaultValue = '',
  permission = false,
  formatWith = ({ value }) => value,
  action,
  infoElement
}: ClaimEditableFieldProps) => {
  const inputRef = useRef<InputRef>(null);
  const [isEditing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const { refreshClaim } = useClaim();

  const { mutate } = useMutation({
    mutationFn: (values: Partial<Claim>) => sendClaimAction(claim.id, action, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      setEditing(false);
      refreshClaim(data);
    }
  });

  useEffect(() => {
    if (isEditing) {
      inputRef?.current?.focus();
    }
  }, [isEditing]);

  if (isEditing) {
    return (
      <Form form={form} initialValues={{ [field]: (claim as any)[field] }} onFinish={mutate}>
        <FormItem name={field} className="no-margin">
          <Input
            onKeyUp={(event) => {
              if (event.keyCode === 27) {
                setEditing(false);
              }
            }}
            addonBefore={prefix}
            id={`editable-${field}`}
            ref={inputRef}
            suffix={<EnterOutlined />}
          />
        </FormItem>
      </Form>
    );
  }

  return (
    <div className="d-flex justify-content-between w-100">
      {formatWith({ value: ((claim as any)[field] as string | number) || defaultValue })}

      <Space>
        {infoElement}
        {permission && (
          <Tooltip title={t('globals.edit')}>
            <Typography.Link onClick={() => setEditing(true)}>
              <EditOutlined />
            </Typography.Link>
          </Tooltip>
        )}
      </Space>
    </div>
  );
};

export default ClaimEditableField;
