import { useState } from 'react';
import { Modal, Button, Form, App, Space } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { addErrorsFromAPIInForm, t } from '@gowgates/utils';

import { Section, SectionStructure } from '../../../../types';
import { updateSection } from '../../../../api/endpoints';
import SectionDescription from '../../descriptions/Section';
import SectionForm from '../../forms/Section';
import ModalFooter from '../../../../components/ModalFooter';
import DeleteSectionBtn from './DeleteSectionBtn';

type SectionAttributesModalProps = {
  isOpen?: boolean;
  setOpen: (open: boolean) => void;
  structure: SectionStructure;
  section: Section;
};

const SectionAttributesModal = ({
  isOpen = false,
  setOpen,
  structure,
  section
}: SectionAttributesModalProps) => {
  const { message } = App.useApp();
  const claimId = Number(useParams().claimId);
  const queryClient = useQueryClient();
  const [isEditing, setEditing] = useState(false);
  const [form] = Form.useForm();

  const startEdit = () => {
    setTimeout(() => {
      form.resetFields();
    }, 50);
    setEditing(true);
  };

  const stopEdit = () => {
    setEditing(false);
  };

  const closeModal = () => {
    setOpen(false);
    stopEdit();
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => updateSection(claimId, section.id, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['claim', claimId] });
      message.success(t('section.saved'));
      stopEdit();
    }
  });

  return (
    <Modal title={structure.name} open={isOpen} onCancel={closeModal} footer={null}>
      {isEditing ? (
        <Form
          form={form}
          initialValues={section}
          layout="vertical"
          onFinish={mutate}
          disabled={isLoading}
        >
          <SectionForm sectionStructure={structure} />

          <ModalFooter spaceBetween>
            <div>
              <DeleteSectionBtn section={section} />
            </div>
            <Space>
              <Button onClick={stopEdit}>{t('globals.cancel')}</Button>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {t('globals.save')}
              </Button>
            </Space>
          </ModalFooter>
        </Form>
      ) : (
        <>
          <SectionDescription sectionStructure={structure} section={section} />

          {section.permissions.update && (
            <ModalFooter>
              <Button onClick={startEdit} key="start-edit">
                {t('globals.edit')}
              </Button>
            </ModalFooter>
          )}
        </>
      )}
    </Modal>
  );
};

export default SectionAttributesModal;
