import { useEffect, useState } from 'react';
import { Modal, Tabs } from 'antd';
import { t } from '@gowgates/utils';
import { Task } from '../../../../../types';
import { CloseTaskModalType } from '../TaskList';
import ShowTaskModalDetails from './Details';
import { ShowTaskModalTimeline } from './Timeline';

type ShowTaskModalProps = {
  isOpen?: boolean;
  closeModal: CloseTaskModalType;
  task?: Task;
  setTask: (task: Task) => void;
};

const ShowTaskModal = ({ isOpen = false, closeModal, task, setTask }: ShowTaskModalProps) => {
  const [activeTab, setActiveTab] = useState('details');

  useEffect(() => {
    task && setActiveTab('details');
  }, [task]);

  if (!task) {
    return;
  }

  const onChangeTab = (newActiveTab: string) => {
    setActiveTab(newActiveTab);
  };

  const items = [
    {
      label: t('globals.details'),
      key: 'details',
      children: <ShowTaskModalDetails task={task} setTask={setTask} closeModal={closeModal} />
    },
    {
      label: 'Timeline',
      key: 'history',
      children: <ShowTaskModalTimeline task={task} />
    }
  ];

  return (
    <Modal
      title={`${task.name} #${task.id}`}
      open={isOpen}
      onCancel={() => closeModal()}
      footer={null}
      width={800}
    >
      <Tabs
        activeKey={activeTab}
        onChange={onChangeTab}
        items={items}
        destroyInactiveTabPane={true}
      />
    </Modal>
  );
};

export default ShowTaskModal;
