import { useQuery } from '@tanstack/react-query';

import { client, extractData } from '@gowgates/api-client';

import { SimpleTenant, Tenant } from '../types';

const queryKey = ['tenant'];
export const fullTenantQueryFn = () =>
  client()
    .get('/tenant')
    .then(extractData<Tenant>);

export const simpleTenantQueryFn = () =>
  client()
    .get('/resources/tenant')
    .then(extractData<SimpleTenant>);

export const useTenantQuery = (customQueryFn?: () => Promise<SimpleTenant>) => {
  const { data: tenant, ...queryData } = useQuery({
    queryKey,
    queryFn: customQueryFn || fullTenantQueryFn,
    staleTime: Infinity
  });

  return {
    tenant,
    ...queryData
  };
};
