import { t, formatPrice } from '@gowgates/utils';
import { DateFormatter } from '@gowgates/dynamic-fields';

import { Item } from '../../../../../../types';

type ItemPaymentFieldsProps = {
  item: Item;
};

const ItemPaymentFields = ({ item }: ItemPaymentFieldsProps) => (
  <dl className="cg-descriptions">
    <dt>{t('activerecord.attributes.item.payablePercentage')}</dt>
    <dd>{`${item.payablePercentage} %`}</dd>

    <dt>{t('activerecord.attributes.item.excess')}</dt>
    <dd>{formatPrice({ value: item.excess })}</dd>

    <dt>{t('activerecord.attributes.item.paymentAmount')}</dt>
    <dd>{formatPrice({ value: item.paymentAmount })}</dd>

    <dt>{t('activerecord.attributes.item.paidAt')}</dt>
    <dd>
      <DateFormatter value={item.paidAt} />
    </dd>
  </dl>
);

export default ItemPaymentFields;
