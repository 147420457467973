import { useState } from 'react';
import { Button, Dropdown } from 'antd';
import { camelCase } from 'change-case';

import { ClaimWithStructure, SectionStructure } from '../../../../types';
import NewSectionModal from './NewSectionModal';

const missingSections = (sections: SectionStructure[], claim: ClaimWithStructure) =>
  sections.filter((sectionStructure) => !claim.sections[camelCase(sectionStructure.slug)]);

type AddSectionProps = { claim: ClaimWithStructure };

const AddSection = ({ claim }: AddSectionProps) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentStructure, setCurrentStructure] = useState<Partial<SectionStructure>>({});

  const items = missingSections(claim.structure.sections, claim).map((section) => ({
    key: section.slug,
    label: section.name
  }));

  // TODO: The logic of knowing which sections can be created need to move to the backend
  if (items.length === 0 || !claim.permissions.createSections) {
    return null;
  }

  return (
    <>
      <NewSectionModal
        isOpen={isModalOpen}
        setOpen={setModalOpen}
        sectionStructure={currentStructure as SectionStructure}
      />

      <div className="d-flex justify-content-end">
        <Dropdown
          menu={{
            items,
            onClick: (event) => {
              setCurrentStructure(
                claim.structure.sections.find(
                  (section) => section.slug === event.key
                ) as SectionStructure
              );
              setModalOpen(true);
            }
          }}
          placement="bottomRight"
          trigger={['click']}
        >
          <Button>Add a section</Button>
        </Dropdown>
      </div>
    </>
  );
};

export default AddSection;
