import { ColorPicker, Input, Radio, Form } from 'antd';
import { FilePicker, FormItem } from '@gowgates/dynamic-fields';
import { booleanOptions } from '@gowgates/utils';

export const TenantDetailsForm = ({ disabled = false }: { disabled?: boolean }) => {
  const form = Form.useFormInstance();

  return (
    <>
      <FormItem name={['name']} model="tenant" required>
        <Input />
      </FormItem>

      <FormItem name="primaryColor" model="tenant">
        <ColorPicker
          disabled={disabled}
          disabledAlpha
          onChange={(v) => form.setFieldValue('primaryColor', v.toHexString())}
        />
      </FormItem>

      <FormItem name="bgColor" model="tenant">
        <ColorPicker
          disabled={disabled}
          disabledAlpha
          onChange={(v) => form.setFieldValue('bgColor', v.toHexString())}
        />
      </FormItem>

      <FormItem name={['replyEmail']} model="tenant">
        <Input />
      </FormItem>

      <FormItem name="forceOtp" model="tenant">
        <Radio.Group options={booleanOptions() as { label: string; value: string }[]} />
      </FormItem>

      <FormItem name="logoSmall" model="tenant">
        <FilePicker accept="image/*" iconRenderType="url" />
      </FormItem>

      <FormItem name="logo" model="tenant">
        <FilePicker accept="image/*" iconRenderType="url" />
      </FormItem>
    </>
  );
};
