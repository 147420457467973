import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router';
import qs from 'query-string';

import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { DataPagination } from '@gowgates/api-client';

import { getPayments } from '../../api/endpoints';
import { updatedFiltersFromParams, updatedSorterFromParams } from '../../utils/tables';
import Page from '../../components/Page';
import TableSearch from '../../components/TableSearch';
import PaymentsTable from './Table';
import TableHeader from './TableHeader';
import PaymentsActions from './PaymentsActions';

const Payments = () => {
  const { search: searchQuery } = useLocation();
  const search = useMemo(() => qs.parse(searchQuery), [searchQuery]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState<DataPagination>();
  const [sorter, setSorter] = useState({});
  usePageTitle(t('activerecord.models.payment.other'));

  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);

  const { isFetching, data: payments } = useQuery({
    queryKey: ['payments', search],
    queryFn: () => getPayments(search)
  });

  useEffect(() => {
    if (payments) {
      setPagination((prevPagination) => ({ ...prevPagination, ...payments.pagination }));
    }
  }, [payments]);

  useEffect(() => {
    setSorter(updatedSorterFromParams(search));
    setFilters(updatedFiltersFromParams(search));
  }, [search]);

  return (
    <Page title={t('activerecord.models.payment.other')} overlap actions={<PaymentsActions />}>
      <TableSearch />

      <TableHeader selectedRows={selectedRows} payments={payments?.rows} />

      <PaymentsTable
        payments={payments?.rows}
        loading={isFetching}
        pagination={pagination}
        sorter={sorter}
        filters={filters}
        rowSelection={{ type: 'checkbox', onChange: setSelectedRows }}
      />
    </Page>
  );
};

export default Payments;
