import { User } from '@gowgates/core';
import { EMPTY_TEXT } from '@gowgates/utils';

import useResourceUsers from './useResourceUsers';

const useCaseManagers = () => {
  const { isLoading, users, userFilters } = useResourceUsers({ role: 'case_manager' });

  const findUser = (id: number) => users?.find((user: User) => user.id === id);
  const userName = (id?: number) => (id ? findUser(id)?.name || EMPTY_TEXT : EMPTY_TEXT);

  return {
    isLoading,
    users,
    userFilters,
    findUser,
    userName
  };
};

export default useCaseManagers;
