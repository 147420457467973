import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { FormBuilder } from '@gowgates/dynamic-fields';

import useAppConfigs from '../../hooks/useAppConfigs';
import { useFileConfig } from './hooks/useFileConfig';
import { SectionDivider } from './components/SectionDivider';

const ClaimantFields = () => {
  const { localFileConfig, updateFileConfigVal } = useFileConfig();
  const { appConfigs } = useAppConfigs();
  usePageTitle(`${t('activerecord.models.claimant.one')} - ${t('backoffice.fileConfig')}`);

  return (
    <>
      <SectionDivider
        labelKey="claimantDetailsLabel"
        fileConfig={localFileConfig}
        updateFileConfigVal={updateFileConfigVal}
      />

      <FormBuilder
        fieldableId={appConfigs.claimantStructureId}
        fieldableType="claimant_structure"
      />
    </>
  );
};

export default ClaimantFields;
