import { Col, Button, Row, Form, Input, App } from 'antd';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';

import { updateJourney } from '../../../api/endpoints';
import { showSavedMessage } from '../../../utils/messages';
import { Journey } from '../../../types';

const JourneyDetails = () => {
  const { message } = App.useApp();
  const journeyId = Number(useParams().journeyId);
  const queryClient = useQueryClient();
  const journey = queryClient.getQueryData<Journey>(['journey', journeyId]);
  const [form] = Form.useForm();
  usePageTitle(
    `${journey?.name} - ${t('globals.details')} | ${t('activerecord.models.journey.other')}`
  );

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: Journey) => updateJourney(journeyId, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.setQueryData(['journey', journeyId], data);
      showSavedMessage(message);
    }
  });

  return (
    <Form
      form={form}
      onFinish={mutate}
      initialValues={journey}
      layout="vertical"
      disabled={isLoading}
    >
      <Row gutter={30}>
        <Col span={12}>
          <FormItem name="name" model="journey">
            <Input />
          </FormItem>
        </Col>
      </Row>

      <footer className="d-flex justify-content-end">
        <Button type="primary" htmlType="submit" loading={isLoading}>
          {t('globals.save')}
        </Button>
      </footer>
    </Form>
  );
};

export default JourneyDetails;
