import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { FormBuilder } from '@gowgates/dynamic-fields';

import useAppConfigs from '../../hooks/useAppConfigs';
import { useFileConfig } from './hooks/useFileConfig';
import { SectionDivider } from './components/SectionDivider';

const CoverFields = () => {
  const { localFileConfig, updateFileConfigVal } = useFileConfig();
  const { appConfigs } = useAppConfigs();
  usePageTitle(`${t('activerecord.models.cover.one')} - ${t('backoffice.fileConfig')}`);

  return (
    <>
      <SectionDivider
        labelKey="policyDetailsLabel"
        toggleKey="policyDetailsActive"
        fileConfig={localFileConfig}
        updateFileConfigVal={updateFileConfigVal}
      />

      <FormBuilder
        fieldableId={appConfigs.coverStructureId}
        fieldableType="cover_structure"
        disabled={!localFileConfig.policyDetailsActive}
      />
    </>
  );
};

export default CoverFields;
