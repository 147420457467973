import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { BankDetailsForm } from '@gowgates/claims-gateway-shared';
import {
  sendClaimAction,
  updateClaimClaimant,
  updateClaimClient,
  updateClaimCover
} from '../../../../api/endpoints';
import ClaimantDescriptions from '../../descriptions/Claimant';
import EditableDescriptions from '../../../../components/EditableDescriptions';
import ClientDescriptions from '../../descriptions/Client';
import ClientDetailsForm from '../../forms/ClientDetails';
import ClaimantDetailsForm from '../../forms/ClaimantDetails';
import useClaim from '../../../../hooks/useClaim';
import PolicyDescriptions from '../../descriptions/Policy';
import PolicyDetailsForm from '../../forms/PolicyDetails';
import useAppConfigs from '../../../../hooks/useAppConfigs';
import BankDetailsDescriptions from '../../descriptions/BankDetails';

const PersonalDetails = () => {
  const { claim, refreshClaim } = useClaim();
  const { appConfigs } = useAppConfigs();
  usePageTitle(`${t('claim.id', { id: claim.id })} - ${t('activerecord.models.client.one')}`);
  if (!appConfigs) return null;
  const fileConfig = appConfigs.fileConfig;

  return (
    <>
      {fileConfig.clientDetailsActive && (
        <EditableDescriptions
          title={fileConfig.clientDetailsLabel}
          showPermission={claim.permissions.showClientDetails}
          editPermission={claim.permissions.update}
          descriptions={<ClientDescriptions />}
          form={<ClientDetailsForm />}
          model={claim.client}
          updateModel={(values) => updateClaimClient(claim.id, values)}
          onSuccess={refreshClaim}
        />
      )}

      <EditableDescriptions
        title={fileConfig.claimantDetailsLabel}
        showPermission={claim.permissions.showPersonalDetails}
        editPermission={claim.permissions.update}
        descriptions={<ClaimantDescriptions />}
        form={<ClaimantDetailsForm />}
        model={claim.claimant}
        updateModel={(values) => updateClaimClaimant(claim.id, values)}
        onSuccess={refreshClaim}
      />

      {fileConfig.policyDetailsActive && (
        <EditableDescriptions
          title={fileConfig.policyDetailsLabel}
          showPermission
          editPermission={claim.permissions.update}
          descriptions={<PolicyDescriptions />}
          form={<PolicyDetailsForm />}
          model={claim.cover}
          updateModel={(values) => updateClaimCover(claim.id, values)}
          onSuccess={refreshClaim}
        />
      )}

      <EditableDescriptions
        title={t('claim.bankDetails')}
        showPermission={claim.permissions.showBankDetails}
        editPermission={claim.permissions.updateBankDetails}
        descriptions={<BankDetailsDescriptions />}
        form={<BankDetailsForm appConfigs={appConfigs} />}
        model={claim}
        updateModel={(values) => sendClaimAction(claim.id, 'update_bank_details', values)}
        onSuccess={refreshClaim}
      />
    </>
  );
};

export default PersonalDetails;
