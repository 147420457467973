import { Outlet } from 'react-router';
import { t } from '@gowgates/utils';
import { useTenantQuery } from '@gowgates/claims-gateway-shared';

import Page from '../../components/Page';
import FullLoader from '../../components/FullLoader';
import { Nav } from './Nav';
import { Summary } from './Summary';

export const Tenant = () => {
  const { isFetching } = useTenantQuery();

  if (isFetching) {
    return <FullLoader />;
  }

  return (
    <Page title={t('tenant.configs')} nav={<Nav />} summary={<Summary />}>
      <Outlet />
    </Page>
  );
};
