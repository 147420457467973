import { Button, Col, Form, Row, Input, Select, App } from 'antd';
import { useQueryClient, useQuery, useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';

import { getJourneys, updateClaimStructure } from '../../../api/endpoints';
import { showSavedMessage } from '../../../utils/messages';
import { ClaimStructure } from '../../../types';

const StructureDetails = () => {
  const { message } = App.useApp();
  const structureId = Number(useParams().structureId);
  const queryClient = useQueryClient();
  const claimStructure = queryClient.getQueryData<ClaimStructure>(['claimStructure', structureId]);
  const [form] = Form.useForm();
  usePageTitle(
    `${claimStructure.name} - ${t('globals.details')} | ${t(
      'activerecord.models.claimStructure.other'
    )}`
  );

  const { data: journeys } = useQuery({ queryKey: ['journeys'], queryFn: () => getJourneys() });

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => updateClaimStructure(structureId, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.setQueryData(['claimStructure', structureId], data);
      showSavedMessage(message);
    }
  });

  return (
    <Form
      form={form}
      onFinish={mutate}
      initialValues={claimStructure}
      layout="vertical"
      disabled={isLoading}
    >
      <Row gutter={30}>
        <Col span={12}>
          <FormItem name="name" model="claimStructure">
            <Input />
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem name="claimName" model="claimStructure">
            <Input />
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem name="journeyId" model="claimStructure">
            <Select options={journeys} fieldNames={{ value: 'id', label: 'name' }} />
          </FormItem>
        </Col>
      </Row>

      <div className="text-right">
        <Button type="primary" htmlType="submit" loading={isLoading}>
          {t('globals.save')}
        </Button>
      </div>
    </Form>
  );
};

export default StructureDetails;
