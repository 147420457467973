import { Role } from '@gowgates/core';
import { t } from '@gowgates/utils';

type RoleDescriptionsProps = {
  role: Role;
};

const RoleDescriptions = ({ role }: RoleDescriptionsProps) => (
  <dl className="cg-descriptions">
    <dt>{t('activerecord.attributes.role.name')}</dt>
    <dd>{role.name || '-'}</dd>
  </dl>
);

export default RoleDescriptions;
