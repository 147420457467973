import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Checkbox,
  Col,
  Input,
  Row,
  Switch,
  Form,
  CheckboxProps,
  GetProps,
  Space,
  Typography,
  Alert,
  Button,
  App
} from 'antd';

import { FormItem, useAppConfigs } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';
import { useInputFocus } from '@gowgates/core';

import { useMutation } from '@tanstack/react-query';
import { testWebhook } from '../api/endpoints';
import { Webhook, AppConfigs } from '../types';

type CheckboxGroupPropsType = GetProps<typeof Checkbox.Group>;

export const WebhookFormFields = () => {
  const { ref } = useInputFocus();
  const { message } = App.useApp();
  const webhookId = useParams<{ webhookId: string }>().webhookId;
  const form = Form.useFormInstance();
  const eventSignatureEnabled = form.getFieldValue('eventSignatureEnabled');
  const [isEventSignatureEnabled, setIsEventSignatureEnabled] = useState(eventSignatureEnabled);

  const verificationKey = form.getFieldValue('verificationKey');

  const { mutate: testMutate } = useMutation({
    mutationFn: () => testWebhook(webhookId),
    onError: () => {
      message.error(t('webhook.testFailure'));
    },
    onSuccess: () => {
      message.success(t('webhook.testSuccess'));
    }
  });

  const renderVerificationKeyContent = () => {
    if (!isEventSignatureEnabled && verificationKey) {
      return (
        <Alert
          message={t('webhook.afterDisableVerificationKey')}
          type="info"
          showIcon
          className="text-center spacer-bottom"
        />
      );
    }

    if (isEventSignatureEnabled && verificationKey) {
      return (
        <Typography.Paragraph code copyable key="token">
          {verificationKey}
        </Typography.Paragraph>
      );
    } else if (isEventSignatureEnabled && !verificationKey) {
      return (
        <Alert
          message={t('webhook.generateVerificationKey')}
          description={t('webhook.afterSaveVerificationKey')}
          type="info"
          showIcon
          className="text-center spacer-bottom"
        />
      );
    }
  };

  return (
    <>
      <FormItem name="enabled" model="webhook">
        <Switch />
      </FormItem>
      <FormItem name="name" model="webhook">
        <Input ref={ref} />
      </FormItem>
      <FormItem name="url" model="webhook">
        <Input />
      </FormItem>
      <FormItem name="actions" model="webhook">
        <ActionsCheckboxes />
      </FormItem>
      <FormItem name="eventSignatureEnabled" model="webhook">
        <Switch onChange={(value) => setIsEventSignatureEnabled(value)} />
      </FormItem>
      {renderVerificationKeyContent()}
      <Space>
        <Button onClick={() => testMutate()}>{t('webhook.testIntegration')}</Button>
      </Space>
    </>
  );
};

const ActionsCheckboxes = (props?: CheckboxGroupPropsType) => {
  const form = Form.useFormInstance<Webhook>();
  const { appConfigs } = useAppConfigs();

  const actionsList =
    appConfigs && (appConfigs as AppConfigs).webhook.actions.map((action) => action.value);
  const selectedActions: Webhook['actions'] = form.getFieldValue('actions') || [];

  const indeterminate = selectedActions.length > 0 && selectedActions.length < actionsList.length;
  const [checkAll, setCheckAll] = useState(actionsList.length === selectedActions.length);

  const onCheckAllChange: CheckboxProps['onChange'] = (e) => {
    form.setFieldValue('actions', e.target.checked ? actionsList : []);

    setCheckAll(e.target.checked);
  };

  return actionsList ? (
    <Space size="middle" direction="vertical">
      <Checkbox onChange={onCheckAllChange} indeterminate={indeterminate} checked={checkAll}>
        {t('globals.checkAll')}
      </Checkbox>

      <Checkbox.Group {...props}>
        <Row>
          {(appConfigs as AppConfigs).webhook.actions.map((action) => (
            <Col xs={12} md={8} key={action.value}>
              <Checkbox value={action.value}>{action.label}</Checkbox>
            </Col>
          ))}
        </Row>
      </Checkbox.Group>
    </Space>
  ) : null;
};
