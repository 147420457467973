import { Form, Row, Col, Button, App, Input } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { RichTextHtml, useAuth, User, usePageTitle } from '@gowgates/core';

import { getOtpQrCode, updateUser } from '../../api/endpoints';

const Profile = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData<User>(['users', 'me']);
  const { updateUserInStorage } = useAuth();
  const [form] = Form.useForm();
  usePageTitle(t('account.title'));

  const { data: svg } = useQuery({
    queryKey: ['qrCode'],
    queryFn: getOtpQrCode,
    enabled: Boolean(user?.otpRequiredForLogin)
  });

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: Partial<User>) => (user ? updateUser(user.id, values) : Promise.reject()),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      message.success('Profile updated');
      updateUserInStorage(data);
    }
  });

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={mutate}
      initialValues={user}
      disabled={isLoading || !user}
    >
      <Row gutter={30}>
        <Col span={12}>
          <FormItem name="firstName" model="user" required>
            <Input />
          </FormItem>
        </Col>

        <Col span={12}>
          <FormItem name="lastName" model="user" required>
            <Input />
          </FormItem>
        </Col>
      </Row>

      {user?.otpRequiredForLogin && (
        <>
          <p>{t('user.otp.enabled')}</p>
          <p>{t('user.otp.useDevice')}</p>

          <RichTextHtml variant="otp-svg" className="text-center" htmlText={svg} />
        </>
      )}

      <footer className="d-flex justify-content-end">
        <Button type="primary" htmlType="submit">
          {t('globals.save')}
        </Button>
      </footer>
    </Form>
  );
};

export default Profile;
