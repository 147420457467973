import { Timeline, Typography } from 'antd';

import { t } from '@gowgates/utils';

import { ItemHistory } from '../types';
import TwoLineDate from './TwoLineDate';

type HistoryProps = {
  history: ItemHistory[];
};

const History = ({ history = [] }: HistoryProps) => {
  const items = history.map((entry) => ({
    label: <TwoLineDate date={entry.createdAt} />,
    children: (
      <p>
        {entry.name}
        <br />
        <Typography.Text type="secondary" italic>
          <small>{t('user.by', { name: entry.user ? entry.user.name : t('user.unknown') })}</small>
        </Typography.Text>
      </p>
    )
  }));

  return <Timeline mode="left" items={items} />;
};

export default History;
