import { GetProps, Tag, Tooltip } from 'antd';

import { t } from '@gowgates/utils';

import { Claim } from '../../../types';

type TooltipPropsType = GetProps<typeof Tooltip>;

type ClaimStatusTagProps = {
  claim: Claim;
  TooltipComponent?: React.FC<TooltipPropsType>;
};

const statuses: { [key: string]: string } = {
  open: 'processing',
  closed: 'green',
  draft: 'warning'
};

const ClaimStatusTag = ({
  claim,
  TooltipComponent = ({ children }) => children
}: ClaimStatusTagProps) => (
  <Tag color={statuses[claim.status]}>
    <TooltipComponent title={t(`claim.statuses.${claim.status}`)}>
      {t(`claim.statuses.${claim.status}`)}
    </TooltipComponent>
  </Tag>
);

export default ClaimStatusTag;
