import { Button } from 'antd';
import { useParams } from 'react-router';
import { DeleteOutlined } from '@ant-design/icons';

import { ExtendedDangerPopconfirm } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';

import { deleteSection } from '../../../../api/endpoints';
import { Section } from '../../../../types';

type DeleteSectionBtnProps = {
  section: Section;
};

const DeleteSectionBtn = ({ section }: DeleteSectionBtnProps) => {
  const claimId = Number(useParams().claimId);

  return (
    <ExtendedDangerPopconfirm
      title={t('section.confirmDelete')}
      deleteFn={() => deleteSection(claimId, section.id)}
      invalidateQueries={['claim', claimId]}
      permission={section.permissions?.destroy}
      tooltip={false}
    >
      <Button danger type="default" icon={<DeleteOutlined />}>
        {t('section.remove')}
      </Button>
    </ExtendedDangerPopconfirm>
  );
};

export default DeleteSectionBtn;
