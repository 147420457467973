import { GetProps, Space, Tooltip, Typography } from 'antd';
import numeral from 'numeral';
import { formatPrice, DEFAULT_CURRENCY, findCurrency } from '@gowgates/utils';

type TooltipPropsType = GetProps<typeof Tooltip>;

type MoneyFormatterProps = {
  value?: number | string;
  currency?: string;
  rate?: number;
  negativeType?: 'secondary' | 'success' | 'warning' | 'danger';
  positiveType?: 'secondary' | 'success' | 'warning' | 'danger';
  zeroType?: 'secondary' | 'success' | 'warning' | 'danger';
  TooltipComponent?: React.FC<TooltipPropsType>;
};

export const MoneyFormatter = ({
  value,
  currency = DEFAULT_CURRENCY,
  rate,
  negativeType,
  positiveType,
  zeroType,
  TooltipComponent
}: MoneyFormatterProps) => {
  let type = undefined;

  const numeralValue = numeral(value).value() || 0;

  if (numeralValue < 0 && negativeType) {
    type = negativeType;
  }

  if (numeralValue > 0 && positiveType) {
    type = positiveType;
  }

  if (numeralValue === 0 && zeroType) {
    type = zeroType;
  }

  const hasExchangeRate = rate && currency !== DEFAULT_CURRENCY;
  const priceInDefaultCurrency = hasExchangeRate ? numeral(value || 0).multiply(rate) : value;
  const formattedPriceInDefaultCurrency = formatPrice({ value: priceInDefaultCurrency });

  const title = `${formatPrice({ value, currency })}${
    hasExchangeRate ? `(${formattedPriceInDefaultCurrency})` : ''
  }`;

  const currencyConfig = findCurrency(currency);

  const currencyNode = <span>{currencyConfig?.symbol} </span>;

  const output = (
    <>
      <Typography.Text type={type}>
        {currency !== DEFAULT_CURRENCY ? (
          <Tooltip title={currencyConfig?.name}>{currencyNode}</Tooltip>
        ) : (
          currencyNode
        )}

        {formatPrice({ value, currency, hideSymbol: true })}
      </Typography.Text>
      {hasExchangeRate && (
        <Typography.Text type="secondary">({formattedPriceInDefaultCurrency})</Typography.Text>
      )}
    </>
  );

  if (!TooltipComponent) {
    return <Space>{output}</Space>;
  }

  return (
    <Space>
      <TooltipComponent title={title}>{output}</TooltipComponent>
    </Space>
  );
};
