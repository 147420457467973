import { useEffect } from 'react';
import { Modal, Form } from 'antd';
import { useParams } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addErrorsFromAPIInForm } from '@gowgates/utils';

import { createSection } from '../../../../api/endpoints';
import SectionForm from '../../forms/Section';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import { Section, SectionStructure } from '../../../../types';

type NewSectionModalProps = {
  isOpen: boolean;
  setOpen: (o: boolean) => void;
  sectionStructure: SectionStructure;
};

const NewSectionModal = ({ isOpen, setOpen, sectionStructure }: NewSectionModalProps) => {
  const claimId = Number(useParams().claimId);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        form.resetFields();
      }, 50);
    }
  }, [sectionStructure, isOpen]);

  const closeModal = () => {
    setOpen(false);
    form.resetFields();
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: Partial<Section>) =>
      createSection(claimId, { ...values, submitting: true }),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['claim', claimId] });
      closeModal();
    }
  });

  return (
    <Modal title={`Add ${sectionStructure.name}`} open={isOpen} onCancel={closeModal} footer={null}>
      <Form
        form={form}
        initialValues={{ type: sectionStructure.slug }}
        layout="vertical"
        onFinish={mutate}
        disabled={isLoading}
      >
        <SectionForm sectionStructure={sectionStructure} />

        <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
      </Form>
    </Modal>
  );
};

export default NewSectionModal;
