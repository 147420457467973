import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router';

// Idea from https://github.com/remix-run/react-router/discussions/9922#discussioncomment-8416783
export const useGoBack = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const goBack = useCallback(
    ({ fallback }: { fallback?: string } = {}) => {
      const thereIsAPrevPage = location.key !== 'default';
      if (thereIsAPrevPage) {
        navigate(-1);
      } else {
        navigate(fallback || '/');
      }
    },
    [location, navigate]
  );

  return {
    navigate,
    goBack
  };
};
