import { PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';

import { AnyObject } from '@gowgates/utils';

type ClickableColumnContentProps = PropsWithChildren<{
  record: AnyObject;
}>;

export const ClickableColumnContent = ({ record, children }: ClickableColumnContentProps) =>
  record.permissions.show ? (
    <Link to={`/claims/${record.claimId}/items?id=${record.id}`} className="col-clickable">
      {children}
    </Link>
  ) : (
    children
  );
