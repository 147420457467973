import { Table, TableProps } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';

import { getRoles } from '../../api/endpoints';
import Page from '../../components/Page';
import RoleActions from './RoleActions';

const Roles = () => {
  usePageTitle(t('activerecord.models.role.other'));

  const { isFetching, data } = useQuery({ queryKey: ['roles'], queryFn: getRoles });

  const columns: TableProps['columns'] = [
    {
      dataIndex: 'name',
      title: t('activerecord.attributes.role.name'),
      render: (text, record) => (
        <Link to={`/roles/${record.id}`} className="col-clickable">
          {text}
        </Link>
      )
    },
    {
      dataIndex: 'usersCount',
      title: t('activerecord.attributes.role.usersCount'),
      render: (text, record) => (
        <Link to={`/roles/${record.id}`} className="col-clickable">
          {text}
        </Link>
      )
    }
  ];

  return (
    <Page title={t('activerecord.models.role.other')} actions={<RoleActions />}>
      <Table
        dataSource={data}
        rowKey={(record) => record.id}
        className="page-table"
        loading={isFetching}
        pagination={false}
        columns={columns}
      />
    </Page>
  );
};

export default Roles;
