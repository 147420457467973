import { DynamicFormatter, Field } from '@gowgates/dynamic-fields';
import { AvatarAndName } from '@gowgates/core';
import { TenantColumnConfig } from '@gowgates/claims-gateway-shared';

import { TaskInTable } from '../../types';
import TaskStatus from '../claim/show/Tasks/TaskStatus';
import StructureName from '../../components/StructureName';
import ClaimStatusTag from '../claim/components/ClaimStatusTag';
import ClaimLiabilityTag from '../claim/components/ClaimLiabilityTag';

type Props = {
  record: TaskInTable;
  columnConfig: TenantColumnConfig;
  field: Field;
};

type DisplayAs = {
  task: Record<string, React.ReactNode | string>;
  claim: Record<string, React.ReactNode>;
};

export const TaskTableColumn = ({ record, columnConfig, field }: Props) => {
  const displayAs: DisplayAs = {
    task: {
      userId: <AvatarAndName user={record.user} />,
      taskStructureId: record.name,
      status: <TaskStatus task={record} />,
      createdById: <AvatarAndName user={record.createdBy} />,
      roleId: record.roleName
    },
    claim: {
      structureId: <StructureName claim={record.claim} />,
      status: <ClaimStatusTag claim={record.claim} />,
      assigneeId: <AvatarAndName user={record.claim.assignee} />,
      liability: <ClaimLiabilityTag liability={record.claim.liability} />
    }
  };

  if (columnConfig.dataType === 'dynamic' && columnConfig.model !== 'task') {
    return <DynamicFormatter data={record[columnConfig.model]?.data || {}} field={field} />;
  }

  if (
    columnConfig.dataType === 'fixed' &&
    (columnConfig.model === 'claimant' ||
      columnConfig.model === 'client' ||
      columnConfig.model === 'cover')
  ) {
    return null;
  }

  if (
    columnConfig.dataType === 'fixed' &&
    (columnConfig.model === 'task' || columnConfig.model === 'claim')
  ) {
    if (displayAs[columnConfig.model][columnConfig.value]) {
      return displayAs[columnConfig.model][columnConfig.value];
    }

    if (columnConfig.model === 'task') {
      return <DynamicFormatter data={record} field={field} />;
    } else {
      return <DynamicFormatter data={record[columnConfig.model]} field={field} />;
    }
  }

  return null;
};
