import { t } from '@gowgates/utils';
import { DynamicDescriptions } from '@gowgates/dynamic-fields';

import { Item, SectionStructure } from '../../../types';

type ItemDescriptionsProps = {
  item: Item;
  structure: SectionStructure;
};

const ItemDescriptions = ({ item, structure }: ItemDescriptionsProps) => (
  <>
    <dl className="cg-descriptions">
      <dt>{t('activerecord.attributes.item.category')}</dt>
      <dd>{t(`item.categories.${item.category}`)}</dd>
    </dl>

    <DynamicDescriptions model={item} fields={structure.items} />
  </>
);

export default ItemDescriptions;
