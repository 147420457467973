import { useEffect } from 'react';
import { Modal, Form } from 'antd';

import { t } from '@gowgates/utils';

import ItemForm from '../../forms/Item';
import { Item, SectionStructure } from '../../../../types';

type EditItemModalProps = {
  isOpen?: boolean;
  setOpen: (open: boolean) => void;
  item?: Partial<Item>;
  structure: SectionStructure;
};
const EditItemModal = ({ isOpen = false, setOpen, item, structure }: EditItemModalProps) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        form.resetFields();
      }, 50);
    }
  }, [item, isOpen]);

  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };

  return (
    <Modal title={t('item.add')} open={isOpen} onCancel={closeModal} footer={null}>
      <ItemForm
        item={item}
        form={form}
        onSuccess={closeModal}
        onCancel={closeModal}
        onDestroy={closeModal}
        structure={structure}
      />
    </Modal>
  );
};

export default EditItemModal;
