import { App } from 'antd';
import { AxiosError } from 'axios';

import { BaseError, isUnprocessableEntity } from '@gowgates/api-client';
import { ExtendedDangerPopconfirm } from '@gowgates/dynamic-fields';

import { deleteTaskStructure } from '../../../api/endpoints';
import { TaskStructure } from '../../../types';

type DeleteTaskStructureProps = {
  journeyId: number;
  taskStructure: TaskStructure;
};

const DeleteTaskStructure = ({ journeyId, taskStructure }: DeleteTaskStructureProps) => {
  const { message } = App.useApp();
  const onError = (error: AxiosError<BaseError>) => {
    if (isUnprocessableEntity(error) && error.response?.data.errors.base) {
      message.error(error.response.data.errors.base);
    }
  };

  const onSuccess = () => {
    message.success('Task deleted');
  };

  return (
    <div className="d-flex justify-content-end">
      <ExtendedDangerPopconfirm
        deleteFn={() => deleteTaskStructure(journeyId, taskStructure.id)}
        invalidateQueries={['taskStructures', journeyId]}
        permission
        onSuccess={onSuccess}
        onError={onError}
      />
    </div>
  );
};

export default DeleteTaskStructure;
