import { useEffect, useState } from 'react';

import { EMPTY_TEXT } from '../utils';

type Currency = {
  key: string;
  name: string;
  symbol: string;
};

type DisplayOptions = {
  showFullNameInLabel?: boolean;
};

const displayCurrency = (currency: Currency) => `${currency.symbol} - ${currency.name}`;

export const useCurrencies = () => {
  const [currencies, setCurrencies] = useState<Currency[]>();

  useEffect(() => {
    import('../resources/currencies.json').then((currenciesModule) => {
      setCurrencies(currenciesModule.default);
    });
  }, []);

  const getCurrencyLabel = (
    currency: Currency,
    { showFullNameInLabel = false }: DisplayOptions = {}
  ) => (showFullNameInLabel ? displayCurrency(currency) : currency.symbol);

  const getLabelForCurrencyKey = (currencyKey?: string, options?: DisplayOptions) => {
    const currencyData = currencies?.find((c) => c.key === currencyKey);
    return currencyData ? getCurrencyLabel(currencyData, options) : EMPTY_TEXT;
  };

  return { currencies, getCurrencyLabel, getLabelForCurrencyKey };
};
