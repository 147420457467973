import React, { createContext, useState, useEffect, PropsWithChildren } from 'react';

import find from 'lodash.find';
import { t } from '@gowgates/utils';
import { claimDetailsLabel } from '../layouts/claim/utils/claim';
import { ClaimWithStructure } from '../types';

type Tab = { key: string; path: string; action: string };
type ContextValue = {
  currentTab: Tab;
  previousTab: Tab;
  nextTab: Tab;
  claimTab: Tab;
  getTab: (key: string) => Tab | undefined;
  getTabByIndex: (number: number) => Tab | undefined;
  isTabDisabled: (index: number) => boolean;
  currentTabIndex: number;
  setCurrentPath: (path: string) => void;
  stepTitle: (stepKey: string) => string;
  currentStepTitle: () => string;
};

const EMPTY_TAB: Tab = {
  key: '',
  path: '',
  action: ''
};

export const CreateStepsContext = createContext<ContextValue>({
  currentTab: EMPTY_TAB,
  previousTab: EMPTY_TAB,
  nextTab: EMPTY_TAB,
  claimTab: EMPTY_TAB,
  getTab: () => undefined,
  getTabByIndex: () => undefined,
  isTabDisabled: () => false,
  currentTabIndex: 0,
  setCurrentPath: () => {},
  stepTitle: () => '',
  currentStepTitle: () => ''
});

const tabMapping: Tab[] = [
  {
    key: 'personal_details',
    path: 'underwriting',
    action: 'add_personal_details'
  },
  {
    key: 'claim_details',
    path: 'claim-details',
    action: 'add_claim_details'
  },
  {
    key: 'section_details',
    path: 'sections',
    action: 'add_section_details'
  },
  {
    key: 'uploads',
    path: 'uploads',
    action: 'add_uploads'
  },
  {
    key: 'declaration',
    path: 'declaration',
    action: 'submit'
  },
  {
    key: 'thank_you',
    path: '',
    action: ''
  }
];

export const CreateStepsContextProvider = ({
  claim,
  children
}: PropsWithChildren<{ claim: ClaimWithStructure }>) => {
  const [currentPath, setCurrentPath] = useState('');
  // Index of the selected tab
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  // Object of the selected tab
  const [currentTab, setCurrentTab] = useState(EMPTY_TAB);
  // Object of the previous tab
  const [previousTab, setPreviousTab] = useState(EMPTY_TAB);
  // Object of the next tab
  const [nextTab, setNextTab] = useState(EMPTY_TAB);
  // Index of the tab corresponding to the current status of the claim
  const [claimTabIndex, setClaimTabIndex] = useState(0);
  // object of the tab corresponding to the current status of the claim
  const [claimTab, setClaimTab] = useState(EMPTY_TAB);

  const stepTitle = (step?: string) => {
    if (!step) return '';

    if (step === 'claim_details' && claim.structure.claimName) {
      return claimDetailsLabel(claim.structure);
    }

    return t(`claim.backofficeCreateStatuses.${step}`);
  };

  // This useEffect handle tab change.
  useEffect(() => {
    // If path is empty, return
    if (!currentPath) {
      return;
    }

    const tab = find(tabMapping, { path: currentPath });

    // if tab is not found, return
    if (!tab) {
      return;
    }

    setCurrentTab(tab);

    document.title = `${t('claim.id', { id: claim.id })} - ${stepTitle(tab.key)} | Claims Gateway`;

    // finds index of tab in claim structure
    // const currentIndex = findIndex(claim.structure.createSteps, { key: tab.key });
    const currentIndex = claim.structure.createSteps.indexOf(tab.key);
    setCurrentTabIndex(currentIndex);

    // sets next tab. If is is the last tab in claim structure, next will be thank you
    if (currentIndex < claim.structure.createSteps.length - 1) {
      const nextKey = claim.structure.createSteps[currentIndex + 1];
      setNextTab(find(tabMapping, { key: nextKey }) || EMPTY_TAB);
    } else {
      setNextTab(find(tabMapping, { key: 'thank_you' }) || EMPTY_TAB);
    }

    // sets previous tab. If is is the last tab in claim structure, next will be thank you
    if (currentIndex > 0) {
      const previousKey = claim.structure.createSteps[currentIndex - 1];
      setPreviousTab(find(tabMapping, { key: previousKey }) || EMPTY_TAB);
    } else {
      setPreviousTab(EMPTY_TAB);
    }
  }, [currentPath, claim.structure.createSteps]);

  useEffect(() => {
    setClaimTab(find(tabMapping, { key: claim.createStatus }) || EMPTY_TAB);
    setClaimTabIndex(claim.structure.createSteps.indexOf(claim.createStatus));
  }, [claim.createStatus, claim.structure.createSteps]);

  const getTabByIndex = (number: number) =>
    find(tabMapping, { key: claim.structure.createSteps[number] });

  const isTabDisabled = (index: number) => index > claimTabIndex;

  const getTab = (key: string) => find(tabMapping, { key });

  const currentStepTitle = () => stepTitle(currentTab?.key);

  const value = {
    currentTab,
    previousTab,
    nextTab,
    claimTab,
    getTab,
    getTabByIndex,
    isTabDisabled,
    currentTabIndex,
    setCurrentPath,
    stepTitle,
    currentStepTitle
  };

  return <CreateStepsContext.Provider value={value}>{children}</CreateStepsContext.Provider>;
};
