import { ReportField } from '../../../../types';

type ShowReportFieldProps = {
  reportField: ReportField;
  startEditing: (id?: number) => void;
};

const ShowReportField = ({ reportField, startEditing }: ShowReportFieldProps) => (
  <article
    className="report-field show-report-field"
    role="button"
    onClick={() => startEditing(reportField.id)}
    onKeyUp={() => startEditing(reportField.id)}
    tabIndex={-1}
  >
    {reportField.pdfName}
  </article>
);

export default ShowReportField;
