import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { updateFileConfig } from '../../../api/endpoints';
import useAppConfigs from '../../../hooks/useAppConfigs';
import { AppConfigs } from '../../../types/app-configs';

export const useFileConfig = () => {
  const { appConfigs } = useAppConfigs();
  const [localFileConfig, setFileConfig] = useState((appConfigs as AppConfigs)?.fileConfig);

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: (data: Partial<AppConfigs['fileConfig']>) => updateFileConfig(data)
  });

  const updateFileConfigVal = (key: keyof AppConfigs['fileConfig'], val: string | boolean) => {
    setFileConfig({ ...localFileConfig, [key]: val });
    mutate(
      { [key]: val },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['appConfigs'] });
        }
      }
    );
  };

  return {
    localFileConfig,
    updateFileConfigVal
  };
};
