import { useState } from 'react';
import { Button, Modal, Space, App } from 'antd';
import { useQueryClient, useMutation } from '@tanstack/react-query';

import { t, UrlId } from '@gowgates/utils';
import { RichTextHtml } from '@gowgates/core';

import { createStructure, updateStructure } from '../../../api/endpoints';
import useStructures from '../../../hooks/useStructures';
import { ClaimStructure, Structure } from '../../../types';

type PublishBtnProps = {
  claimStructure: ClaimStructure;
};

const PublishBtn = ({ claimStructure }: PublishBtnProps) => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  const { structures: allStructures } = useStructures();
  const [isModalOpen, setModalOpen] = useState(false);

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (fn: (claimStructureId: UrlId) => Promise<Structure>) => fn(claimStructure.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['claimStructure', claimStructure.id] });
      queryClient.invalidateQueries({ queryKey: ['structures'] });
      message.success(t('structure.saved'));
      closeModal();
    }
  });

  if (!claimStructure.dirty) {
    return null;
  }

  const structures = allStructures?.filter(
    (structure) => structure.claimStructureId === claimStructure.id
  );

  const showModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleCreate = () => {
    mutate(createStructure);
  };

  const handleUpdate = () => {
    mutate(updateStructure);
  };

  const onClick = () => {
    if (structures && structures.length === 0) {
      handleCreate();
    } else {
      showModal();
    }
  };

  return (
    <>
      <Button type="primary" onClick={onClick}>
        {t('globals.publish')}
      </Button>

      <Modal
        title={t('claimStructure.publish')}
        open={isModalOpen}
        onCancel={closeModal}
        footer={null}
        width={800}
      >
        <RichTextHtml Component="p" htmlText={t('claimStructure.usedInClaims')} noStyle />
        <RichTextHtml Component="p" htmlText={t('claimStructure.updateExplanation')} noStyle />
        <RichTextHtml htmlText={t('claimStructure.updateRules')} noStyle />
        <RichTextHtml Component="p" htmlText={t('claimStructure.newVersionExplanation')} noStyle />
        <RichTextHtml
          Component="p"
          className="spacer-bottom"
          htmlText={t('claimStructure.futureClaimsExplanation')}
          noStyle
        />

        <footer className="d-flex justify-content-between">
          <Button onClick={closeModal} disabled={isLoading}>
            {t('globals.close')}
          </Button>
          <div>
            <Space>
              <Button type="primary" disabled={isLoading} onClick={handleUpdate}>
                {t('structure.updateExisting')}
              </Button>
              <Button type="primary" disabled={isLoading} onClick={handleCreate}>
                {t('structure.newVersion')}
              </Button>
            </Space>
          </div>
        </footer>
      </Modal>
    </>
  );
};

export default PublishBtn;
