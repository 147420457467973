import React from 'react';
import { Space } from 'antd';
import uniqBy from 'lodash.uniqby';

import ActionButton from '../claim/show/components/ActionButton';
import { Payment } from '../../types';

type TableHeaderProps = {
  selectedRows: React.Key[];
  payments?: Payment[];
};
export const TableHeader = ({ selectedRows, payments = [] }: TableHeaderProps) => {
  if (!selectedRows.length || !payments.length) {
    return <div className="spacer control-height" />;
  }

  const selectedPayments = payments.filter(({ id }) => selectedRows.includes(String(id)));
  const allActions = selectedPayments.map(({ actions }) => actions).flat();

  return (
    <Space>
      {uniqBy(allActions, 'id').map((action) => {
        const itemsToBulk = selectedPayments.filter((p) =>
          p.actions.map(({ id }) => id).includes(action.id)
        );

        return (
          <ActionButton key={action.id} action={action} recordsToBulk={itemsToBulk} entity="item" />
        );
      })}
    </Space>
  );
};

export default TableHeader;
