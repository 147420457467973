import { Link, useMatch } from 'react-router-dom';
import { Menu } from 'antd';

import { t } from '@gowgates/utils';

import useClaim from '../../../../hooks/useClaim';

const ClaimNav = () => {
  const match = useMatch({ path: '/claims/:claimId/:tab', end: false });
  const { claim } = useClaim();

  const menuItems = [];

  if (claim.permissions.showData) {
    menuItems.push({
      key: 'claim',
      label: <Link to="claim">{t('activerecord.models.claim.one')}</Link>
    });
  }

  if (claim.permissions.showClientDetails || claim.permissions.showPersonalDetails) {
    menuItems.push({
      key: 'client',
      label: <Link to="client">{t('activerecord.models.client.one')}</Link>
    });
  }

  if (claim.permissions.showDocuments) {
    menuItems.push({
      key: 'documents',
      label: <Link to="documents">{t('activerecord.models.document.other')}</Link>
    });
  }

  if (claim.permissions.showItems) {
    menuItems.push({
      key: 'items',
      label: <Link to="items">{t('activerecord.models.item.other')}</Link>
    });
  }

  if (claim.permissions.showFinance) {
    menuItems.push({
      key: 'finance',
      label: <Link to="finance">{t('claim.finance')}</Link>
    });
  }

  if (claim.permissions.showNotes) {
    menuItems.push({
      key: 'notes',
      label: <Link to="notes">{t('activerecord.models.note.other')}</Link>
    });
  }

  if (claim.permissions.showTasks) {
    menuItems.push({
      key: 'tasks',
      label: <Link to="tasks">{t('activerecord.models.task.other')}</Link>
    });
  }

  return (
    <Menu
      data-testid="claim-menu"
      selectedKeys={match?.params?.tab ? [match.params.tab] : undefined}
      mode="horizontal"
      className="box-menu"
      items={menuItems}
    />
  );
};

export default ClaimNav;
