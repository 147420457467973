import { DynamicForm } from '@gowgates/dynamic-fields';

import useFileConfig from '../../../hooks/useFileConfig';

type ClaimantDetailsFormProps = {
  nested?: boolean;
};

const ClaimantDetailsForm = ({ nested = false }: ClaimantDetailsFormProps = {}) => {
  const { claimantStructure } = useFileConfig();
  const namespace = nested ? ['claimant', 'data'] : ['data'];

  return <DynamicForm fields={claimantStructure} namespace={namespace} />;
};

export default ClaimantDetailsForm;
