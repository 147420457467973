import { FormInstance, Tabs } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { t } from '@gowgates/utils';
import { Loader } from '@gowgates/core';

import { Item, SectionStructure } from '../../../../../types';
import ItemForm from '../../../forms/Item';
import History from '../../../../../components/History';
import TaskList from '../../../show/Tasks/TaskList';
import { getItem } from '../../../../../api/endpoints';
import ItemModalDetails from './Details';

type ItemModalContentProps = {
  form: FormInstance<Item>;
  closeModal: () => void;
  item: Item;
  structure: SectionStructure;
  isEditing: boolean;
  setEditing: (e: boolean) => void;
  activeTab: string;
  setActiveTab: (t: string) => void;
};

const ItemModalContent = ({
  form,
  closeModal,
  item,
  structure,
  isEditing,
  setEditing,
  activeTab,
  setActiveTab
}: ItemModalContentProps) => {
  const claimId = Number(useParams().claimId);
  const queryKey = ['claim', claimId, 'items', item.id];

  // TODO: Use cache to get these items only within a certain amount of time
  const { isFetched, data } = useQuery({
    queryKey,
    queryFn: () => getItem(claimId, item.id),
    // TODO: this makes an unnecessary request when deleting an expense.
    //       need to use useErrorBoundary so it doesn't show 404 page.
    throwOnError: false
  });

  const afterEditing = () => {
    setEditing(false);
    closeModal();
  };

  if (!isFetched) {
    return <Loader />;
  }

  if (!data) return;

  const menuItems = [
    {
      label: t('globals.details'),
      key: 'details',
      children: (
        <ItemModalDetails
          item={data}
          sectionStructure={structure}
          onSuccess={closeModal}
          setEditing={setEditing}
          form={form}
          notes={data?.notes}
          documents={data.documents}
        />
      )
    },
    {
      label: t('activerecord.models.task.other'),
      key: 'tasks',
      children: <TaskList tasks={data.tasks} item={data} />
    },
    {
      label: t('globals.history'),
      key: 'history',
      children: <History history={data?.history} />
    }
  ];

  return isEditing ? (
    <ItemForm
      item={data}
      form={form}
      onSuccess={afterEditing}
      onCancel={() => setEditing(false)}
      onDestroy={closeModal}
      structure={structure}
    />
  ) : (
    <Tabs activeKey={activeTab} onChange={setActiveTab} items={menuItems} />
  );
};

export default ItemModalContent;
