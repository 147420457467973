import { useEffect, useMemo } from 'react';
import get from 'lodash.get';
import { camelCase } from 'change-case';
import { Col, Form } from 'antd';

import {
  evalConditionalField,
  extractFieldsForCondition,
  hasCondition
} from '../../utils/conditionField';
import { Field } from '../../types';
import { DynamicField } from './DynamicField';

type DynamicFormItemProps = {
  field: Field;
  // number for lists (Form.List)
  namespace?: (string | number)[];
  fullNamespace?: (string | number)[];
};

type ConditionalDynamicFieldProps = Required<DynamicFormItemProps> & { fieldsToWatch: string[] };

const ConditionalDynamicField = ({
  field,
  namespace,
  fullNamespace,
  fieldsToWatch
}: ConditionalDynamicFieldProps) => {
  const form = Form.useFormInstance();

  const currentValues: { [key: string]: unknown } = {};
  fieldsToWatch.forEach((f) => {
    const fieldName = camelCase(f);
    currentValues[fieldName] = form.getFieldValue([...fullNamespace, fieldName]);
  });

  const isConditionMatched = evalConditionalField({ field, currentValues });

  // if the fields is not shown, it sets it value to nil. it prevents issues on conditions
  useEffect(() => {
    if (!isConditionMatched) {
      form.setFieldValue([...fullNamespace, camelCase(field.name)], null);
    }
  }, [isConditionMatched, field, fullNamespace, form]);

  return (
    isConditionMatched && (
      <Col span={field.columns || 24}>
        <DynamicField field={field} namespace={namespace} />
      </Col>
    )
  );
};

export const DynamicFormItem = ({
  field,
  namespace = ['data'],
  ...props
}: DynamicFormItemProps) => {
  const fullNamespace = props.fullNamespace || namespace;
  const fieldsToWatch = useMemo(
    () =>
      hasCondition(field) && field.conditionExpression
        ? extractFieldsForCondition(field.conditionExpression)
        : [],
    [field]
  );

  // Prevents running shouldUpdate on fields that don't have a condition
  if (fieldsToWatch.length === 0) {
    return (
      <Col span={field.columns || 24}>
        <DynamicField field={field} namespace={namespace} />
      </Col>
    );
  }

  return (
    <Form.Item
      noStyle
      shouldUpdate={(prevValues, currentValues) =>
        fieldsToWatch.length
          ? fieldsToWatch.some((f) => {
              const fullPath = [...fullNamespace, camelCase(f)];
              return get(prevValues, fullPath) !== get(currentValues, fullPath);
            })
          : false
      }
    >
      {() => (
        <ConditionalDynamicField
          fieldsToWatch={fieldsToWatch}
          field={field}
          namespace={namespace}
          fullNamespace={fullNamespace}
        />
      )}
    </Form.Item>
  );
};
