import { useMemo } from 'react';
import { Button, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import uniqBy from 'lodash.uniqby';
import { t } from '@gowgates/utils';
import { TableFilters } from '@gowgates/core';
import { Claim, Task } from '../../../../types';
import { ReassignTasks } from '../../../tasks/ReassignTasks';
import TableSearch from '../../../../components/TableSearch';
import { Filters } from './Filters';

type TableHeaderProps = {
  claim: Claim;
  selectedRows: React.Key[];
  setModalOpen: (modalOpen: boolean) => void;
  tasks: Task[];
};

const TableHeader = ({ claim, selectedRows, setModalOpen, tasks }: TableHeaderProps) => {
  const taskNameList = useMemo(() => uniqBy(tasks, 'name').map((t) => t.name), [tasks]);

  return (
    <>
      <Row gutter={8}>
        <Col span={15}>
          <TableSearch />
        </Col>
        <Col span={2}>
          <TableFilters content={<Filters taskNameList={taskNameList} />} />
        </Col>

        <Col span={7} className="d-flex justify-content-end">
          {claim.permissions.createTasks && (
            <Button type="primary" onClick={() => setModalOpen(true)} icon={<PlusOutlined />}>
              {t('task.add')}
            </Button>
          )}
        </Col>
      </Row>
      <Row>
        <Col span={17}>
          <ReassignTasks
            selectedRows={selectedRows}
            cacheKey={['claim', claim.id, 'tasks']}
            addControlHeightOnEmpty={false}
          />
        </Col>
      </Row>
    </>
  );
};

export default TableHeader;
