import { Form, Row, Col, Button, App, Input } from 'antd';
import { useMutation } from '@tanstack/react-query';

import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';

import { updatePassword } from '../../api/endpoints';

const Account = () => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  usePageTitle(t('account.changePassword'));

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: updatePassword,
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: () => {
      form.resetFields();
      message.success(t('user.passwordUpdated'));
    }
  });

  return (
    <Form form={form} layout="vertical" onFinish={mutate} disabled={isLoading}>
      <Row>
        <Col span={16} offset={4}>
          <FormItem name="currentPassword" model="user" required>
            <Input.Password />
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col span={16} offset={4}>
          <FormItem name="password" model="user" required>
            <Input.Password />
          </FormItem>
        </Col>
      </Row>

      <Row>
        <Col span={16} offset={4}>
          <FormItem name="passwordConfirmation" model="user" required>
            <Input.Password />
          </FormItem>
        </Col>
      </Row>

      <footer className="d-flex justify-content-end">
        <Button type="primary" htmlType="submit" loading={isLoading}>
          {t('globals.save')}
        </Button>
      </footer>
    </Form>
  );
};

export default Account;
