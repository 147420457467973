import { useMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';

import { t } from '@gowgates/utils';

const PermissionNav = () => {
  const match = useMatch({ path: '/permissions/:permissionId/:tab', end: false });

  return (
    <Menu
      selectedKeys={match?.params?.tab ? [match?.params?.tab] : undefined}
      mode="horizontal"
      className="box-menu"
      items={[
        {
          key: 'permissions',
          label: <Link to="permissions">{t('activerecord.models.permission.other')}</Link>
        },
        {
          key: 'users',
          label: <Link to="users">{t('activerecord.models.user.other')}</Link>
        }
      ]}
    />
  );
};

export default PermissionNav;
