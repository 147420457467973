import { Form, Modal } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { createTaskTrigger } from '../../../api/endpoints';

import { TaskStructure, TaskTrigger } from '../../../types';
import ModalFormFooter from '../../../components/ModalFormFooter';
import TaskTriggerForm from './Form';

type TaskTriggerModalProps = {
  isOpen?: boolean;
  setOpen: (open: boolean) => void;
  event: string;
  taskStructure: TaskStructure;
};

const TaskTriggerModal = ({
  isOpen = false,
  setOpen,
  event,
  taskStructure
}: TaskTriggerModalProps) => {
  const journeyId = Number(useParams().journeyId);
  const taskId = Number(useParams().taskId);
  const queryClient = useQueryClient();
  const [form] = Form.useForm<TaskTrigger>();

  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: TaskTrigger) => createTaskTrigger(journeyId, taskId, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.setQueryData(['taskStructure', taskId], data);
      closeModal();
    }
  });

  return (
    <Modal title={t(`taskTrigger.add.${event}`)} open={isOpen} onCancel={closeModal} footer={null}>
      <Form
        form={form}
        layout="vertical"
        onFinish={mutate}
        initialValues={{ event }}
        disabled={isLoading}
      >
        <TaskTriggerForm form={form} taskStructure={taskStructure} />

        <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
      </Form>
    </Modal>
  );
};

export default TaskTriggerModal;
