import { useState } from 'react';
import { EyeOutlined } from '@ant-design/icons';
import { Button, Modal, Space, Tooltip, Typography } from 'antd';

import { t } from '@gowgates/utils';

import useStructures from '../../../hooks/useStructures';
import { ClaimStructure, Structure } from '../../../types';

type StructureSummaryProps = {
  claimStructure: ClaimStructure;
};

const StructureSummary = ({ claimStructure }: StructureSummaryProps) => {
  const { structures: allStructures } = useStructures();

  const [isModalOpen, setModalOpen] = useState(false);
  const [currentStructure, setCurrentStructure] = useState<Structure>();

  const structures = allStructures?.filter(
    (structure) => structure.claimStructureId === claimStructure.id
  );

  const showDetails = (structure: Structure) => {
    setCurrentStructure(structure);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <aside className="page-summary">
      <dl>
        <dt>{t('claimStructure.versions')}</dt>
        <dd>
          {structures?.map((structure) => (
            <Space key={structure.id}>
              {structure.version}
              {structure.status === 'active' ? (
                <span> (current)</span>
              ) : (
                <Tooltip title="Show version details">
                  <Typography.Link onClick={() => showDetails(structure)}>
                    <EyeOutlined />
                  </Typography.Link>
                </Tooltip>
              )}
            </Space>
          ))}
        </dd>
      </dl>

      <Modal
        title={`${claimStructure.name} (v${currentStructure?.version || ''})`}
        open={isModalOpen}
        onCancel={closeModal}
        footer={[
          <Button onClick={closeModal} key="close">
            {t('globals.close')}
          </Button>
        ]}
      >
        TODO: Show structure details
      </Modal>
    </aside>
  );
};

export default StructureSummary;
