import { t } from '@gowgates/utils';

import Box from '../../../../components/Box';
import ClaimantDescriptions from '../../descriptions/Claimant';
import ClaimDescriptions from '../../descriptions/Claim';
import ClientDescriptions from '../../descriptions/Client';
import PolicyDescriptions from '../../descriptions/Policy';
import { claimDetailsLabel } from '../../utils/claim';
import DocumentsDescription from '../../descriptions/Documents';
import Sections from '../../components/Sections';
import SectionDescription from '../../descriptions/Section';
import ItemsTable from '../../components/Items/Table';
import CreateClaimForm from '../CreateClaimForm';
import useClaim from '../../../../hooks/useClaim';
import FileDetailsDescriptions from '../../descriptions/FileDetails';
import useAppConfigs from '../../../../hooks/useAppConfigs';

const CreateClaimDeclaration = () => {
  const { claim } = useClaim();
  const { appConfigs } = useAppConfigs();

  if (!appConfigs) return null;
  const fileConfig = appConfigs.fileConfig;

  return (
    <>
      <Box title={fileConfig.fileDetailsLabel}>
        <FileDetailsDescriptions />
      </Box>

      <Box title={t('claim.clientDetails')}>
        <ClientDescriptions />
      </Box>

      <Box title={t('claim.claimantDetails')}>
        <ClaimantDescriptions />
      </Box>

      {fileConfig.policyDetailsActive && (
        <Box title={t('claim.policyDetails')}>
          <PolicyDescriptions />
        </Box>
      )}

      <Box title={claimDetailsLabel(claim.structure)}>
        <ClaimDescriptions />
      </Box>

      <Box title={t('claim.backofficeCreateStatuses.sectionDetails')}>
        <Sections claim={claim}>
          {(sectionStructure, section) => (
            <section className="spacer-bottom" key={sectionStructure.slug}>
              <h4>{sectionStructure.name}</h4>
              <SectionDescription sectionStructure={sectionStructure} section={section} />

              <ItemsTable claim={claim} sectionStructure={sectionStructure} section={section} />
            </section>
          )}
        </Sections>
      </Box>

      <Box title={t('claim.backofficeCreateStatuses.uploads')}>
        <DocumentsDescription claim={claim} />
      </Box>

      <CreateClaimForm />
    </>
  );
};

export default CreateClaimDeclaration;
