import { useState } from 'react';

import { User as UserType } from '@gowgates/core';

import UserDetails from './components/Details';
import EditUserForm from './EditUserForm';

type UserProps = {
  user: UserType;
};

const User = ({ user }: UserProps) => {
  const [isEditing, setEditing] = useState(false);

  if (user.permissions.update && isEditing) {
    return (
      <EditUserForm
        user={user}
        onCancel={() => setEditing(false)}
        onSuccess={() => setEditing(false)}
      />
    );
  }

  return <UserDetails user={user} onEditClick={() => setEditing(true)} />;
};

export default User;
