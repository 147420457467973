import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { FormBuilder } from '@gowgates/dynamic-fields';

import { SectionStructure } from '../../../../types';

const StructureSectionItems = () => {
  const sectionId = Number(useParams().sectionId);
  const queryClient = useQueryClient();
  const sectionStructure = queryClient.getQueryData<SectionStructure>([
    'sectionStructure',
    sectionId
  ]);

  usePageTitle(
    `${sectionStructure?.name} - ${t('activerecord.models.item.other')} | ${t(
      'activerecord.models.claimStructure.other'
    )}`
  );

  return (
    <FormBuilder fieldableId={sectionStructure?.itemStructureId} fieldableType="item_structure" />
  );
};

export default StructureSectionItems;
