import { Tooltip } from 'antd';
import { ExportOutlined } from '@ant-design/icons';

import { t } from '@gowgates/utils';

import { insightClientURL } from '../utils/insight';
import { Claim } from '../../../types';

type ShowClientInInsightProps = {
  claim: Claim;
};

const ShowClientInInsight = ({ claim }: ShowClientInInsightProps) => {
  if (!claim.insightLedger || !claim.client?.insightId) {
    return null;
  }

  return (
    <Tooltip title={t('globals.showInInsight')}>
      <a
        href={insightClientURL(claim.insightLedger, claim.client.insightId)}
        target="_blank"
        rel="noreferrer"
      >
        <ExportOutlined />
      </a>
    </Tooltip>
  );
};

export default ShowClientInInsight;
