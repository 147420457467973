import { Button, Input, Select, Space } from 'antd';
import { useQuery } from '@tanstack/react-query';

import { FormItem } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';
import { User } from '@gowgates/core';

import {
  getResourceRoles,
  getResourcePermissions,
  getResourceScopes
} from '../../../api/endpoints';
import InnerBox from '../../../components/InnerBox';

type UserFormProps = {
  user: User;
  onCancel?: () => void;
};
const UserForm = ({ user, onCancel }: UserFormProps) => {
  const { data: roles } = useQuery({
    queryKey: ['resourceRoles'],
    queryFn: getResourceRoles,
    placeholderData: []
  });

  const { data: permissions } = useQuery({
    queryKey: ['resourcePermissions'],
    queryFn: getResourcePermissions,
    placeholderData: []
  });

  // TODO: Move to hooks
  const { data: scopes } = useQuery({
    queryKey: ['resourceScopes'],
    queryFn: getResourceScopes,
    placeholderData: []
  });

  return (
    <>
      <InnerBox>
        <FormItem name="firstName" model="user">
          <Input />
        </FormItem>

        <FormItem name="lastName" model="user">
          <Input />
        </FormItem>

        <FormItem name="email" model="user">
          <Input />
        </FormItem>

        <FormItem name="roleId" model="user">
          <Select
            options={roles}
            fieldNames={{ value: 'id', label: 'name' }}
            disabled={!user.permissions.updateRole}
          />
        </FormItem>

        {user.permissions.showScopes && (
          <FormItem name="scopeIds" model="user">
            <Select
              mode="multiple"
              placeholder="Please select"
              options={scopes}
              fieldNames={{ value: 'id', label: 'name' }}
              disabled={!user.permissions.updateScopes}
            />
          </FormItem>
        )}

        {user.permissions.showPermissions && (
          <FormItem name="permissionIds" model="user">
            <Select
              mode="multiple"
              placeholder="Please select"
              options={permissions}
              fieldNames={{ value: 'id', label: 'name' }}
              disabled={!user.permissions.updatePermissions}
            />
          </FormItem>
        )}
      </InnerBox>

      <div className="d-flex justify-content-end">
        <Space>
          {onCancel && <Button onClick={onCancel}>{t('globals.cancel')}</Button>}
          <Button type="primary" htmlType="submit">
            {t('globals.save')}
          </Button>
        </Space>
      </div>
    </>
  );
};

export default UserForm;
