import { Button, GetProps } from 'antd';
import { useNavigate } from 'react-router-dom';

type ButtonPropsType = GetProps<typeof Button>;
type LinkButtonProps = Omit<ButtonPropsType, 'href' | 'onClick'> & { to: string };

const LinkButton = ({ to, children, ...rest }: LinkButtonProps) => {
  const navigate = useNavigate();

  return (
    <Button
      type="primary"
      href={to}
      onClick={(event) => {
        event.preventDefault();
        navigate(to);
      }}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default LinkButton;
