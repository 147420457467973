import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { useTenantQuery } from '@gowgates/claims-gateway-shared';

import FullLoader from '../../../components/FullLoader';
import { ColumnsConfig } from '../ColumnsConfig';

export const TasksColumns = () => {
  usePageTitle(t('activerecord.attributes.tenant.tasksColumns'));

  const { isFetching } = useTenantQuery();

  if (isFetching) {
    return <FullLoader />;
  }

  return <ColumnsConfig table="tasksColumns" />;
};
