import { t } from '@gowgates/utils';
import {
  ColumnConfigTypes,
  Tenant,
  ColumnDataType,
  ColumnModel
} from '@gowgates/claims-gateway-shared';
import { Form, FormListFieldData, Select, Space } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';

import useFileConfig from '../../../hooks/useFileConfig';
import useStructures from '../../../hooks/useStructures';

type AttributeUpdatedType = (prevValues: Tenant, currentValues: Tenant) => boolean;

type Props = {
  column: FormListFieldData;
  remove: (fieldName: FormListFieldData['name']) => void;
  table: ColumnConfigTypes;
};

export const ColumnConfig = ({ column, remove, table }: Props) => {
  const { name, key, ...restField } = column;
  const { claimantStructure, clientStructure, coverStructure } = useFileConfig();
  const { activeStructures, findStructure } = useStructures();

  // TODO: should only show client and policy according to fileConfig data
  // const fileConfig = appConfigs.fileConfig;
  // fileConfig.clientDetailsActive
  // fileConfig.policyDetailsActive

  const nonClaimFields = {
    claimant: claimantStructure?.map((field) => ({ value: field.name, label: field.label })),
    client: clientStructure?.map((field) => ({ value: field.name, label: field.label })),
    cover: coverStructure?.map((field) => ({ value: field.name, label: field.label })),
    task: [{}],
    claim: [{}]
  };

  const dataTypes = [
    { value: 'fixed', label: 'Fixed' },
    { value: 'dynamic', label: 'Dynamic' }
  ];

  const models = {
    fixed: [
      { value: 'task', label: t('activerecord.models.task.one') },
      { value: 'claim', label: t('activerecord.models.claim.one') }
    ],
    dynamic: [
      { value: 'claim', label: t('activerecord.models.claim.one') },
      { value: 'claimant', label: t('activerecord.models.claimant.one') },
      { value: 'client', label: t('activerecord.models.client.one') },
      { value: 'cover', label: t('activerecord.models.cover.one') }
    ]
  };

  const fixedFields = {
    task: [
      { value: 'id', label: t('activerecord.attributes.claim.id') },
      { value: 'userId', label: t('activerecord.attributes.task.userId') },
      { value: 'claimId', label: t('activerecord.attributes.task.claimId') },
      { value: 'itemId', label: t('activerecord.attributes.task.itemId') },
      { value: 'taskStructureId', label: t('activerecord.attributes.task.taskStructureId') },
      { value: 'dueAt', label: t('activerecord.attributes.task.dueAt') },
      { value: 'status', label: t('activerecord.attributes.task.status') },
      { value: 'createdById', label: t('activerecord.attributes.task.createdBy') },
      { value: 'description', label: t('activerecord.attributes.task.description') },
      { value: 'createdAt', label: t('activerecord.attributes.task.createdAt') },
      { value: 'roleId', label: t('activerecord.attributes.task.roleId') }
    ],
    claim: [
      { value: 'id', label: t('activerecord.attributes.claim.id') },
      { value: 'structureId', label: t('activerecord.attributes.claim.structureId') },
      { value: 'status', label: t('activerecord.attributes.claim.status') },
      { value: 'lastActionAt', label: t('activerecord.attributes.claim.lastActionAt') },
      { value: 'assigneeId', label: t('activerecord.attributes.claim.assigneeId') },
      { value: 'insurerNumber', label: t('activerecord.attributes.claim.insurerNumber') },
      { value: 'liability', label: t('activerecord.attributes.claim.liability') },
      { value: 'paidAmount', label: t('activerecord.attributes.claim.paidAmount') },
      {
        value: 'settlementReserve',
        label: t('activerecord.attributes.claim.settlementReserve')
      },
      {
        value: 'legalCostReserve',
        label: t('activerecord.attributes.claim.legalCostReserve')
      },
      {
        value: 'assessorsReserve',
        label: t('activerecord.attributes.claim.assessorsReserve')
      },
      { value: 'otherReserve', label: t('activerecord.attributes.claim.otherReserve') },
      {
        value: 'recoveryReserve',
        label: t('activerecord.attributes.claim.recoveryReserve')
      },
      { value: 'createdAt', label: t('activerecord.attributes.claim.createdAt') }
    ],
    claimant: [{}],
    client: [{}],
    cover: [{}]
  };

  const dataTypeUpdated: AttributeUpdatedType = (prevValues, currentValues) =>
    prevValues[table][name]?.dataType !== currentValues[table][name]?.dataType;

  const typeOrModelUpdated: AttributeUpdatedType = (prevValues, currentValues) =>
    dataTypeUpdated(prevValues, currentValues) ||
    prevValues[table][name]?.model !== currentValues[table][name]?.model;

  const structureIdUpdated: AttributeUpdatedType = (prevValues, currentValues) =>
    prevValues[table][name]?.structureId !== currentValues[table][name]?.structureId;

  return (
    <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
      <Form.Item {...restField} name={[name, 'dataType']}>
        <Select options={dataTypes} placeholder="Select a type" />
      </Form.Item>

      <Form.Item noStyle shouldUpdate={typeOrModelUpdated}>
        {({ getFieldValue }) => (
          <Form.Item {...restField} name={[name, 'model']}>
            <Select
              options={models[getFieldValue(table)[name]?.dataType as ColumnDataType]}
              placeholder="Select an entity"
            />
          </Form.Item>
        )}
      </Form.Item>

      <Form.Item noStyle shouldUpdate={typeOrModelUpdated}>
        {({ getFieldValue }) =>
          getFieldValue(table)[name]?.dataType === 'fixed' &&
          fixedFields[getFieldValue(table)[name]?.model as ColumnModel] && (
            <Form.Item name={[name, 'value']}>
              <Select
                options={fixedFields[getFieldValue(table)[name]?.model as ColumnModel]}
                style={{ width: 200 }}
              />
            </Form.Item>
          )
        }
      </Form.Item>

      <Form.Item noStyle shouldUpdate={typeOrModelUpdated}>
        {({ getFieldValue }) =>
          getFieldValue(table)[name]?.dataType === 'dynamic' &&
          getFieldValue(table)[name]?.model &&
          getFieldValue(table)[name]?.model !== 'claim' && (
            <Form.Item name={[name, 'value']}>
              <Select
                options={nonClaimFields[getFieldValue(table)[name]?.model as ColumnModel]}
                style={{ width: 200 }}
              />
            </Form.Item>
          )
        }
      </Form.Item>

      <Form.Item noStyle shouldUpdate={typeOrModelUpdated}>
        {({ getFieldValue }) =>
          getFieldValue(table)[name]?.dataType === 'dynamic' &&
          getFieldValue(table)[name]?.model === 'claim' && (
            <Form.Item name={[name, 'structureId']}>
              <Select
                options={activeStructures?.map((structure) => ({
                  value: structure.id,
                  label: structure.name
                }))}
                style={{ width: 200 }}
              />
            </Form.Item>
          )
        }
      </Form.Item>

      <Form.Item noStyle shouldUpdate={structureIdUpdated}>
        {({ getFieldValue }) => {
          const options = findStructure(getFieldValue(table)[name]?.structureId)?.data.claim.map(
            (field) => ({ value: field.name, label: field.label })
          );

          return (
            getFieldValue(table)[name]?.structureId && (
              <Form.Item name={[name, 'value']}>
                <Select options={options} style={{ width: 200 }} />
              </Form.Item>
            )
          );
        }}
      </Form.Item>

      <MinusCircleOutlined onClick={() => remove(name)} />
    </Space>
  );
};
