import { useQuery } from '@tanstack/react-query';
import { Outlet, useParams } from 'react-router';

import { usePageTitle, Loader } from '@gowgates/core';
import { t } from '@gowgates/utils';

import { getClaimNotes } from '../../../../api/endpoints';

const ClaimNotes = () => {
  const claimId = Number(useParams().claimId);
  usePageTitle(`${t('claim.id', { id: claimId })} - ${t('activerecord.models.note.other')}`);

  const { isLoading } = useQuery({
    queryKey: ['claim', claimId, 'notes'],
    queryFn: () => getClaimNotes(claimId),
    staleTime: 120 * 1000
  });

  if (isLoading) {
    return (
      <div className="box-loader-container">
        <Loader />
      </div>
    );
  }

  return <Outlet />;
};

export default ClaimNotes;
