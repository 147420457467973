import { Alert, ConfigProvider, theme } from 'antd';

import { t } from '@gowgates/utils';

import { VulnerabilityIcon } from '../../../../icons';
import { ClaimWithStructure } from '../../../../types';

type ClaimVulnerabilityProps = { claim: ClaimWithStructure };

const ClaimVulnerability = ({ claim }: ClaimVulnerabilityProps) => {
  const { token } = theme.useToken();

  if (!claim.vulnerableCustomer) {
    return null;
  }

  return (
    <ConfigProvider theme={{ token: { colorWarning: token.orange6 } }}>
      <Alert
        message={t('activerecord.attributes.claim.vulnerableCustomer')}
        type="warning"
        showIcon
        icon={<VulnerabilityIcon />}
      />
    </ConfigProvider>
  );
};

export default ClaimVulnerability;
