import React from 'react';
import { Empty } from 'antd';
import { camelCase } from 'change-case';

import { t } from '@gowgates/utils';

import { ClaimWithStructure, Section, SectionStructure } from '../../../types';

type SectionsProps = {
  claim: ClaimWithStructure;
  children:
    | React.ReactNode
    | ((sectionStructure: SectionStructure, claimSection?: Section) => React.ReactNode);
};

const countSections = (
  sections: ClaimWithStructure['structure']['sections'],
  claim: ClaimWithStructure
) =>
  sections.filter((sectionStructure) => !!claim.sections[camelCase(sectionStructure.slug)]).length;

const Sections = ({ claim, children }: SectionsProps) => {
  const sectionsCount = countSections(claim.structure?.sections, claim);

  if (sectionsCount < 1) {
    return (
      <Empty
        className="empty-sections"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={t('section.empty')}
      />
    );
  }

  return (
    <>
      {claim.structure.sections.map(
        (sectionStructure) =>
          !!claim.sections[camelCase(sectionStructure.slug)] && (
            <React.Fragment key={sectionStructure.slug}>
              {typeof children === 'function'
                ? children(sectionStructure, claim.sections[camelCase(sectionStructure.slug)])
                : children}
            </React.Fragment>
          )
      )}
    </>
  );
};

export default Sections;
