import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';

import FullLoader from '../../components/FullLoader';
import useClaim from '../../hooks/useClaim';
import ClaimConflict from './show/components/ConflictFile';
import ClaimDetails from './show';
import CreateClaimContainer from './create/container';

const Claim = () => {
  const { isLoading, claim, claimId } = useClaim();
  usePageTitle(t('claim.id', { id: claimId }));

  if (isLoading) {
    return <FullLoader />;
  }

  if (claim.conflict) {
    return <ClaimConflict />;
  }

  if (claim.status === 'draft' || claim.justSubmitted) {
    return <CreateClaimContainer claim={claim} />;
  }

  return <ClaimDetails claim={claim} />;
};

export default Claim;
