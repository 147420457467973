import states from '../resources/states.json';

export const DEFAULT_CLAIMS_PATH = '/claims?requires_action=true';

export const STATES = states;

export const AUSTRALIA = 'AU';
export const AUSTRALIAN_DOLLAR = 'AUD';
export const AUSTRALIAN_DOLLAR_SYMBOL = '$';

export const DEFAULT_CURRENCY = AUSTRALIAN_DOLLAR;

export const PRICE_FORMAT = '0,0.00';

export const SLIM_TEXT_QUILL_MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }]
  ],
  uploader: false
};

export const ADVANCED_TEXT_QUILL_MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ size: ['small', false, 'large'] }],
    [{ header: [1, 2, 3, false] }]
  ],
  uploader: false
};

export const DEFAULT_PAGINATION = {
  defaultPageSize: 25,
  pageSizeOptions: [25, 50, 100],
  total: 0
};

export const frontendURL = `${window.location.protocol}//${window.location.host}`;
