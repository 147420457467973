import { camelCase } from 'change-case';
import { Col, Form, Input, Row } from 'antd';

import { FormItem, DynamicForm } from '@gowgates/dynamic-fields';
import { SectionStructure } from '../../../types';

type SectionFormProps = {
  sectionStructure: SectionStructure;
  namespaced?: boolean;
};

const SectionForm = ({ sectionStructure, namespaced = false }: SectionFormProps) => {
  const namespace = namespaced ? ['sections', camelCase(sectionStructure.slug)] : [];

  return (
    <section>
      <Row className="structure-validator-errors">
        <Col span={16} offset={8}>
          <Form.Item
            name={[...namespace, 'data', 'base', 'data', camelCase(sectionStructure.slug)]}
          >
            <Input hidden />
          </Form.Item>
        </Col>
      </Row>

      <FormItem name={[...namespace, 'type']} hidden>
        <Input />
      </FormItem>

      <DynamicForm fields={sectionStructure.fields} namespace={[...namespace, 'data']} />
    </section>
  );
};

export default SectionForm;
