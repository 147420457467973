import { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { ClaimWithStructure, Item, Section, SectionStructure } from '../../../../types';
import ItemsTable from '../Items/Table';
import EditItemModal from '../Items/EditItemModal';
import ItemDetailsModal from '../Items/DetailsModal';
import SectionAttributesModal from './AttributesModal';
import SectionHeader from './Header';

type SectionHeaderProps = {
  sectionStructure: SectionStructure;
  section: Section;
  claim: ClaimWithStructure;
};

const SectionDetails = ({ sectionStructure, section, claim }: SectionHeaderProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const initialItem = searchParams.has('id')
    ? section?.items?.find((i: Item) => searchParams.get('id') === String(i.id))
    : undefined;
  const [isItemFormModalOpen, setItemFormModalOpen] = useState(
    initialItem ? initialItem.status === 'draft' : false
  );
  const [isItemDetailsModalOpen, setItemDetailsModalOpen] = useState(
    initialItem ? initialItem.status !== 'draft' : false
  );
  const [isAttributesModalOpen, setAttributesModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(initialItem || { data: {} });

  const navigateToItem = (item: Partial<Item>) => {
    const nextSearchParams = new URLSearchParams(location.search);
    if (item?.id) {
      nextSearchParams.set('id', String(item.id));
    } else if (nextSearchParams.has('id')) {
      nextSearchParams.delete('id');
    }

    navigate(`${location.pathname}?${nextSearchParams.toString()}`, { preventScrollReset: true });
  };

  const editItem = (item: Partial<Item>) => {
    setCurrentItem(item);
    setItemFormModalOpen(true);

    navigateToItem(item);
  };

  const showItem = (item: Partial<Item>) => {
    setCurrentItem(item);
    setItemDetailsModalOpen(true);

    navigateToItem(item);
  };

  const addItem = () => {
    editItem({ sectionId: section.id });
  };

  const showSectionAttributes = () => {
    setAttributesModalOpen(true);
  };

  return (
    <section className="inner-box">
      <SectionHeader
        sectionStructure={sectionStructure}
        showSectionAttributes={showSectionAttributes}
        addItem={addItem}
        canCreateItems={claim.permissions.createItems}
      />

      <ItemsTable
        claim={claim}
        sectionStructure={sectionStructure}
        section={section}
        editItem={editItem}
        showItem={showItem}
      />

      <EditItemModal
        isOpen={isItemFormModalOpen}
        setOpen={(o) => {
          setItemFormModalOpen(o);
          navigateToItem(o && currentItem);
        }}
        item={currentItem}
        structure={sectionStructure}
      />

      <ItemDetailsModal
        isOpen={isItemDetailsModalOpen}
        setOpen={(o) => {
          setItemDetailsModalOpen(o);
          navigateToItem(o && currentItem);
        }}
        item={currentItem}
        structure={sectionStructure}
      />

      <SectionAttributesModal
        isOpen={isAttributesModalOpen}
        setOpen={setAttributesModalOpen}
        structure={sectionStructure}
        section={section}
      />
    </section>
  );
};

export default SectionDetails;
