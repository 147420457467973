import { Dropdown, Modal, Space, Tag, Typography } from 'antd';
import { ArrowLeftOutlined, ExclamationCircleOutlined, MoreOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { t } from '@gowgates/utils';
import { DateFormatter } from '@gowgates/dynamic-fields';
import { RichTextHtml } from '@gowgates/core';

import { deleteNote } from '../../../../api/endpoints';
import DirectionDescription from '../Notes/DirectionDescription';
import NatureIcon from '../Notes/NatureIcon';
import { Note } from '../../../../types';

const Notes = () => {
  const claimId = Number(useParams().claimId);
  const noteId = Number(useParams().noteId);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { data: notes } = useQuery<Note[]>({ queryKey: ['claim', claimId, 'notes'] });
  const note = notes?.find((n) => n.id === noteId);

  const { mutate } = useMutation({
    mutationFn: () => deleteNote(note.id),
    onSuccess: () => {
      navigate(`/claims/${claimId}/notes`);
      queryClient.invalidateQueries({ queryKey: ['claim', claimId, 'notes'] });
    }
  });

  const destroy = () => {
    Modal.confirm({
      title: t('note.confirmDestroy'),
      icon: <ExclamationCircleOutlined />,
      okText: t('globals.yesNo.true'),
      okType: 'danger',
      cancelText: t('globals.yesNo.false'),
      onOk() {
        mutate();
      }
    });
  };

  const items = [];
  if (note.permissions.destroy) {
    items.push({ key: 'destroy', label: t('globals.delete'), danger: true, onClick: destroy });
  }

  return (
    <section className="note-detail">
      <header>
        <h3>
          <Space>
            <Link to={`/claims/${claimId}/notes`}>
              <ArrowLeftOutlined />
            </Link>

            {note.subject}

            {items.length > 0 && (
              <Dropdown menu={{ items }} trigger={['click']}>
                <MoreOutlined />
              </Dropdown>
            )}
          </Space>
        </h3>
      </header>

      <div>
        <Space>
          <DirectionDescription note={note} />
          {note.nature && (
            <Tag>
              <NatureIcon nature={note.nature} />
              <span>{t(`note.natures.${note.nature}`)}</span>
            </Tag>
          )}
        </Space>
      </div>

      <RichTextHtml Component="main" htmlText={note.text} />

      <Typography.Text type="secondary">
        <small>Added on </small>
        <strong>
          <DateFormatter value={note.createdAt} format="minute" />
        </strong>
        <small> by </small>
        <strong>{note.user?.name}</strong>
      </Typography.Text>
    </section>
  );
};

export default Notes;
