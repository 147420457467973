import { Row } from 'antd';

import { ModelWithData } from '@gowgates/utils';

import { Field } from '../../types';
import { ConditionalDescription } from '../ConditionalDescription';

type DynamicFormItemProps = {
  fields?: Field[];
  model?: ModelWithData;
};

export const DynamicDescriptions = ({ model, fields }: DynamicFormItemProps) =>
  model && (
    <dl className="cg-descriptions">
      <Row gutter={24}>
        {fields?.map((field) => (
          <ConditionalDescription key={field.id || field.position} model={model} field={field} />
        ))}
      </Row>
    </dl>
  );
