import { PropsWithChildren, useState } from 'react';
import { Col, Row, Typography } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { AppConfigs } from '../../../types/app-configs';
import { updateFileConfig } from '../../../api/endpoints';
import useAppConfigs from '../../../hooks/useAppConfigs';
import RequiredSwitch from './RequiredSwitch';
import ActiveSwitch from './ActiveSwitch';

type InlineInputProps = PropsWithChildren<{
  labelKey?: keyof AppConfigs['fileConfig'];
  activeKey?: keyof AppConfigs['fileConfig'];
  requiredKey?: keyof AppConfigs['fileConfig'];
  editable?: boolean;
  fieldLabel?: string;
}>;

const toogleKeyValue = (
  fileConfig: AppConfigs['fileConfig'],
  toggleKey: keyof AppConfigs['fileConfig']
): boolean => {
  const toogleValue = fileConfig[toggleKey];
  const value = typeof toogleValue === 'boolean' ? toogleValue : false;

  return value;
};

const InlineInput = ({
  labelKey,
  activeKey,
  requiredKey,
  editable = false,
  fieldLabel,
  children
}: InlineInputProps) => {
  const { appConfigs } = useAppConfigs();
  const fileConfig = appConfigs?.fileConfig;
  const [label, setLabel] = useState(
    fieldLabel || (fileConfig && labelKey ? fileConfig[labelKey] : '')
  );
  const [active, setActive] = useState(
    fileConfig && activeKey ? toogleKeyValue(fileConfig, activeKey) : false
  );
  const [required, setRequired] = useState(
    fileConfig && requiredKey ? toogleKeyValue(fileConfig, requiredKey) : false
  );
  const currentlyEditable = editable || active;

  const queryClient = useQueryClient();
  const { mutate } = useMutation({
    mutationFn: (data: Partial<AppConfigs['fileConfig']>) => updateFileConfig(data)
  });

  const updateVal = (key: keyof AppConfigs['fileConfig'], val: string | boolean) => {
    mutate(
      { [key]: val },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['appConfigs'] });
        }
      }
    );
  };

  const setEditableStr = (val: string) => {
    if (!labelKey) return;

    updateVal(labelKey, val);
    setLabel(val);
  };

  const updateActive = (val: boolean) => {
    if (!activeKey) return;

    updateVal(activeKey, val);
    setActive(val);
  };

  const updateRequired = (val: boolean) => {
    if (!requiredKey) return;

    updateVal(requiredKey, val);
    setRequired(val);
  };

  return (
    <Row gutter={10} align="middle">
      <Col span={7}>
        {currentlyEditable ? (
          <Typography.Paragraph editable={{ onChange: setEditableStr }} className="text-right">
            {label}
          </Typography.Paragraph>
        ) : (
          <Typography.Paragraph className="text-right">{label}</Typography.Paragraph>
        )}
      </Col>
      <Col span={12}>{children}</Col>
      <Col span={2} offset={1}>
        <ActiveSwitch activeKey={activeKey} active={active} updateActive={updateActive} />
      </Col>
      <Col span={2}>
        <RequiredSwitch
          requiredKey={requiredKey}
          active={active}
          required={required}
          updateRequired={updateRequired}
        />
      </Col>
    </Row>
  );
};

export default InlineInput;
