import { Divider, Form, FormInstance } from 'antd';

import { Document, Item, Note, SectionStructure } from '../../../../../../types';
import ItemDescriptions from '../../../../descriptions/Item';
import ItemActions from './ItemActions';
import ItemDocuments from './ItemDocuments';
import ItemPaymentFields from './ItemPaymentFields';
import ItemNotes from './ItemNotes';

type ItemModalDetailsProps = {
  onSuccess: () => void;
  item: Item;
  sectionStructure: SectionStructure;
  setEditing: (e: boolean) => void;
  form: FormInstance<Item>;
  notes?: Note[];
  documents?: Document[];
};

const ItemModalDetails = ({
  item,
  sectionStructure,
  onSuccess = () => {},
  setEditing,
  form,
  documents = [],
  notes = []
}: ItemModalDetailsProps) => (
  <>
    <ItemDescriptions item={item} structure={sectionStructure} />

    <ItemDocuments documentsCount={item.documentsCount} documents={documents} />

    <Divider orientation="left">Payment details</Divider>

    <Form form={form} initialValues={item} layout="vertical">
      <ItemPaymentFields item={item} />
    </Form>

    <ItemActions item={item} setEditing={setEditing} onSuccess={onSuccess} />

    <ItemNotes notesCount={item.notesCount} notes={notes} />
  </>
);

export default ItemModalDetails;
