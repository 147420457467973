import { Tooltip } from 'antd';

import { ExportOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';

import { insightTransactionURL } from '../utils/insight';
import { Claim } from '../../../types';

type ShowTransactionInInsightProps = {
  claim: Claim;
};

const ShowTransactionInInsight = ({ claim }: ShowTransactionInInsightProps) => {
  if (!claim.insightLedger || !claim.insightPolicyTransactionId) {
    return null;
  }

  return (
    <Tooltip title={t('globals.showInInsight')}>
      <a
        href={insightTransactionURL(claim.insightLedger, claim.insightPolicyTransactionId)}
        target="_blank"
        rel="noreferrer"
      >
        <ExportOutlined />
      </a>
    </Tooltip>
  );
};

export default ShowTransactionInInsight;
