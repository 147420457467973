import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { t } from '@gowgates/utils';

type UserStatusTagProps = {
  status: string;
};

const UserStatusTag = ({ status }: UserStatusTagProps) => {
  if (status === 'active') {
    return (
      <Tag icon={<CheckCircleOutlined />} color="green">
        {t('user.statuses.active')}
      </Tag>
    );
  }

  return (
    <Tag icon={<CloseCircleOutlined />} color="error">
      {t('user.statuses.blocked')}
    </Tag>
  );
};

export default UserStatusTag;
