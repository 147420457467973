import { Form } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { QuillWithSend } from '@gowgates/core';
import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm } from '@gowgates/utils';

import { createNote } from '../../api/endpoints';
import { Note } from '../../types';

type NewProps = {
  onSubmit: (data: Note) => void;
  notableId: number;
  notableType: string;
  label?: string;
  onCancel?: () => void;
};

const NewNote = ({
  onSubmit = () => {},
  onCancel = () => {},
  label,
  notableId,
  notableType
}: NewProps) => {
  const [form] = Form.useForm();

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: Note) => createNote({ ...values, notableId, notableType }),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      form.resetFields();
      onSubmit(data);
    }
  });

  return (
    <Form form={form} layout="vertical" onFinish={mutate} disabled={isLoading}>
      <FormItem name="text" label={label}>
        <QuillWithSend loading={isLoading} onCancel={onCancel} />
      </FormItem>
    </Form>
  );
};

export default NewNote;
