import { t, DEFAULT_CLAIMS_PATH } from '@gowgates/utils';
import { ErrorPage } from '@gowgates/core';
import { useTenantQuery } from '@gowgates/claims-gateway-shared';

import LinkButton from '../../../../components/LinkButton';

const ClaimConflict = () => {
  const { tenant } = useTenantQuery();

  return (
    tenant && (
      <ErrorPage
        code={
          <>
            4<span className="accent">0</span>4
          </>
        }
        subtitle={t('claim.conflict')}
        button={<LinkButton to={DEFAULT_CLAIMS_PATH}>Back to claims</LinkButton>}
      />
    )
  );
};

export default ClaimConflict;
