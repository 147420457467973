import { Button, Form, Select, Space } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { FormItem } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';

import { DocumentStructure, DynamicClaimField, ReportField } from '../../../../types';
import { updateReportField } from '../../../../api/endpoints';
import useAppConfigs from '../../../../hooks/useAppConfigs';
import DocumentDynamic from './DocumentDynamic';
import FieldExpression from './FieldExpression';

type EditReportFieldProps = {
  reportField: ReportField;
  dynamicClaimFields: DynamicClaimField[];
  documentStructures: DocumentStructure[];
  endEditing: (id?: number) => void;
};
const EditReportField = ({
  reportField,
  dynamicClaimFields,
  documentStructures,
  endEditing
}: EditReportFieldProps) => {
  const structureId = Number(useParams().structureId);
  const reportId = Number(useParams().reportId);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { appConfigs } = useAppConfigs();

  const claimFields = [
    { name: 'id', label: t(`activerecord.attributes.claim.id`) },
    ...appConfigs.claim.staticFields
  ];

  const { mutate } = useMutation({
    mutationFn: (values) => updateReportField(structureId, reportId, reportField.id, values),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['reportStructure', reportId, 'fields'] });
      endEditing();
    }
  });

  const typeUpdated = (prevValues: ReportField, currentValues: ReportField) =>
    prevValues.type !== currentValues.type;

  return (
    <article className="report-field edit-report-field">
      <p>{reportField.pdfName}</p>

      <Form
        form={form}
        initialValues={reportField}
        onValuesChange={(changed) => {
          if (changed.type) {
            form.setFieldsValue({
              evalType: 'direct',
              fieldName: null,
              fieldExpression: null,
              resourceId: null
            });
          }
        }}
        onFinish={mutate}
        layout="vertical"
      >
        <FormItem name="type" model="reportField">
          <Select options={appConfigs.reportField.types} />
        </FormItem>

        <FormItem name="evalType" model="reportField">
          <Select options={appConfigs.reportField.evalTypes} />
        </FormItem>

        <Form.Item noStyle shouldUpdate={typeUpdated}>
          {({ getFieldValue }) =>
            getFieldValue('type') === 'claim_fixed' && <FieldExpression fields={claimFields} />
          }
        </Form.Item>

        <Form.Item noStyle shouldUpdate={typeUpdated}>
          {({ getFieldValue }) =>
            getFieldValue('type') === 'claim_dynamic' && (
              <FieldExpression fields={dynamicClaimFields} />
            )
          }
        </Form.Item>

        <Form.Item noStyle shouldUpdate={typeUpdated}>
          {({ getFieldValue }) =>
            getFieldValue('type') === 'document_dynamic' && (
              <DocumentDynamic documentStructures={documentStructures} />
            )
          }
        </Form.Item>

        <footer className="d-flex justify-content-end">
          <Space>
            <Button onClick={() => endEditing()}>{t('globals.close')}</Button>
            <Button type="primary" htmlType="submit">
              {t('globals.save')}
            </Button>
          </Space>
        </footer>
      </Form>
    </article>
  );
};

export default EditReportField;
