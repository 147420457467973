import { t } from '@gowgates/utils';

import { Claim, Structure, Task } from '../../../types';

export const claimDetailsLabel = (structureData?: Structure['data']) => {
  if (structureData?.claimName) {
    return t('claim.backofficeCreateStatuses.customClaimDetails', {
      name: structureData.claimName
    });
  }

  return t('claim.backofficeCreateStatuses.claimDetails');
};

export const isImportant = (claim: Claim) => claim.importance !== 'normal';

export const taskCompletedBy = (task: Task) => {
  if (task.status === 'completed' && task.frontoffice) {
    return t('activerecord.models.claimant.one');
  }

  return task.completedBy?.name;
};

export default {};
