import Box from '../../../../components/Box';
import useClaim from '../../../../hooks/useClaim';
import CreateClaimForm from '../CreateClaimForm';
import { DocumentsFormLazy } from '../../forms/Documents/DocumentsFormLazy';

const CreateClaimDocuments = () => {
  const { claim } = useClaim();

  return (
    <>
      <Box>
        <div className="documents-container">
          <DocumentsFormLazy claim={claim} />
        </div>
      </Box>

      <CreateClaimForm />
    </>
  );
};

export default CreateClaimDocuments;
