import { AxiosError } from 'axios';
import { App } from 'antd';

import { isUnprocessableEntity } from '@gowgates/api-client';
import { ExtendedDangerPopconfirm, ListItem } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';

import { deleteListItem } from '../../api/endpoints';

type DeleteListItemProps = {
  data: ListItem;
};

const DeleteListItem = ({ data }: DeleteListItemProps) => {
  const { message } = App.useApp();
  const onError = (error: AxiosError<{ errors: { base: string } }>) => {
    if (isUnprocessableEntity(error) && error?.response?.data.errors.base) {
      message.error(error.response.data.errors.base);
    }
  };

  const onSuccess = () => {
    message.success(t('list.deletedMessage'));
  };

  if (!data.permissions.destroy) return null;

  return (
    <div className="d-flex justify-content-end">
      <ExtendedDangerPopconfirm
        deleteFn={() => deleteListItem(data.id)}
        invalidateQueries={['listItems']}
        permission
        onSuccess={onSuccess}
        onError={onError}
      />
    </div>
  );
};

export default DeleteListItem;
