import { useEffect, useMemo, useState } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { useQuery } from '@tanstack/react-query';

import { t, AnyObject } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { DataPagination } from '@gowgates/api-client';

import { getAllClaims } from '../../../api/endpoints';
import {
  TableSorter,
  updatedFiltersFromParams,
  updatedSorterFromParams
} from '../../../utils/tables';
import Page from '../../../components/Page';
import useStructures from '../../../hooks/useStructures';
import ClaimsTable from './Table';
import ExportBtn from './ExportBtn';

const Reports = () => {
  const { search: searchQuery } = useLocation();
  const search = useMemo(() => qs.parse(searchQuery), [searchQuery]);
  const [filters, setFilters] = useState<AnyObject>({});
  const [pagination, setPagination] = useState<DataPagination>();
  const [sorter, setSorter] = useState<TableSorter>({});
  usePageTitle(t('activerecord.models.report.other'));

  const { activeStructures = [] } = useStructures();

  const { isFetching, data: claims } = useQuery({
    queryKey: ['claims', search],
    queryFn: () => getAllClaims(search)
  });

  useEffect(() => {
    if (claims) setPagination((prevPagination) => ({ ...prevPagination, ...claims.pagination }));
  }, [claims]);

  useEffect(() => {
    setSorter(updatedSorterFromParams(search));
    setFilters(updatedFiltersFromParams(search));
  }, [search]);

  return (
    <Page title={t('activerecord.models.report.other')} actions={<ExportBtn />}>
      <ClaimsTable
        claims={claims?.rows}
        loading={isFetching}
        pagination={pagination}
        sorter={sorter}
        filters={filters}
        structures={activeStructures}
      />
    </Page>
  );
};

export default Reports;
