import { Navigate } from 'react-router';

import usePermissions from '../../hooks/usePermissions';

export const TenantRootRedirect = () => {
  const { permissions } = usePermissions();

  let initialTab = 'general';
  if (!permissions.updateTenant && permissions.manageApiKeys) {
    initialTab = 'api-keys';
  } else if (!permissions.updateTenant && permissions.manageWebhooks) {
    initialTab = 'webhooks';
  }

  return <Navigate to={initialTab} replace />;
};
