import { Alert } from 'antd';

import { t } from '@gowgates/utils';

import { ClaimWithStructure } from '../../../../types';

type InsightImportedProps = {
  claim: ClaimWithStructure;
};
const InsightImported = ({ claim }: InsightImportedProps) => {
  if (!claim.insightImported) {
    return null;
  }

  return <Alert message={t('claim.insightImported')} type="warning" />;
};

export default InsightImported;
