import { useState } from 'react';
import { Modal, Button, Select, Input, Form, Space, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { DateFormatter, FormItem } from '@gowgates/dynamic-fields';
import { AnyObject, t, useCountries } from '@gowgates/utils';

import { deleteClaim, getPolicyTransaction } from '../../../api/endpoints';
import ModalFooter from '../../../components/ModalFooter';
import useIntegrations from '../../../hooks/useIntegrations';

const buildClaimValues = (data: AnyObject) => ({
  insightLedger: data.insightLedger,
  vulnerableCustomer: data.vulnerableCustomer
});

const buildCoverValues = (data: AnyObject) => ({
  insurer: data.insurerName || null,
  number: data.policyNumber || null,
  startsAt: data.fromDate || null,
  endsAt: data.toDate || null,
  classOfRisk: data.classOfRisk || null
});

const buildClientValues = (data: AnyObject) => ({
  insightId: data.id || null,
  insightSalesTeamId: data.insightSalesTeamId || null,
  insightSalesTeamName: data.insightSalesTeamName || null,
  insightServiceTeamId: data.insightServiceTeamId || null,
  insightServiceTeamName: data.insightServiceTeamName || null
});

const buildClientDataValues = (data: AnyObject) => ({
  name: data.name || null,
  abn: data.abn || null
});

const buildClaimantValues = (
  data: AnyObject,
  countries: ReturnType<typeof useCountries>['countries']
) => {
  const clientNames = data.name?.split(' ') || [null, null];
  let fullAddress = null;
  let country = null;

  if (data.addressLine1 || data.addressLine2 || data.addressLine1) {
    fullAddress = [data.addressLine1, data.addressLine2, data.addressLine3]
      .filter((addressLine) => !!addressLine)
      .join(', ');
  }

  if (data.countryName) {
    country = countries?.find(
      (c) => c.label.toLowerCase() === data.countryName.toLowerCase()
    )?.value;
  }

  return {
    email: data.email || null,
    firstName: clientNames.slice(0, clientNames.length - 1).join(' '),
    lastName: clientNames[clientNames.length - 1],
    country,
    addressStreet: fullAddress,
    addressCity: data.suburb || null,
    addressPostcode: data.postcode || null,
    addressState: data.stateName || null,
    phone: data.telephone || null,
    mobilePhone: data.mobile || null,
    workPhone: data.telephone2 || null
  };
};

type SearchInsightProps = {
  setUpdating: (u: boolean) => void;
  countries: ReturnType<typeof useCountries>['countries'];
};

const SearchInsight = ({ setUpdating = () => {}, countries }: SearchInsightProps) => {
  const form = Form.useFormInstance();
  const claimId = Number(useParams().claimId);
  const [duplicateClaims, setDuplicateClaims] = useState<AnyObject[]>([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const { insightIntegrationsForSelect } = useIntegrations();

  let timeout: NodeJS.Timeout;

  const { mutate: handleDestroy } = useMutation({
    mutationFn: () => deleteClaim(claimId),
    onSuccess: () => {
      navigate('/claims');
    }
  });

  const handleOk = () => {
    setModalOpen(false);
  };

  const setForm = (data = {}) => {
    form.setFieldsValue({
      ...buildClaimValues(data),
      cover: { data: { ...buildCoverValues(data) } },
      client: { ...buildClientValues(data), data: { ...buildClientDataValues(data) } },
      claimant: { data: { ...buildClaimantValues(data, countries) } }
    });
  };

  const handleCancel = () => {
    setForm();
    form.setFieldsValue({ insightPolicyTransactionId: null, integrationId: null });
    setModalOpen(false);
  };

  const { mutate } = useMutation({
    mutationFn: (values: any) => getPolicyTransaction(values.value, values.integrationId, claimId),
    onSuccess: (data) => {
      setForm(data);

      if (data.claims && data.claims.length > 0) {
        setDuplicateClaims(data.claims);
        setModalOpen(true);
      }
    },
    onSettled: () => {
      setUpdating(false);
    }
  });

  const getInsightData = (value: string, integrationId: string) => {
    if (!value || !integrationId) {
      return;
    }

    setUpdating(true);
    mutate({ value, integrationId });
  };

  return (
    <>
      <FormItem name="integrationId" model="claim">
        <Select
          options={insightIntegrationsForSelect}
          onChange={(value) => {
            getInsightData(form.getFieldValue('insightPolicyTransactionId'), value);
          }}
        />
      </FormItem>

      <FormItem name="insightPolicyTransactionId" model="claim">
        <Input
          onChange={(event) => {
            const { value } = event.target;

            clearTimeout(timeout);

            timeout = setTimeout(() => {
              getInsightData(value, form.getFieldValue('integrationId'));
            }, 500);
          }}
        />
      </FormItem>

      <FormItem name="insightLedger" hidden>
        <Input />
      </FormItem>

      <Modal open={isModalOpen} onOk={handleOk} closable={false} width={600} footer={null}>
        <div className="duplicate-claims-modal">
          <ExclamationCircleOutlined className="modal-warning" />

          <div>
            <h3>{t(`claim.duplicatesTitle.${duplicateClaims.length === 1 ? 'one' : 'other'}`)}</h3>
            <p>
              {t('claim.confirmDuplicate')}
              &nbsp;
              {t(`claim.checkDuplicates.${duplicateClaims.length === 1 ? 'one' : 'other'}`)}
            </p>
            <ul className="duplicate-claims-list">
              {duplicateClaims.map((duplicateClaim) => (
                <li key={duplicateClaim.id}>
                  <Link to={`/claims/${duplicateClaim.id}`} target="_blank">
                    {t('claim.id', { id: duplicateClaim.id })}
                  </Link>
                  <Typography.Text type="secondary" italic>
                    <small> created by </small>
                  </Typography.Text>
                  {duplicateClaim.createdBy.name}
                  <Typography.Text type="secondary" italic>
                    <small> on </small>
                  </Typography.Text>
                  <DateFormatter value={duplicateClaim.createdAt} format="minute" />
                </li>
              ))}
            </ul>
          </div>
        </div>

        <ModalFooter>
          <Space>
            <Button key="clear" onClick={handleCancel}>
              {t('globals.clearValues')}
            </Button>
            <Button key="return" onClick={() => handleDestroy()}>
              {t('claim.cancelAndReturn')}
            </Button>
            <Button key="close" type="primary" onClick={handleOk}>
              {t('claim.thisIsNew')}
            </Button>
          </Space>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default SearchInsight;
