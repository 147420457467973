import { forwardRef } from 'react';
import { GetProps } from 'antd';
import Icon from '@ant-design/icons';

import { PrioritySvg } from './svgs/Priority';
import { VulnerabilitySvg } from './svgs/Vulnerability';
import { AtSvg } from './svgs/At';
import { HandPointer } from './svgs/HandPointer';

type IconPropsType = GetProps<typeof Icon>;

// forwardRef needed to avoid warning: https://github.com/ant-design/ant-design/issues/41497#issuecomment-1486101923
export const PriorityIcon = forwardRef<HTMLSpanElement, IconPropsType>((props, ref) => (
  <Icon component={PrioritySvg} {...props} ref={ref} />
));
export const VulnerabilityIcon = forwardRef<HTMLSpanElement, IconPropsType>((props, ref) => (
  <Icon component={VulnerabilitySvg} {...props} ref={ref} />
));
export const AtIcon = forwardRef<HTMLSpanElement, IconPropsType>((props, ref) => (
  <Icon component={AtSvg} {...props} ref={ref} />
));
export const HandPointerIcon = forwardRef<HTMLSpanElement, IconPropsType>((props, ref) => (
  <Icon component={HandPointer} {...props} ref={ref} />
));

export default {};
