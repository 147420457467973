import { InputRef } from 'antd';
import { useEffect, useRef } from 'react';

export const useInputFocus = ({ focusCondition }: { focusCondition?: () => boolean } = {}) => {
  const inputRef = useRef<InputRef>(null);

  useEffect(() => {
    if (!focusCondition || focusCondition()) {
      setTimeout(() => {
        inputRef?.current?.focus();
      }, 1);
    }
  }, [focusCondition]);

  return {
    ref: inputRef
  };
};
