import React, { useContext, useState } from 'react';
import { Form } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { isUnprocessableEntity } from '@gowgates/api-client';
import { addErrorsFromAPIInForm, scrollToFirstError } from '@gowgates/utils';

import { sendClaimAction } from '../../../api/endpoints';
import { CreateStepsContext } from '../../../contexts/CreateStepsContext';
import useClaim from '../../../hooks/useClaim';
import { Claim } from '../../../types';
import Footer from './Footer';

type CreateClaimFormProps = {
  nestedModels?: (string | string[])[];
  children?: React.ReactNode | ((setUpdating: (u: boolean) => void) => React.ReactNode);
};
const CreateClaimForm = ({ nestedModels, children }: CreateClaimFormProps) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { currentTab, nextTab } = useContext(CreateStepsContext);
  const navigate = useNavigate();
  const [isUpdating, setUpdating] = useState(false);
  const { claim } = useClaim();

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: Partial<Claim>) => sendClaimAction(claim.id, currentTab.action, values),
    onError: (error) => {
      addErrorsFromAPIInForm({ error, form, extraNodes: nestedModels });

      if (isUnprocessableEntity(error)) {
        scrollToFirstError();
      }
    },
    onSuccess: () => {
      if (nextTab.path) {
        queryClient.invalidateQueries({ queryKey: ['claim', claim.id] });
        navigate(`../${nextTab.path}`);
      } else {
        window.location.href = `/claims/${claim.id}`;
      }
    }
  });

  return (
    <Form
      form={form}
      initialValues={claim}
      labelWrap
      onFinish={mutate}
      disabled={isLoading || isUpdating}
      layout="vertical"
    >
      {typeof children === 'function' ? children(setUpdating) : children}

      <Footer />
    </Form>
  );
};

export default CreateClaimForm;
