import { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { useFieldsQuery } from '@gowgates/dynamic-fields';
import { usePageTitle } from '@gowgates/core';
import { t } from '@gowgates/utils';

import { ReportStructure } from '../../../../types';
import { getDocumentStructures, getReportFields } from '../../../../api/endpoints';
import FullLoader from '../../../../components/FullLoader';
import ReportField from './Field';

const ReportStructureFields = () => {
  const structureId = Number(useParams().structureId);
  const reportId = Number(useParams().reportId);
  const queryClient = useQueryClient();
  const reportStructure = queryClient.getQueryData<ReportStructure>(['reportStructure', reportId]);
  usePageTitle(
    `${reportStructure.name} - ${t('activerecord.models.field.other')} | ${t(
      'activerecord.models.claimStructure.other'
    )}`
  );
  const [editing, setEditing] = useState<number>();

  const { isLoading, data } = useQuery({
    queryKey: ['reportStructure', reportId, 'fields'],
    queryFn: () => getReportFields(structureId, reportId)
  });

  const { isLoading: isLoadingFields, fields } = useFieldsQuery({
    fieldableId: structureId,
    fieldableType: 'claim_structure'
  });

  const { isLoading: isLoadingDocs, data: docs } = useQuery({
    queryKey: ['documentStructures', structureId],
    queryFn: () => getDocumentStructures(structureId)
  });

  if (isLoading || isLoadingFields || isLoadingDocs || !fields || !docs) {
    return <FullLoader />;
  }

  const startEditing = (fieldId?: number) => {
    setEditing(fieldId);
  };

  const endEditing = () => {
    setEditing(undefined);
  };

  return (
    <section className="report-fields">
      {data?.map((reportField) => (
        <ReportField
          key={reportField.pdfName}
          reportField={reportField}
          dynamicClaimFields={fields}
          documentStructures={docs}
          editing={editing}
          startEditing={startEditing}
          endEditing={endEditing}
        />
      ))}
    </section>
  );
};

export default ReportStructureFields;
