import { Section, SectionStructure } from '../../../../../types';
import Box from '../../../../../components/Box';
import SectionDetails from './SectionDetails';
import AddSection from './AddSection';

type CreateSectionProps = {
  section: Section;
  sectionStructure: SectionStructure;
};

const CreateSection = ({ section, sectionStructure }: CreateSectionProps) => (
  <Box title={sectionStructure.name}>
    {section ? (
      <SectionDetails section={section} sectionStructure={sectionStructure} />
    ) : (
      <AddSection sectionStructure={sectionStructure} />
    )}
  </Box>
);

export default CreateSection;
