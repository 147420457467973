import { useQuery } from '@tanstack/react-query';
import { Outlet, useParams } from 'react-router';

import { getClaimStructure } from '../../api/endpoints';
import FullLoader from '../../components/FullLoader';
import Page from '../../components/Page';
import PublishBtn from './components/PublishBtn';
import StructureNav from './components/StructureNav';
import StructureSummary from './components/StructureSummary';

const Structures = () => {
  const structureId = Number(useParams().structureId);

  const { isLoading, data } = useQuery({
    queryKey: ['claimStructure', structureId],
    queryFn: () => getClaimStructure(structureId)
  });

  if (isLoading) {
    return <FullLoader />;
  }

  return (
    <Page
      title={data.name}
      backUrl="/structures"
      actions={<PublishBtn claimStructure={data} />}
      summary={<StructureSummary claimStructure={data} />}
      nav={<StructureNav />}
    >
      <Outlet />
    </Page>
  );
};

export default Structures;
