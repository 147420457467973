import { Alert, ConfigProvider, theme } from 'antd';

import { t, tEnum } from '@gowgates/utils';

import { ClaimWithStructure } from '../../../../types';

type ClaimWithdrawnProps = {
  claim: ClaimWithStructure;
};

const ClaimWithdrawn = ({ claim }: ClaimWithdrawnProps) => {
  const { token } = theme.useToken();

  if (claim.liability !== 'withdrawn' || !claim.withdrawReason) {
    return null;
  }

  return (
    <ConfigProvider theme={{ token: { colorWarning: token.orange6 } }}>
      <Alert
        message={t('claim.withdrawnBecause', {
          reason: tEnum('claim.withdrawReasons', claim.withdrawReason)
        })}
        type="warning"
      />
    </ConfigProvider>
  );
};

export default ClaimWithdrawn;
