import { Form, Row, Col, Button, App, Input } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { useAuth, User, usePageTitle } from '@gowgates/core';

import { updateUser } from '../../api/endpoints';

const ProfileEmail = () => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData<User>(['users', 'me']);
  const { updateUserInStorage } = useAuth();
  const [form] = Form.useForm();
  usePageTitle(t('account.changeEmail'));

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: Partial<User>) => (user ? updateUser(user.id, values) : Promise.reject()),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      message.success({
        content: t('account.changeEmailSuccessMessage'),
        duration: 10
      });
      updateUserInStorage(data);
    }
  });

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={mutate}
      initialValues={user}
      disabled={isLoading || !user}
    >
      <Row>
        <Col span={16} offset={4}>
          <FormItem name="email" model="user" required>
            <Input />
          </FormItem>
        </Col>
      </Row>

      <footer className="d-flex justify-content-end">
        <Button type="primary" htmlType="submit">
          {t('globals.save')}
        </Button>
      </footer>
    </Form>
  );
};

export default ProfileEmail;
