import { Tag, Typography } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { frontendURL, t } from '@gowgates/utils';
import { User } from '@gowgates/core';

type UserConfirmedTagProps = {
  user: User;
};

const UserConfirmedTag = ({ user }: UserConfirmedTagProps) => {
  if (user.confirmed) {
    return (
      <Tag icon={<CheckCircleOutlined />} color="green">
        {t('user.confirmed')}
      </Tag>
    );
  }

  return (
    <Tag icon={<CloseCircleOutlined />} color="error">
      {user.confirmationToken ? (
        <Typography.Paragraph
          className="d-inline"
          copyable={{
            text: `${frontendURL}/users/confirmation?confirmation_token=${user.confirmationToken}`,
            tooltips: [t('user.copyConfirmationLink')]
          }}
        >
          {t('user.unconfirmed')}
        </Typography.Paragraph>
      ) : (
        t('user.unconfirmed')
      )}
    </Tag>
  );
};

export default UserConfirmedTag;
