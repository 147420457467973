import { useEffect, useState } from 'react';

import {
  DocumentStructure,
  DynamicClaimField,
  ReportField as ReportFieldType
} from '../../../../types';

import ShowReportField from './Show';
import EditReportField from './Edit';

type ReportFieldProps = {
  reportField: ReportFieldType;
  dynamicClaimFields: DynamicClaimField[];
  documentStructures: DocumentStructure[];
  editing?: number;
  startEditing: (id?: number) => void;
  endEditing: (id?: number) => void;
};

const ReportField = ({
  reportField,
  dynamicClaimFields,
  documentStructures,
  editing,
  startEditing,
  endEditing
}: ReportFieldProps) => {
  const [isEditing, setEditing] = useState(editing === reportField.id);

  useEffect(() => {
    setEditing(editing === reportField.id);
  }, [editing]);

  if (isEditing) {
    return (
      <EditReportField
        reportField={reportField}
        endEditing={endEditing}
        dynamicClaimFields={dynamicClaimFields}
        documentStructures={documentStructures}
      />
    );
  }

  return <ShowReportField reportField={reportField} startEditing={startEditing} />;
};

export default ReportField;
