import { Skeleton, Typography, Space, Timeline, Collapse, TimelineItemProps } from 'antd';
import { CheckCircleFilled, CloseCircleFilled, StopFilled } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';
import { t } from '@gowgates/utils';
import { ConditionExpressionDescription } from '@gowgates/dynamic-fields';
import { getTaskHistory } from '../../../../../api/endpoints';
import { Task } from '../../../../../types';
import TwoLineDate from '../../../../../components/TwoLineDate';
import { taskCompletedBy } from '../../../utils/claim';
import CreationDetails from './CreationDetails';
import CompletionDetails from './CompletionDetails';
import ActionDescription from './ActionDescription';

const defaultDot = (
  <Typography.Text type="success">
    <CheckCircleFilled />
  </Typography.Text>
);

const notPerformedDot = (
  <Typography.Text type="danger">
    <StopFilled />
  </Typography.Text>
);

const notRequiredDot = (
  <Typography.Text type="secondary">
    <StopFilled />
  </Typography.Text>
);

const cancelledDot = (
  <Typography.Text type="danger">
    <CloseCircleFilled />
  </Typography.Text>
);

const ByUser = ({ userName }: { userName?: string }) => (
  <Typography.Text type="secondary">
    <small>by {userName || <em>{t('globals.system')}</em>}</small>
  </Typography.Text>
);

export const ShowTaskModalTimeline = ({ task }: { task: Task }) => {
  const { isLoading, data } = useQuery({
    queryKey: ['task', task.id, 'history'],
    queryFn: () => getTaskHistory(task.id),
    staleTime: 120 * 1000, // refetch every 2 minutes
    placeholderData: []
  });

  if (isLoading) {
    return (
      <Space direction="vertical" className="w-100">
        <Skeleton.Input active block />
        <Skeleton.Input active block />
        <Skeleton.Input active block />
      </Space>
    );
  }

  const items: TimelineItemProps[] = [
    {
      label: <TwoLineDate date={task.createdAt} />,
      dot: defaultDot,
      children: (
        <>
          <strong>{t('task.created')}</strong> <br />
          {task.creationDetails.event && (
            <>
              <CreationDetails task={task} /> <br />
            </>
          )}
          <ByUser userName={task.createdBy?.name} />
        </>
      )
    }
  ];

  task.automaticRules.forEach((automaticRule) => {
    items.push({
      label: <TwoLineDate date={task.createdAt} />,
      dot: notPerformedDot,
      children: (
        <>
          <strong>{t('task.failedAutomatic')}</strong> <br />
          <span>
            {t('task.actionsNotPerformed')}:
            <ul>
              {automaticRule.actions.map((action, index) => (
                <li key={index}>
                  <ActionDescription action={action} entity={task.itemId ? 'item' : 'claim'} />
                </li>
              ))}
            </ul>
          </span>
          <Collapse
            size="small"
            ghost
            items={[
              {
                key: '1',
                label: <Typography.Link>{t('task.failureDetails')}</Typography.Link>,
                children: (
                  <ConditionExpressionDescription
                    model="claim"
                    conditionExpression={automaticRule.expression}
                  />
                )
              }
            ]}
          />
        </>
      )
    });
  });

  data?.forEach((entry) => {
    items.push({
      label: <TwoLineDate date={entry.createdAt} />,
      dot: defaultDot,
      children: (
        <p>
          <strong>{entry.name}</strong> <br />
          <ByUser userName={entry.user?.name} />
        </p>
      )
    });
  });

  if (task.status === 'completed' || task.status === 'aborted') {
    items.push({
      label: <TwoLineDate date={task.completedAt} />,
      dot: task.status === 'aborted' ? notRequiredDot : defaultDot,
      children: (
        <>
          <strong>Task {t(`task.statuses.${task.status}`)}</strong> <br />
          {task.completionDetails.event && (
            <>
              <CompletionDetails task={task} /> <br />
            </>
          )}
          <ByUser userName={taskCompletedBy(task)} />
        </>
      )
    });

    task.completionDetails.actions?.forEach((action) =>
      items.push({
        color: 'gray',
        children: <ActionDescription action={action} entity={task.itemId ? 'item' : 'claim'} />
      })
    );
  }

  if (task.status === 'cancelled') {
    items.push({
      label: <TwoLineDate date={task.completedAt} />,
      dot: cancelledDot,
      children: (
        <>
          <strong>{t('task.manuallyCancelled')}</strong> <br />
          <ByUser userName={taskCompletedBy(task)} />
        </>
      )
    });
  }

  return <Timeline mode="left" items={items} className="task-timeline" />;
};
