import { GetProps, Tooltip } from 'antd';

import useStructures from '../hooks/useStructures';
import { Claim } from '../types';

type TooltipPropsType = GetProps<typeof Tooltip>;

type StructureNameProps = {
  claim: Claim;
  TooltipComponent?: React.FC<TooltipPropsType>;
};

const StructureName = ({
  claim,
  TooltipComponent = ({ children }) => children
}: StructureNameProps) => {
  const { findStructure } = useStructures();

  return (
    <span>
      <TooltipComponent title={findStructure(claim.structureId)?.name || ''}>
        {findStructure(claim.structureId)?.name}
      </TooltipComponent>
    </span>
  );
};

export default StructureName;
