import { t } from '@gowgates/utils';

import useClaim from '../../../../hooks/useClaim';
import InnerBox from '../../../../components/InnerBox';
import { backendURL } from '../../../../utils/tenants';

const DocumentReports = () => {
  const { claim } = useClaim();
  const { structure } = claim;

  if (!structure.reports || structure.reports?.length === 0) {
    return null;
  }

  return (
    <InnerBox title={t('activerecord.models.report.other')} noLine>
      {structure.reports.map((report) => (
        <p>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`${backendURL}/claims/${claim.id}/reports/${report.slug}`}
            key={report.slug}
          >
            {report.name}
          </a>
        </p>
      ))}
    </InnerBox>
  );
};

export default DocumentReports;
