import { ColorPicker, Image } from 'antd';
import { formatBoolean, t } from '@gowgates/utils';
import { Tenant } from '@gowgates/claims-gateway-shared';

export const TenantDescriptions = ({ tenant }: { tenant?: Tenant }) => (
  <dl className="cg-descriptions">
    <dt>{t('activerecord.attributes.tenant.name')}</dt>
    <dd>{tenant?.name || '-'}</dd>

    <dt>{t('activerecord.attributes.tenant.primaryColor')}</dt>
    <dd>{tenant?.primaryColor ? <ColorPicker disabled value={tenant.primaryColor} /> : '-'}</dd>

    <dt>{t('activerecord.attributes.tenant.bgColor')}</dt>
    <dd>{tenant?.bgColor ? <ColorPicker disabled value={tenant.bgColor} /> : '-'}</dd>

    <dt>{t('activerecord.attributes.tenant.replyEmail')}</dt>
    <dd>
      {tenant?.replayEmail ? (
        <a href={`mailto:${tenant.replayEmail}`}>{tenant.replayEmail}</a>
      ) : (
        '-'
      )}
    </dd>

    <dt>{t('activerecord.attributes.tenant.forceOtp')}</dt>
    <dd>{formatBoolean(tenant?.forceOtp)}</dd>

    <dt>{t('activerecord.attributes.tenant.logoSmall')}</dt>
    <dd>{tenant?.logoSmall ? <Image preview={false} src={tenant.logoSmall?.url} /> : '-'}</dd>

    <dt>{t('activerecord.attributes.tenant.logo')}</dt>
    <dd>{tenant?.logo ? <Image preview={false} src={tenant.logo?.url} /> : '-'}</dd>
  </dl>
);
