import { DynamicDescriptions } from '@gowgates/dynamic-fields';

import { Section, SectionStructure } from '../../../types';

type SectionDescriptionProps = {
  sectionStructure: SectionStructure;
  section: Section;
};
const SectionDescription = ({ sectionStructure, section }: SectionDescriptionProps) => (
  <DynamicDescriptions model={section} fields={sectionStructure.fields} />
);

export default SectionDescription;
