import { useCallback } from 'react';
import { To, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Form, Modal } from 'antd';

import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { useGoBack } from '@gowgates/core';

import { Webhook } from '../../../types';
import { updateWebhook } from '../../../api/endpoints';
import { WebhookFormFields } from '../../../components/WebhookFormFields';
import { MODAL_WIDTH, EDIT_WEBHOOK_FORM_ID } from './configs';

export const EditWebhookModal = () => {
  const { message } = App.useApp();
  const webhookId = useParams<{ webhookId: string }>().webhookId;
  const queryClient = useQueryClient();
  const [form] = Form.useForm<Webhook>();
  const { goBack, navigate } = useGoBack();

  const closeModal = useCallback(
    (to?: To) => {
      form.resetFields();
      to ? navigate(to) : goBack({ fallback: window.location.pathname });
    },
    [form, navigate, goBack]
  );

  const webhooks = queryClient.getQueryData<Webhook[]>(['webhooks']);
  const webhook = webhooks?.find((w) => String(w.id) === webhookId);

  const { isPending, mutate } = useMutation({
    mutationFn: (values: Partial<Webhook>) => updateWebhook(webhookId, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: () => {
      message.success(t('webhook.saved'));
      queryClient.invalidateQueries({ queryKey: ['webhooks'] });

      closeModal();
    }
  });

  return (
    <Modal
      width={MODAL_WIDTH}
      title={webhook?.name}
      onCancel={() => closeModal()}
      okButtonProps={{
        loading: isPending,
        htmlType: 'submit',
        form: EDIT_WEBHOOK_FORM_ID
      }}
      open
      okText={t('globals.save')}
      cancelText={t('globals.cancel')}
    >
      <Form
        id={EDIT_WEBHOOK_FORM_ID}
        onFinish={mutate}
        layout="vertical"
        form={form}
        disabled={isPending}
        initialValues={webhook}
      >
        <WebhookFormFields />
      </Form>
    </Modal>
  );
};
