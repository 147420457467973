import { snakeCase } from 'change-case';

import { Field, useAppConfigs } from '@gowgates/dynamic-fields';
import { ColumnConfigTypes, TenantColumnConfig } from '@gowgates/claims-gateway-shared';

import { AppConfigs } from '../types/app-configs';
import useFileConfig from './useFileConfig';
import useStructures from './useStructures';

const useCustomColumns = ({ table }: { table: ColumnConfigTypes }) => {
  const { claimantStructure, clientStructure, coverStructure } = useFileConfig();
  const { appConfigs } = useAppConfigs();
  const { findStructure } = useStructures();
  const columnConfigs = appConfigs.tables[table] as TenantColumnConfig[];

  const findField = (columnConfig: TenantColumnConfig): Field | undefined => {
    if (
      columnConfig.dataType === 'fixed' &&
      (columnConfig.model === 'claim' || columnConfig.model === 'task')
    ) {
      // return (appConfigs as AppConfigs)[columnConfig.model]?.staticFields.find(
      return (appConfigs as AppConfigs)[columnConfig.model]?.staticFields.find(
        (field) => field.name === snakeCase(columnConfig.value as string)
      );
    }

    if (columnConfig.dataType === 'dynamic' && columnConfig.model === 'claim') {
      return findStructure(columnConfig.structureId)?.data.claim.find(
        (field) => field.name === columnConfig.value
      );
    }

    if (columnConfig.dataType === 'dynamic' && columnConfig.model === 'claimant') {
      return claimantStructure?.find((field) => field.name === columnConfig.value);
    }

    if (columnConfig.dataType === 'dynamic' && columnConfig.model === 'client') {
      return clientStructure?.find((field) => field.name === columnConfig.value);
    }

    if (columnConfig.dataType === 'dynamic' && columnConfig.model === 'cover') {
      return coverStructure?.find((field) => field.name === columnConfig.value);
    }

    return undefined;
  };

  return {
    findField,
    columnConfigs
  };
};

export default useCustomColumns;
