import { Alert } from 'antd';

import { t } from '@gowgates/utils';

import { PriorityIcon } from '../../../../icons';
import { isImportant } from '../../utils/claim';
import { ClaimWithStructure } from '../../../../types';

type ClaimImportanceProps = {
  claim: ClaimWithStructure;
};

const ClaimImportance = ({ claim }: ClaimImportanceProps) => {
  if (!isImportant(claim)) {
    return null;
  }

  return (
    <Alert
      message={t(`claim.isImportant.${claim.importance}`)}
      type="info"
      showIcon
      icon={<PriorityIcon />}
    />
  );
};

export default ClaimImportance;
