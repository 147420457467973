import { App, Modal, Typography } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { t } from '@gowgates/utils';
import { User } from '@gowgates/core';

import { blockUser, unblockUser, resetUserPassword, disableOtp } from '../../../api/endpoints';
import PageContextMenu from '../../../components/PageContextMenu';

type UserContextMenuProps = {
  user: User;
};
const UserContextMenu = ({ user }: UserContextMenuProps) => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();

  const onSuccess = (data: User, text: string) => {
    queryClient.setQueryData(['user', user.id], data);
    message.success(text);
  };

  const blockUserMutation = useMutation({
    mutationFn: () => blockUser(user.id),
    onSuccess: (data) => onSuccess(data, t('user.blockSuccess'))
  });

  const unblockUserMutation = useMutation({
    mutationFn: () => unblockUser(user.id),
    onSuccess: (data) => onSuccess(data, t('user.unblockSuccess'))
  });

  const resetPasswordMutation = useMutation({
    mutationFn: () => resetUserPassword(user.id),
    onSuccess: (data) => {
      Modal.info({
        title: t('user.resetPasswordConfirmation'),
        content: (
          <div>
            <p>{t('user.resetPasswordCopy')}</p>
            <Typography.Paragraph code copyable>
              {data}
            </Typography.Paragraph>
          </div>
        )
      });
    }
  });

  const disableOtpMutation = useMutation({
    mutationFn: () => disableOtp(user.id),
    onSuccess: (data) => onSuccess(data, t('user.otp.disableConfirmation'))
  });

  const confirmResendPassword = () => {
    Modal.confirm({
      title: t('user.resetPassword'),
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          <p>{t('user.resetPasswordConfirmOne')}</p>
          <p>{t('user.resetPasswordConfirmTwo')}</p>
        </>
      ),
      okText: t('globals.yesNo.true'),
      cancelText: t('globals.yesNo.false'),
      onOk: () => resetPasswordMutation.mutate()
    });
  };

  const confirmDisableOtp = () => {
    Modal.confirm({
      title: t('user.otp.disable'),
      icon: <ExclamationCircleOutlined />,
      content: t('user.otp.areYouSure'),
      okText: t('globals.yesNo.true'),
      cancelText: t('globals.yesNo.false'),
      onOk: () => disableOtpMutation.mutate()
    });
  };

  const confirmBlock = () => {
    Modal.confirm({
      title: t('user.block'),
      icon: <ExclamationCircleOutlined />,
      content: t('user.blockConfirmation'),
      okText: t('globals.yesNo.true'),
      cancelText: t('globals.yesNo.false'),
      onOk: () => blockUserMutation.mutate()
    });
  };

  const confirmUnblock = () => {
    Modal.confirm({
      title: t('user.unblock'),
      icon: <ExclamationCircleOutlined />,
      content: t('user.unblockConfirmation'),
      okText: t('globals.yesNo.true'),
      cancelText: t('globals.yesNo.false'),
      onOk: () => unblockUserMutation.mutate()
    });
  };

  const items = [];

  if (user.permissions.resetPassword) {
    items.push({
      key: 'reset-password',
      label: t('user.resetPassword'),
      onClick: confirmResendPassword
    });
  }

  if (user.permissions.disableOtp) {
    items.push({
      key: 'disable-otp',
      label: t('user.otp.disable'),
      onClick: confirmDisableOtp
    });
  }

  if (user.permissions.block) {
    items.push({ key: 'block', label: t('user.block'), onClick: confirmBlock });
  }

  if (user.permissions.unblock) {
    items.push({ key: 'unblock', label: t('user.unblock'), onClick: confirmUnblock });
  }

  if (items.length === 0) {
    return null;
  }

  return <PageContextMenu items={items} />;
};

export default UserContextMenu;
