import { Loader } from '@gowgates/core';
// import Page from './Page';

// TODO: Page cannot be here because it gets stuff from the tenant
const FullLoader = () => (
  // <Page>
  <Loader />
  // </Page>
);

export default FullLoader;
