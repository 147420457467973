import { Input, Switch } from 'antd';

import { FormItem } from '@gowgates/dynamic-fields';
import { useInputFocus } from '@gowgates/core';

export const ApiKeyFormFields = () => {
  const { ref } = useInputFocus();

  return (
    <>
      <FormItem name="enabled" model="apiKey">
        <Switch />
      </FormItem>
      <FormItem name="name" model="apiKey">
        <Input ref={ref} />
      </FormItem>
    </>
  );
};
