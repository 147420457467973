import { App, Button, Flex, Table } from 'antd';
import { Outlet, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery } from '@tanstack/react-query';

import { useTable, UseTableProps } from '@gowgates/core';
import { ExtendedDangerPopconfirm, useAppConfigs } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';

import { Webhook } from '../../../types/webhook';
import { deleteWebhook, getWebhooks } from '../../../api/endpoints';
import { AppConfigs } from '../../../types/app-configs';

export const Webhooks = () => {
  const { message } = App.useApp();
  const searchString = useLocation().search;
  const { appConfigs } = useAppConfigs();

  const onSuccess = () => {
    message.success(t('webhook.deleteSuccess'));
  };

  const onError = () => {
    message.error(t('globals.unexpectedError'));
  };

  const tableSchema: UseTableProps<Webhook> = {
    model: 'webhook',
    rowUrlPattern: `:id${searchString}`,
    frontendSort: true,
    columns: [
      {
        dataIndex: 'name',
        width: '35%',
        sorter: (first, second) => (first.name.toLowerCase() < second.name.toLowerCase() ? -1 : 1)
      },
      {
        dataIndex: 'actions',
        render: (_value, webhook) =>
          webhook.actions.map((action) => t(`taskTrigger.actions.${action}`)).join(', '),
        width: '55%'
      },
      {
        dataIndex: 'enabled',
        render: (_value, webhook) => t(`globals.yesNo.${webhook.enabled}`),
        width: '12.5%',
        filters: (appConfigs as AppConfigs)?.global.boolean,
        onFilter: (value, record) => record.enabled === value
      },
      {
        render: (_value, webhook) => (
          <Flex justify="center" align="center">
            <ExtendedDangerPopconfirm
              deleteFn={() => deleteWebhook(webhook.id)}
              invalidateQueries={['webhooks']}
              permission
              onSuccess={onSuccess}
              onError={onError}
            />
          </Flex>
        ),
        width: '7.5%'
      }
    ]
  };

  const { handleTableChange, columns } = useTable<Webhook>(tableSchema);

  const { isFetching, data: webhooks } = useQuery({
    queryKey: ['webhooks'],
    queryFn: () => getWebhooks()
  });

  return (
    <Flex vertical gap={16}>
      <Flex justify="flex-end">
        <Link to={`new${searchString}`}>
          <Button icon={<PlusOutlined />} type="primary">
            {t('webhook.new')}
          </Button>
        </Link>
      </Flex>
      <Table
        rowKey={(record) => String(record.id)}
        columns={columns}
        dataSource={webhooks}
        onChange={handleTableChange}
        loading={isFetching}
        pagination={false}
      />
      <Outlet />
    </Flex>
  );
};
