import { useState } from 'react';
import { Table, Button } from 'antd';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';

import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';

import { Journey, TaskStructure } from '../../../types';
import { getTaskStructures } from '../../../api/endpoints';
import NewTaskStructureModal from './NewTaskStructureModal';
import DeleteTaskStructure from './DeleteTaskStructure';

const { Column } = Table;

type JourneyTasksProps = {
  entity: string;
};

const JourneyTasks = ({ entity }: JourneyTasksProps) => {
  const journeyId = Number(useParams().journeyId);
  const queryClient = useQueryClient();
  const journey = queryClient.getQueryData<Journey>(['journey', journeyId]);
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  usePageTitle(
    `${journey?.name} - ${t('activerecord.models.task.other')} | ${t(
      'activerecord.models.journey.other'
    )}`
  );

  const { isFetching, data } = useQuery({
    queryKey: ['taskStructures', journeyId],
    queryFn: () => getTaskStructures(journeyId),
    placeholderData: []
  });

  const taskStructures = data?.filter((taskStructure) => taskStructure.entity === entity);

  const showModal = () => {
    setModalOpen(true);
  };

  return (
    <section>
      <header className="box-header d-flex justify-content-end">
        <Button type="primary" onClick={showModal}>
          {t('taskStructure.new')}
        </Button>
      </header>

      <Table
        dataSource={taskStructures}
        rowKey={(record) => record.id}
        pagination={false}
        className="box-table"
        loading={isFetching}
      >
        <Column
          title={t('activerecord.attributes.taskStructure.name')}
          dataIndex="name"
          sorter
          render={(text, record) => (
            <Link to={`${record.id}`} className="col-clickable">
              {text}
            </Link>
          )}
        />
        <Column<TaskStructure>
          render={(text, record) => (
            <DeleteTaskStructure journeyId={journeyId} taskStructure={record} />
          )}
        />
      </Table>

      <NewTaskStructureModal isOpen={isModalOpen} setOpen={setModalOpen} entity={entity} />
    </section>
  );
};

export default JourneyTasks;
