import { Table } from 'antd';
import { camelCase } from 'change-case';

import { t } from '@gowgates/utils';
import { tEnum } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';

import { MoneyFormatter } from '@gowgates/dynamic-fields';
import useClaim from '../../../../hooks/useClaim';
import InnerBox from '../../../../components/InnerBox';
import useAppConfigs from '../../../../hooks/useAppConfigs';
import { AppConfigs } from '../../../../types/app-configs';

const ClaimFinance = () => {
  const { claim } = useClaim();
  usePageTitle(`${t('claim.id', { id: claim.id })} - ${t('claim.finance')}`);
  const { appConfigs } = useAppConfigs();

  if (!claim.permissions.showFinance) {
    return null;
  }

  const financeData = (appConfigs as AppConfigs).item.categories.map((category) => {
    const camelizedKey = camelCase(category.value);

    return {
      title: tEnum('item.categories', category.value),
      expandable: true,
      key: category.value,
      paid: (claim.finance.paid as any)[camelizedKey].total,
      reserve: (claim.finance.reserve as any)[camelizedKey],
      outstanding: (claim.finance.paid as any)[camelizedKey].outstanding
    };
  });

  const excessData: any[] = [];

  claim.structure.sections.forEach((sectionStructure) => {
    const section = claim.sections[camelCase(sectionStructure.slug)];
    if (section) {
      excessData.push({
        key: sectionStructure.slug,
        title: sectionStructure.name,
        excess: section.excess,
        excessOutstanding: section.excessOutstanding
      });
    }
  });

  const renderFinanceAmount = (text: string | number) => (
    <MoneyFormatter value={text} negativeType="danger" />
  );

  const excessColumns = [
    { dataIndex: 'title' },
    {
      title: t('claim.deductibleApplied'),
      dataIndex: 'excess',
      width: 255,
      render: renderFinanceAmount
    },
    {
      title: t('claim.deductibleOutstanding'),
      dataIndex: 'excessOutstanding',
      width: 255,
      render: renderFinanceAmount
    }
  ];

  const financeColumns = [
    { dataIndex: 'title' },
    { title: t('claim.insurerPaid'), dataIndex: 'paid', width: 170, render: renderFinanceAmount },
    {
      title: t('claim.insurerReserve'),
      dataIndex: 'reserve',
      width: 170,
      render: renderFinanceAmount
    },
    {
      title: t('globals.outstanding'),
      dataIndex: 'outstanding',
      width: 170,
      render: renderFinanceAmount
    }
  ];

  const itemTypeColumns = [
    { dataIndex: 'title' },
    { dataIndex: 'paid', width: 491, render: renderFinanceAmount }
  ];

  const excessSummary = () => (
    <Table.Summary.Row className="row-gold row-strong">
      <Table.Summary.Cell index={0}>{t('globals.totals')}</Table.Summary.Cell>
      <Table.Summary.Cell index={1}>
        <MoneyFormatter value={claim.finance.excess} />
      </Table.Summary.Cell>
      <Table.Summary.Cell index={2}>
        <MoneyFormatter value={claim.finance.excessOutstanding} />
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );

  const financeSummary = () => (
    <Table.Summary.Row className="row-gold row-strong">
      <Table.Summary.Cell index={0} />
      <Table.Summary.Cell index={1}>{t('globals.totals')}</Table.Summary.Cell>
      <Table.Summary.Cell index={2}>
        <MoneyFormatter value={claim.finance.paid.total} />
      </Table.Summary.Cell>
      <Table.Summary.Cell index={3}>
        <MoneyFormatter value={claim.finance.reserve.total} />
      </Table.Summary.Cell>
      <Table.Summary.Cell index={4}>
        <MoneyFormatter value={claim.finance.paid.outstanding} />
      </Table.Summary.Cell>
    </Table.Summary.Row>
  );

  const expandedRowRender = (categoryRecord: { key: string }) => {
    const categoryFinanceData: any[] = [];

    claim.structure.sections.forEach((sectionStructure) => {
      if (claim.sections[camelCase(sectionStructure.slug)]) {
        categoryFinanceData.push({
          key: sectionStructure.slug,
          title: sectionStructure.name,
          paid: (claim.finance.paid as any)[camelCase(categoryRecord.key)][
            camelCase(sectionStructure.slug)
          ]
        });
      }
    });

    return (
      <Table
        dataSource={categoryFinanceData}
        columns={itemTypeColumns}
        rowKey={(record) => record.key}
        pagination={false}
        showHeader={false}
        rowClassName="row-italic"
      />
    );
  };

  return (
    <>
      <InnerBox title="Policy limits">
        <Table
          dataSource={excessData}
          columns={excessColumns}
          rowKey={(record) => record.key}
          pagination={false}
          className="box-table"
          summary={excessSummary}
        />
      </InnerBox>

      <InnerBox title="Paid expenses">
        <Table
          dataSource={financeData}
          columns={financeColumns}
          rowKey={(record) => record.key}
          pagination={false}
          className="box-table"
          expandable={{ expandedRowRender, rowExpandable: () => true }}
          summary={financeSummary}
        />
      </InnerBox>
    </>
  );
};

export default ClaimFinance;
