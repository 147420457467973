import { Tag } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';

import { t } from '@gowgates/utils';

type UserLoginLockedTagProps = {
  locked: boolean;
};

const UserLoginLockedTag = ({ locked }: UserLoginLockedTagProps) => {
  if (locked === false) {
    return null;
  }

  return (
    <Tag icon={<CloseCircleOutlined />} color="error">
      {t('user.locked')}
    </Tag>
  );
};

export default UserLoginLockedTag;
