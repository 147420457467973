import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { t, tEnum } from '@gowgates/utils';
import { ConditionExpressionDescription } from '@gowgates/dynamic-fields';

import ButtonActionDescriptions from '../ButtonAction/Descriptions';
import { TaskStructure, TaskTrigger } from '../../../types';

type TaskTriggerDescriptionsProps = {
  taskTrigger: TaskTrigger;
  taskStructure: TaskStructure;
};

const TaskTriggerDescriptions = ({ taskTrigger, taskStructure }: TaskTriggerDescriptionsProps) => {
  const journeyId = Number(useParams().journeyId);
  const queryClient = useQueryClient();
  const taskStructures = queryClient.getQueryData<TaskStructure[]>(['taskStructures', journeyId]);

  const findTaskStructure = (id?: number) =>
    taskStructures?.find((taskStructure) => taskStructure.id === id)?.name;

  return (
    <dl className="cg-descriptions">
      <dt>{t('activerecord.attributes.taskTrigger.type')}</dt>
      <dd>{t(`taskTrigger.types.${taskTrigger.type}`)}</dd>

      {taskTrigger.type === 'system' && (
        <>
          <dt>{t('activerecord.attributes.taskTrigger.action')}</dt>
          <dd>{t(`taskTrigger.actions.${taskTrigger.action}`)}</dd>
        </>
      )}

      {['closed_task', 'not_completed_in_time'].includes(taskTrigger.type) && (
        <>
          <dt>{t('activerecord.attributes.taskTrigger.relatedTaskStructureId')}</dt>
          <dd>{findTaskStructure(taskTrigger.relatedTaskStructureId)}</dd>
        </>
      )}

      {taskTrigger.type === 'not_completed_in_time' && (
        <>
          <dt>{t('activerecord.attributes.taskTrigger.days')}</dt>
          <dd>{taskTrigger.days}</dd>
        </>
      )}

      {taskTrigger.event === 'completion' &&
        taskTrigger.type !== 'automatic' &&
        taskTrigger.type !== 'frontoffice' && (
          <>
            <dt>{t('activerecord.attributes.taskTrigger.requiresComment')}</dt>
            <dd>{t(`globals.yesNo.${taskTrigger.requiresComment}`)}</dd>
          </>
        )}

      {taskTrigger.conditional && (
        <>
          <dt>{t('taskTrigger.onlyIf')}</dt>
          <dd>
            <ConditionExpressionDescription
              model={taskTrigger.conditionModel}
              conditionExpression={taskTrigger.conditionExpression}
            />
          </dd>
        </>
      )}

      {taskTrigger.type === 'button' && (
        <>
          <dt>{t('activerecord.attributes.taskTrigger.buttonLabel')}</dt>
          <dd>{taskTrigger.buttonLabel}</dd>

          <dt>{t('activerecord.attributes.taskTrigger.buttonColor')}</dt>
          <dd>{tEnum('taskTrigger.buttonColors', taskTrigger.buttonColor)}</dd>

          <ButtonActionDescriptions taskTrigger={taskTrigger} taskStructure={taskStructure} />
        </>
      )}

      {taskTrigger.type === 'automatic' && (
        <ButtonActionDescriptions
          taskTrigger={taskTrigger}
          taskStructure={taskStructure}
          title={t('taskTrigger.automaticActions')}
        />
      )}

      {taskTrigger.type === 'frontoffice' && (
        <ButtonActionDescriptions
          taskTrigger={taskTrigger}
          taskStructure={taskStructure}
          title={t('taskTrigger.frontofficeFields')}
        />
      )}
    </dl>
  );
};

export default TaskTriggerDescriptions;
