import { t, useCurrencies } from '@gowgates/utils';

import useClaim from '../../../hooks/useClaim';

const BankDetailsDescriptions = () => {
  const { claim } = useClaim();
  const { getLabelForCurrencyKey } = useCurrencies();

  return (
    <dl className="cg-descriptions">
      <dt>{t('activerecord.attributes.claim.bankAccountType')}</dt>
      <dd>{claim.bankAccountType ? t(`claim.bankAccountTypes.${claim.bankAccountType}`) : '-'}</dd>

      <dt>{t('activerecord.attributes.claim.bankName')}</dt>
      <dd>{claim.bankName || '-'}</dd>

      <dt>{t('activerecord.attributes.claim.bankAccountHolderName')}</dt>
      <dd>{claim.bankAccountHolderName || '-'}</dd>

      {claim.bankAccountType === 'australia' && (
        <>
          <dt>{t('activerecord.attributes.claim.bsb')}</dt>
          <dd>{claim.bsb || '-'}</dd>

          <dt>{t('activerecord.attributes.claim.accountNumber')}</dt>
          <dd>{claim.accountNumber || '-'}</dd>
        </>
      )}

      {claim.bankAccountType === 'overseas' && (
        <>
          <dt>{t('activerecord.attributes.claim.swift')}</dt>
          <dd>{claim.swift || '-'}</dd>

          <dt>{t('activerecord.attributes.claim.iban')}</dt>
          <dd>{claim.iban || '-'}</dd>

          <dt>{t('activerecord.attributes.claim.currency')}</dt>
          <dd>{getLabelForCurrencyKey(claim.currency, { showFullNameInLabel: true })}</dd>
        </>
      )}
    </dl>
  );
};

export default BankDetailsDescriptions;
