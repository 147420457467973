import { useEffect, useRef } from 'react';
import { Form, Modal, Input, InputRef } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { Role } from '@gowgates/core';

import { createRole } from '../../api/endpoints';
import ModalFormFooter from '../../components/ModalFormFooter';

type NewRoleModalProps = {
  isOpen?: boolean;
  setOpen: (open: boolean) => void;
};

const NewRoleModal = ({ isOpen = false, setOpen }: NewRoleModalProps) => {
  const [form] = Form.useForm();
  const inputRef = useRef<InputRef>(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen) {
      inputRef?.current?.focus();
    }
  }, [isOpen]);

  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: Role) => createRole(values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({ queryKey: ['roles'] });
    }
  });

  return (
    <Modal title={t('role.new')} open={isOpen} onCancel={closeModal} footer={null}>
      <Form form={form} layout="vertical" onFinish={mutate} disabled={isLoading}>
        <FormItem name="name" model="role">
          <Input ref={inputRef} />
        </FormItem>

        <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
      </Form>
    </Modal>
  );
};

export default NewRoleModal;
