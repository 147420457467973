import { useEffect, useState } from 'react';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { DEFAULT_CLAIMS_PATH } from '@gowgates/utils';

const GlobalSearch = () => {
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [focused, setFocused] = useState(false);
  const [searchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/claims') {
      setSearch(searchParams.get('q') || '');
    } else {
      setSearch('');
    }
  }, [location.pathname]);

  useEffect(() => {
    if (search) {
      setFocused(true);
    } else {
      setFocused(false);
    }
  }, [search]);

  return (
    <Input
      prefix={<SearchOutlined />}
      onChange={(event) => {
        const { value } = event.target;

        setSearch(value);

        if (!value) {
          navigate(DEFAULT_CLAIMS_PATH);
        }
      }}
      onPressEnter={(event) => {
        const { value } = event.target as HTMLInputElement;

        navigate(`/claims?q=${value}`);
      }}
      value={search}
      allowClear
      className={`global-search ${focused ? 'open' : 'closed'}`}
    />
  );
};

export default GlobalSearch;
