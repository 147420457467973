import { forwardRef } from 'react';
import { Form, Input, InputRef, Select } from 'antd';

import { ClearableSelect, FormItem } from '@gowgates/dynamic-fields';
import { t, tEnum } from '@gowgates/utils';
import { useResourceRolesQuery } from '@gowgates/core';

const claimTabs = ['claim', 'client', 'documents', 'items', 'finance', 'notes', 'tasks'];

const TaskStructureForm = forwardRef<InputRef>((_props, ref) => {
  const tabs = claimTabs.map((value) => ({ value, label: tEnum('claim.tabs', value) }));
  const form = Form.useFormInstance();
  const { roles } = useResourceRolesQuery();

  const isClaimEntity = form.getFieldValue('entity') === 'claim';

  return (
    <>
      <FormItem name="entity" model="taskStructure" hidden>
        <Input />
      </FormItem>

      <FormItem name="name" model="taskStructure" required>
        <Input ref={ref} />
      </FormItem>

      <FormItem name="dueDays" model="taskStructure" required>
        <Input />
      </FormItem>

      <FormItem name="roleId" model="taskStructure">
        <ClearableSelect
          name="roleId"
          options={roles}
          fieldNames={{ value: 'id', label: 'name' }}
          placeholder={t('role.any')}
        />
      </FormItem>

      {isClaimEntity && (
        <FormItem name="claimTab" model="taskStructure">
          <Select options={tabs} />
        </FormItem>
      )}
    </>
  );
});

export default TaskStructureForm;
