import { useNavigate } from 'react-router-dom';
import { Form } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { usePageTitle, User as UserType } from '@gowgates/core';
import { createUser, newUser } from '../../../api/endpoints';
import UserForm from '../components/Form';
import Box from '../../../components/Box';
import Page from '../../../components/Page';
import FullLoader from '../../../components/FullLoader';

const User = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  usePageTitle(t('user.new'));

  const { isLoading: userLoaded, data: user } = useQuery({
    queryKey: ['user', 'new'],
    queryFn: newUser
  });

  const { isPending, mutate } = useMutation({
    mutationFn: (values: Partial<UserType>) => createUser(values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['resourceUsers'] });
      navigate(`/users/${data.id}`);
    }
  });

  if (userLoaded) {
    return <FullLoader />;
  }

  return (
    <Page title={t('user.new')} backUrl="/users" overlap>
      <Box>
        {user && (
          <Form
            form={form}
            initialValues={user}
            onFinish={mutate}
            layout="vertical"
            disabled={isPending}
          >
            <UserForm user={user} />
          </Form>
        )}
      </Box>
    </Page>
  );
};

export default User;
