import { Form, App } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addErrorsFromAPIInForm } from '@gowgates/utils';
import { User } from '@gowgates/core';

import { updateUser } from '../../api/endpoints';
import UserForm from './components/Form';

type EditUserFormProps = {
  user: User;
  onCancel?: () => void;
  onSuccess?: () => void;
};

const EditUserForm = ({ user, onCancel, onSuccess = () => {} }: EditUserFormProps) => {
  const { message } = App.useApp();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: Partial<User>) => updateUser(user.id, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.setQueryData(['user', user.id], data);
      queryClient.invalidateQueries({ queryKey: ['resourceUsers'] });
      message.success('User updated');

      onSuccess();
    }
  });

  return (
    <Form form={form} onFinish={mutate} initialValues={user} layout="vertical" disabled={isLoading}>
      <UserForm user={user} onCancel={onCancel} />
    </Form>
  );
};

export default EditUserForm;
