import { Form, Button, App, Input, Switch } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { Scope } from '@gowgates/core';
import { FormItem } from '@gowgates/dynamic-fields';
import { PlusOutlined } from '@ant-design/icons';

import { getScope, updateScope } from '../../api/endpoints';
import { showSavedMessage } from '../../utils/messages';
import FullLoader from '../../components/FullLoader';
import { RuleConfig } from './RuleConfig';

type AttributeUpdatedType = (prevValues: Scope, currentValues: Scope) => boolean;

const ScopeForm = () => {
  const { message } = App.useApp();
  const scopeId = Number(useParams().scopeId);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { isLoading, data: scope } = useQuery({
    queryKey: ['scope', scopeId],
    queryFn: () => getScope(scopeId)
  });

  const { isPending: isMutating, mutate } = useMutation({
    mutationFn: (values: Scope) => updateScope(scopeId, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['myScopes'] });
      queryClient.setQueryData(['scope', scopeId], data);
      showSavedMessage(message);
    }
  });

  if (isLoading) {
    return <FullLoader />;
  }

  if (!scope) return;

  const isDisabled = isMutating || !scope.permissions.update;

  const modelUpdated: AttributeUpdatedType = (prevValues, currentValues) =>
    prevValues.full !== currentValues.full;

  return (
    <Form
      form={form}
      onFinish={mutate}
      initialValues={scope}
      disabled={isDisabled}
      layout="vertical"
    >
      <FormItem name="name" model="scope">
        <Input data-1p-ignore data-lpignore data-protonpass-ignore />
      </FormItem>

      <FormItem name="full" model="scope">
        <Switch />
      </FormItem>

      <Form.Item noStyle shouldUpdate={modelUpdated}>
        {({ getFieldValue }) =>
          !getFieldValue('full') && (
            <Form.List name="rules">
              {(rules, { add, remove }) => (
                <>
                  {rules.map((rule) => (
                    <RuleConfig key={rule.key} rule={rule} remove={remove} />
                  ))}
                  <Form.Item>
                    <Button onClick={() => add()} icon={<PlusOutlined />}>
                      {t('tenant.columns.add')}
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          )
        }
      </Form.Item>

      <div className="d-flex justify-content-end">
        {scope.permissions.update && (
          <Button type="primary" htmlType="submit" loading={isDisabled}>
            {t('globals.save')}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default ScopeForm;
