import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import cloneDeep from 'lodash.clonedeep';

import { datesDiff } from '@gowgates/utils';

import { Task } from '../../../../types';

/* Types */
type Props = {
  tasks: Task[];
};

type TaskFilter = {
  search?: string;
  assigneeIds?: string[];
  names?: string[];
  sortBy?: 'createdAt' | 'dueAt' | 'completedAt';
  order?: 'asc' | 'desc';
};

type AssertFunc = (task: Task, filter: TaskFilter) => boolean;

/* Helper functions */
const isSearchFilterEmpty = ({ search, assigneeIds, names }: TaskFilter) =>
  !search && !assigneeIds?.length && !names?.length;
const isSortFilterEmpty = ({ sortBy, order }: TaskFilter) => !sortBy && !order;

const filterAndSortTasks = (tasks: Task[], filter: TaskFilter) => {
  const filteredTasks = isSearchFilterEmpty(filter)
    ? tasks
    : tasks.filter(
        (t) =>
          assertTaskForSearch(t, filter) &&
          assertTaskForAssignees(t, filter) &&
          assertTaskForName(t, filter)
      );

  return isSortFilterEmpty(filter) ? filteredTasks : sortTasks(filteredTasks, filter);
};

// Assert functions: return true when there is no filter for assertion in case
const assertTaskForSearch: AssertFunc = (task, { search }) => {
  const normalizedSearch = search?.trim().toLowerCase();

  return normalizedSearch
    ? task.name.toLowerCase().includes(normalizedSearch) ||
        (task.user ? task.user.name.toLowerCase().includes(normalizedSearch) : true)
    : true;
};

const assertTaskForAssignees: AssertFunc = (task, { assigneeIds }) =>
  assigneeIds?.length ? assigneeIds.includes(String(task.user?.id) || '-1') : true;
const assertTaskForName: AssertFunc = (task, { names }) =>
  names?.length ? names.includes(task.name) : true;

const sortTasks = (tasks: Task[], { sortBy, order }: TaskFilter) => {
  if (!sortBy) return tasks;

  const sortedTasks: { [k in Required<TaskFilter>['sortBy']]: string }[] = cloneDeep(tasks);
  sortedTasks.sort((first, second) => {
    // needed for completedAt, not defined in some situations. This sorts all rows, with value for sortBy property defined, to the top
    if (!first[sortBy] && !second[sortBy]) return 0;
    if (!first[sortBy] && second[sortBy]) return 1;
    if (first[sortBy] && !second[sortBy]) return -1;

    return order === 'asc'
      ? datesDiff(first[sortBy], second[sortBy])
      : datesDiff(second[sortBy], first[sortBy]);
  }) as Task[];

  return sortedTasks as Task[];
};

export const useTasksFilter = ({ tasks }: Props) => {
  const [searchParams] = useSearchParams();

  const [filteredTasks, setFilteredTasks] = useState(tasks);

  useEffect(() => {
    const search = searchParams.get('q') || '';
    const assigneeIdsFilter = searchParams.getAll('userId');
    const namesFilter = searchParams.getAll('name');
    const sortByFilter = searchParams.get('sortBy') || undefined;
    const orderFilter = searchParams.get('order') || undefined;

    setFilteredTasks(
      filterAndSortTasks(tasks, {
        search,
        assigneeIds: assigneeIdsFilter,
        names: namesFilter,
        sortBy: sortByFilter as TaskFilter['sortBy'],
        order: orderFilter as TaskFilter['order']
      })
    );
  }, [tasks, searchParams]);

  return { filteredTasks };
};
