import { useMemo } from 'react';
import { Button, Col, Row, Select } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined } from '@ant-design/icons';
import { UrlId, t } from '@gowgates/utils';
import { apiConfigs, extractData } from '@gowgates/api-client';
import { useFieldTemplatesQuery } from '../../../hooks/field-templates/useFieldTemplatesQuery';
import { Field } from '../../../types';
import { getConditionalFieldsUsingField } from '../../../utils';
import { ClearableSelect, FormItem } from '../../forms';
import { ExtendedDangerPopconfirm } from '../../ExtendedDangerPopconfirm';
import { useDynamicDataConfigsQuery } from '../../../hooks/dynamic-data-configs/useDynamicDataConfigsQuery';
import { useFormBuilder } from '../../../hooks/useFormBuilder';
import { fieldableBaseQueryKey } from '../../../hooks/fields/useFieldsQuery';
import { MoveField } from './MoveField';

const client = () => apiConfigs().clientInstance;

const deleteField = (id: UrlId) => client().delete(`/dynamic_data/fields/${id}`).then(extractData);

const widths = Array.from(Array(24).keys()).map((val) => ({
  value: val + 1,
  label: val + 1
}));

export const Header = ({ field }: { field: Partial<Field> }) => {
  const { fieldableType, fieldableId, fields, isNested } = useFormBuilder();
  const { fieldTemplates: allFieldTemplates } = useFieldTemplatesQuery();
  const { allFieldTypes, nestedFieldTypes } = useDynamicDataConfigsQuery();
  const fieldNames = fields.map((f) => f.name);

  const fieldTemplates = allFieldTemplates.map((fieldTemplate) => ({
    value: fieldTemplate.name,
    label: fieldTemplate.label,
    disabled: fieldNames.indexOf(fieldTemplate.name) >= 0 && fieldTemplate.name !== field.name
  }));

  const fieldTypes = isNested ? nestedFieldTypes : allFieldTypes;

  const conditionalFieldsUsingField = useMemo(
    () => getConditionalFieldsUsingField({ field, fields }),
    [field, fields]
  );
  const isUsedForConditionalFields = conditionalFieldsUsingField.length > 0;

  return (
    <header className="field-container-header">
      <Row align="middle" gutter={30}>
        <Col span={6}>
          <FormItem name="type">
            <Select options={fieldTypes} disabled={!!field.template} />
          </FormItem>
        </Col>

        <Col span={5}>
          <FormItem model="field" name="columns">
            <Select options={widths} showSearch />
          </FormItem>
        </Col>

        {isNested ? (
          <Col span={8} />
        ) : (
          <Col span={8}>
            <FormItem model="field" name="template">
              <ClearableSelect
                name="template"
                options={fieldTemplates}
                showSearch
                optionFilterProp="label"
                placeholder={t('globals.none')}
              />
            </FormItem>
          </Col>
        )}

        <Col span={3} offset={2} className="d-flex justify-content-end">
          <ExtendedDangerPopconfirm
            title={t('formBuilder.confirmDeleteField')}
            deleteFn={async () => field.id && deleteField(field.id)}
            invalidateQueries={fieldableBaseQueryKey({ fieldableId, fieldableType })}
            permission={!!field.id}
            tooltipMessage={
              isUsedForConditionalFields
                ? t('formBuilder.inUseRemoveMessage', {
                    fields: conditionalFieldsUsingField.map(({ label }) => label).join(', ')
                  })
                : undefined
            }
          >
            <Button
              size="small"
              className="field-header-action"
              disabled={isUsedForConditionalFields}
            >
              <DeleteOutlined />
            </Button>
          </ExtendedDangerPopconfirm>

          {field.id && field.position && (
            <>
              <MoveField
                field={field}
                position={field.position - 1}
                tooltip={t('formBuilder.moveFieldUp')}
                icon={<ArrowUpOutlined />}
              />
              <MoveField
                field={field}
                position={field.position + 1}
                tooltip={t('formBuilder.moveFieldDown')}
                icon={<ArrowDownOutlined />}
              />
            </>
          )}
        </Col>
      </Row>
    </header>
  );
};
