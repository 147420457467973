import { useState } from 'react';
import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';

import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';

import { ClaimStructure } from '../../../types';
import { getSectionStructures } from '../../../api/endpoints';
import NewSectionStructureModal from './NewSectionStructureModal';

const { Column } = Table;

const StructureSections = () => {
  const structureId = Number(useParams().structureId);
  const queryClient = useQueryClient();
  const claimStructure = queryClient.getQueryData<ClaimStructure>(['claimStructure', structureId]);
  const [isModalOpen, setModalOpen] = useState(false);
  usePageTitle(
    `${claimStructure.name} - ${t('claim.backofficeCreateStatuses.sectionDetails')} | ${t(
      'activerecord.models.claimStructure.other'
    )}`
  );

  const { isLoading, data } = useQuery({
    queryKey: ['sectionStructures', structureId],
    queryFn: () => getSectionStructures(structureId)
  });

  return (
    <>
      <header className="d-flex justify-content-end">
        <Button type="primary" onClick={() => setModalOpen(true)} icon={<PlusOutlined />}>
          {t('section.add')}
        </Button>
      </header>

      <Table
        dataSource={data}
        rowKey={(record) => record.id}
        pagination={false}
        loading={isLoading}
        className="box-table"
      >
        <Column
          title="Name"
          dataIndex="name"
          render={(text, record) => (
            <Link to={`${record.id}`} className="col-clickable">
              {text}
            </Link>
          )}
        />
      </Table>

      <NewSectionStructureModal
        isOpen={isModalOpen}
        setOpen={setModalOpen}
        structureId={structureId}
      />
    </>
  );
};

export default StructureSections;
