import { AxiosError } from 'axios';

type ErrorChecker = (error?: AxiosError) => boolean;

export const isUnauthorized: ErrorChecker = (error) => error?.response?.status === 401;
export const isNotFound: ErrorChecker = (error) => error?.response?.status === 404;
export const isForbidden: ErrorChecker = (error) => error?.response?.status === 403;
export const isUnprocessableEntity: ErrorChecker = (error) => error?.response?.status === 422;
export const isInternalServerError: ErrorChecker = (error) => error?.response?.status === 500;

export type BaseError = { errors: { base: string } };
