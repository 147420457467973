import { useState } from 'react';
import { Button } from 'antd';

import { t } from '@gowgates/utils';

import usePermissions from '../../hooks/usePermissions';
import NewScopeModal from './NewScopeModal';

const ScopeActions = () => {
  const { permissions } = usePermissions();
  const [isModalOpen, setModalOpen] = useState(false);

  const showModal = () => {
    setModalOpen(true);
  };

  if (!permissions.createScope) {
    return null;
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {t('scope.new')}
      </Button>

      <NewScopeModal isOpen={isModalOpen} setOpen={setModalOpen} />
    </>
  );
};

export default ScopeActions;
