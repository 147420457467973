import { Col, Form, Input, Row } from 'antd';
import { camelCase } from 'change-case';

import { DynamicForm } from '@gowgates/dynamic-fields';

import { ClaimStructure } from '../../../types';

type ClaimDetailsFormProps = { claimStructure: ClaimStructure };

const ClaimDetailsForm = ({ claimStructure }: ClaimDetailsFormProps) => (
  <>
    <Row className="structure-validator-errors">
      <Col span={16} offset={8}>
        <Form.Item name={['data', 'base', 'data', camelCase(claimStructure.name)]}>
          <Input hidden />
        </Form.Item>
      </Col>
    </Row>

    <DynamicForm fields={claimStructure.claim} />
  </>
);

export default ClaimDetailsForm;
