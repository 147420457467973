import { ClaimStructure } from '../../../types';
import { CreateStepsContextProvider } from '../../../contexts/CreateStepsContext';
import CreateClaim from '.';

type CreateClaimContainerProps = {
  claim: ClaimStructure;
};
const CreateClaimContainer = ({ claim }: CreateClaimContainerProps) => (
  <CreateStepsContextProvider claim={claim}>
    <CreateClaim claim={claim} />
  </CreateStepsContextProvider>
);

export default CreateClaimContainer;
