import React, { useEffect } from 'react';
import qs from 'query-string';
import { Input, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router';

const TableSearch = () => {
  const search = qs.parse(useLocation().search);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  // update in case q from query string is changed by external component
  useEffect(() => {
    form.setFieldValue('q', search.q);
  }, [search.q, form]);

  return (
    <Form form={form} initialValues={search}>
      <Form.Item name="q">
        <Input
          prefix={<SearchOutlined />}
          onPressEnter={(event) => {
            const { value } = event.target as HTMLInputElement;

            // stores in `rest` all search keys except `page`
            const { page: _, ...rest } = search;

            navigate({ search: qs.stringify({ ...rest, q: value }) });
          }}
          allowClear
          onChange={(event) => {
            if (!event.target.value && event.type !== 'change') {
              // stores in `rest` all search keys except `page` and `q`
              const { page: _page, q: _q, ...rest } = search;
              navigate({ search: qs.stringify({ ...rest }) });
            }
          }}
        />
      </Form.Item>
    </Form>
  );
};

export default TableSearch;
