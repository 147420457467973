import { useQuery } from '@tanstack/react-query';
import { Table, TableProps, App } from 'antd';
import { Link } from 'react-router-dom';
import { AxiosError } from 'axios';
import { BaseError, isUnprocessableEntity } from '@gowgates/api-client';
import { t } from '@gowgates/utils';
import { ExtendedDangerPopconfirm } from '@gowgates/dynamic-fields';
import { usePageTitle } from '@gowgates/core';
import usePermissions from '../../hooks/usePermissions';
import { deleteEmailTemplate, getEmailTemplates } from '../../api/endpoints';
import { EmailTemplate } from '../../types';
import Page from '../../components/Page';
import { EmailTemplateActions } from './EmailTemplateActions';

export const EmailTemplates = () => {
  const { message } = App.useApp();
  usePageTitle(t('activerecord.models.emailTemplate.other'));
  const { permissions } = usePermissions();

  const { isFetching, data } = useQuery({
    queryKey: ['emailTemplates'],
    queryFn: () => getEmailTemplates()
  });

  const onDeleteError = (error: AxiosError<BaseError>) => {
    if (isUnprocessableEntity(error) && error.response?.data.errors.base) {
      message.error(error.response.data.errors.base);
    }
  };

  const onDeleteSuccess = () => {
    message.success(t('emailTemplate.deletedMessage'));
  };

  const columns: TableProps<EmailTemplate>['columns'] = [
    {
      title: t('activerecord.attributes.emailTemplate.name'),
      dataIndex: 'name',
      render: permissions.showEmailTemplate
        ? (text, record) => (
            <Link to={`/email-templates/${record.id}`} className="col-clickable">
              {text}
            </Link>
          )
        : undefined
    }
  ];

  if (permissions.deleteEmailTemplate) {
    columns.push({
      render: (_, record) => (
        <div className="d-flex justify-content-end">
          <ExtendedDangerPopconfirm
            deleteFn={() => deleteEmailTemplate(record.id)}
            invalidateQueries={['emailTemplates']}
            permission
            onSuccess={onDeleteSuccess}
            onError={onDeleteError}
          />
        </div>
      )
    });
  }

  return (
    <Page title={t('activerecord.models.emailTemplate.other')} actions={<EmailTemplateActions />}>
      <Table
        dataSource={data}
        rowKey={(record) => record.id}
        className="page-table"
        loading={isFetching}
        columns={columns}
      />
    </Page>
  );
};
