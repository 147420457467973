import { useEffect } from 'react';
import { App, Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';

import { DataType, t } from '@gowgates/utils';
import { setPageTitle } from '@gowgates/core';

import { deleteScope, getScope } from '../../api/endpoints';
import Page from '../../components/Page';
import { showBaseErrors } from '../../utils/messages';
import FullLoader from '../../components/FullLoader';
import PageContextMenu from '../../components/PageContextMenu';
import ScopeNav from './ScopeNav';

const Scope = () => {
  const { message } = App.useApp();
  const scopeId = Number(useParams().scopeId);
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === `/scopes/${scopeId}`) {
      navigate('scopes', { replace: true });
    }
  }, [pathname]);

  const { mutate: mudateDestroy } = useMutation({
    mutationFn: () => deleteScope(scopeId),
    onError: (error: AxiosError<DataType>) => {
      error?.response && showBaseErrors(error.response.data, message);
    },
    onSuccess: () => {
      message.success(`${t('globals.deleted')}!`);
      queryClient.invalidateQueries({ queryKey: ['scopes'] }).then(() => {
        navigate('/scopes');
      });
    }
  });

  const { isLoading, data: scope } = useQuery({
    queryKey: ['scope', scopeId],
    queryFn: () => getScope(scopeId)
  });

  useEffect(() => {
    if (!scope) return;

    setPageTitle(`${scope.name} | ${t('activerecord.models.scope.other')}`);
  }, [scope]);

  if (isLoading) {
    return <FullLoader />;
  }

  if (!scope) return;

  const destroy = () => {
    Modal.confirm({
      title: t('scope.confirmDestroy'),
      icon: <ExclamationCircleOutlined />,
      okText: t('globals.yesNo.true'),
      okType: 'danger',
      cancelText: t('globals.yesNo.false'),
      onOk() {
        mudateDestroy();
      }
    });
  };

  const contextMenuItems = [];
  if (scope.permissions.destroy) {
    contextMenuItems.push({ key: 'delete', label: t('globals.delete'), onClick: destroy });
  }

  return (
    <Page
      title={scope.name}
      backUrl="/scopes"
      contextMenu={<PageContextMenu items={contextMenuItems} />}
      nav={<ScopeNav />}
      summary={<></>}
    >
      <Outlet />
    </Page>
  );
};

export default Scope;
