import { useCallback, useState } from 'react';
import { To } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Form, Modal, Result, Typography } from 'antd';

import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { useGoBack } from '@gowgates/core';

import { ApiKey, CreatedApiKey } from '../../../types';
import { createApiKey } from '../../../api/endpoints';
import { ApiKeyFormFields } from '../../../components/ApiKeyFormFields';
import { NEW_API_KEY_FORM_ID } from './configs';

export const NewApiKeyModal = () => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { goBack, navigate } = useGoBack();

  const [createdApiKey, setCreatedApiKey] = useState<CreatedApiKey>();

  const closeModal = useCallback(
    (to?: To) => {
      form.resetFields();
      to ? navigate(to) : goBack({ fallback: window.location.pathname });
    },
    [form, navigate, goBack]
  );

  const { isPending, mutate } = useMutation({
    mutationFn: (values: Partial<ApiKey>) => createApiKey(values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['apiKeys'] });

      setCreatedApiKey(data);
      form.resetFields();
    }
  });

  return (
    <Modal
      title={createdApiKey?.name || t('apiKey.new')}
      onCancel={() => closeModal()}
      footer={createdApiKey ? null : undefined}
      okButtonProps={{
        loading: isPending,
        htmlType: 'submit',
        form: NEW_API_KEY_FORM_ID
      }}
      open
      okText={t('globals.save')}
      cancelText={t('globals.cancel')}
    >
      <Form
        id={NEW_API_KEY_FORM_ID}
        onFinish={mutate}
        layout="vertical"
        form={form}
        disabled={isPending}
        initialValues={{ enabled: true }}
      >
        {createdApiKey ? (
          <Result
            status="success"
            title={t('apiKey.createSuccess')}
            subTitle={t('apiKey.copyTokenMessage')}
            extra={[
              <Typography.Paragraph code copyable key="token">
                {createdApiKey.accessToken}
              </Typography.Paragraph>
            ]}
          />
        ) : (
          <ApiKeyFormFields />
        )}
      </Form>
    </Modal>
  );
};
