import { useState, useEffect } from 'react';
import { Col, Divider, Row, Space, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router';

import { t } from '@gowgates/utils';

import { deleteTaskTrigger, updateTaskTrigger } from '../../../api/endpoints';
import EditableDescriptions from '../../../components/EditableDescriptions';
import TaskTriggerDescriptions from '../TaskTrigger/Descriptions';
import TaskTriggerForm from '../TaskTrigger/Form';
import TaskTriggerModal from '../TaskTrigger/Modal';
import { TaskStructure, TaskTrigger } from '../../../types';

const filterTaskTriggers = (taskTriggers: TaskTrigger[] = [], event: string) =>
  (taskTriggers || []).filter((taskTrigger) => taskTrigger.event === event);

type TaskTriggersProps = {
  taskStructure: TaskStructure;
  event: string;
};

const TaskTriggers = ({ taskStructure, event }: TaskTriggersProps) => {
  const journeyId = Number(useParams().journeyId);
  const [isModalOpen, setModalOpen] = useState(false);
  const [taskTriggers, setTaskTriggers] = useState(
    filterTaskTriggers(taskStructure.taskTriggers, event)
  );

  useEffect(() => {
    setTaskTriggers(filterTaskTriggers(taskStructure.taskTriggers, event));
  }, [taskStructure.taskTriggers]);

  const addTrigger = () => {
    setModalOpen(true);
  };

  return (
    <>
      <section className="inner-box">
        <header>
          <Row align="middle">
            <Col span={18}>
              <Divider orientation="left">
                <span>{t(`taskTrigger.${event}`)}</span>
              </Divider>
            </Col>
            <Col span={6} className="d-flex justify-content-end">
              <Typography.Link onClick={addTrigger}>
                <Space>
                  <PlusOutlined />
                  {t(`taskTrigger.add.${event}`)}
                </Space>
              </Typography.Link>
            </Col>
          </Row>
        </header>

        {taskTriggers.map((taskTrigger, index) => (
          <EditableDescriptions
            key={taskTrigger.id}
            title={`Trigger ${index + 1}`}
            showPermission
            editPermission
            descriptions={
              <TaskTriggerDescriptions taskTrigger={taskTrigger} taskStructure={taskStructure} />
            }
            form={<TaskTriggerForm taskStructure={taskStructure} />}
            model={taskTrigger}
            updateModel={(values) =>
              updateTaskTrigger(journeyId, taskStructure.id, taskTrigger.id, values as TaskTrigger)
            }
            queryDataKey={['taskStructure', taskStructure.id]}
            destroyModel={() => deleteTaskTrigger(journeyId, taskStructure.id, taskTrigger.id)}
            noLine
          />
        ))}

        {taskTriggers.length === 0 && (
          <Typography.Text type="secondary" italic>
            {t('taskTrigger.none')}
          </Typography.Text>
        )}
      </section>

      <TaskTriggerModal
        isOpen={isModalOpen}
        setOpen={setModalOpen}
        event={event}
        taskStructure={taskStructure}
      />
    </>
  );
};

export default TaskTriggers;
