import { QueryBuilderAntD } from '@react-querybuilder/antd';

import { AppWrapper } from '@gowgates/core';
import { fullTenantQueryFn, TenantProvider } from '@gowgates/claims-gateway-shared';

import router from './router';

import './stylesheets/app.scss';

export const App = () => (
  <AppWrapper>
    <QueryBuilderAntD>
      <TenantProvider router={router} tenantQueryFn={fullTenantQueryFn} />
    </QueryBuilderAntD>
  </AppWrapper>
);
