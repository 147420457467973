import { Link, useMatch } from 'react-router-dom';
import { Menu } from 'antd';

import { t } from '@gowgates/utils';

const StructureNav = () => {
  const match = useMatch({ path: '/structures/:structureId/:tab', end: false });

  const menuItems = [
    {
      key: 'details',
      label: <Link to="details">{t('globals.details')}</Link>
    },
    {
      key: 'claim',
      label: <Link to="claim">{t('activerecord.models.claim.one')}</Link>
    },
    {
      key: 'sections',
      label: <Link to="sections">{t('claim.backofficeCreateStatuses.sectionDetails')}</Link>
    },
    {
      key: 'documents',
      label: <Link to="documents">{t('activerecord.models.document.other')}</Link>
    },
    {
      key: 'reports',
      label: <Link to="reports">{t('activerecord.models.report.other')}</Link>
    }
  ];

  return (
    <Menu
      selectedKeys={match?.params?.tab ? [match?.params?.tab] : undefined}
      mode="horizontal"
      className="box-menu"
      items={menuItems}
    />
  );
};

export default StructureNav;
