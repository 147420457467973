import { GetProps, Typography } from 'antd';
import Icon, { EyeOutlined, SendOutlined } from '@ant-design/icons';

import { t } from '@gowgates/utils';

import { HandPointerIcon } from '../icons';
import { TaskEmail } from '../types';

const DELIVERED_STATUSES = ['delivered', 'open', 'clicked'];
const OPEN_STATUSES = ['open', 'clicked'];
const CLICKED_STATUSES = ['clicked'];
const FAILED_STATUSES = ['failed'];

type AssertEmailStatusFunc = (email?: TaskEmail) => boolean;

const emailStatus = (email?: TaskEmail) => email?.status || '';

const isEmailDelivered: AssertEmailStatusFunc = (email) =>
  DELIVERED_STATUSES.includes(emailStatus(email));
const isEmailOpen: AssertEmailStatusFunc = (email) => OPEN_STATUSES.includes(emailStatus(email));
const isEmailClicked: AssertEmailStatusFunc = (email) =>
  CLICKED_STATUSES.includes(emailStatus(email));
export const isEmailFailed: AssertEmailStatusFunc = (email) =>
  FAILED_STATUSES.includes(emailStatus(email));

type TypographyTextPropsType = GetProps<typeof Typography.Text>;
type EmailDetails = {
  color: Required<TypographyTextPropsType>['type'];
  text: string;
  IconComponent: typeof Icon;
};

const deliveryDetailsForEmail = (email: TaskEmail): EmailDetails => {
  let color: EmailDetails['color'], text;

  if (isEmailDelivered(email)) {
    color = 'success';
    text = t('sent_email.delivered_message');
  } else if (isEmailFailed(email)) {
    color = 'danger';
    text = email.reason || t('sent_email.failed_message');
  } else {
    color = 'secondary';
    text = t('sent_email.not_delivered_message');
  }

  return {
    color,
    text,
    IconComponent: SendOutlined
  };
};

const openDetailsForEmail = (email: TaskEmail): EmailDetails => {
  let color: EmailDetails['color'] = 'secondary',
    text = t('sent_email.not_open_message');
  if (isEmailOpen(email)) {
    color = 'success';
    text = t('sent_email.open_message');
  }

  return {
    color,
    text,
    IconComponent: EyeOutlined
  };
};

const clickedDetailsForEmail = (email: TaskEmail): EmailDetails => {
  let color: EmailDetails['color'] = 'secondary',
    text = t('sent_email.not_clicked_message');
  if (isEmailClicked(email)) {
    color = 'success';
    text = t('sent_email.clicked_message');
  }

  return {
    color,
    text,
    IconComponent: HandPointerIcon
  };
};

export const processEmailDetails = (
  email?: TaskEmail
): { delivery: EmailDetails; open: EmailDetails; clicked: EmailDetails } | undefined =>
  email && {
    delivery: deliveryDetailsForEmail(email),
    open: openDetailsForEmail(email),
    clicked: clickedDetailsForEmail(email)
  };
