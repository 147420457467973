import { useResourceRolesQuery } from '@gowgates/core';
import { t } from '@gowgates/utils';

import { Task } from '../../../../types';

const TaskRole = ({ task }: { task: Task }) => {
  const { roleName } = useResourceRolesQuery();

  if (task.frontoffice) {
    return t('activerecord.models.claimant.one');
  }

  return roleName(task.roleId, '');
};

export default TaskRole;
