import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { FormBuilder } from '@gowgates/dynamic-fields';

import { DocumentStructure } from '../../../../types';

const DocumentStructureFields = () => {
  const documentId = Number(useParams().documentId);
  const queryClient = useQueryClient();
  const documentStructure = queryClient.getQueryData<DocumentStructure>([
    'documentStructure',
    documentId
  ]);
  usePageTitle(
    `${documentStructure.label} - ${t('activerecord.models.field.other')} | ${t(
      'activerecord.models.claimStructure.other'
    )}`
  );

  return <FormBuilder fieldableId={documentId} fieldableType="document_structure" />;
};

export default DocumentStructureFields;
