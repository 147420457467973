import { Table, TableProps } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import { t } from '@gowgates/utils';
import { Permission, usePageTitle } from '@gowgates/core';

import { getPermissions } from '../../api/endpoints';
import Page from '../../components/Page';
import PermissionActions from './PermissionActions';

const Permissions = () => {
  usePageTitle(t('activerecord.models.permission.other'));

  const { isFetching, data } = useQuery({ queryKey: ['permissions'], queryFn: getPermissions });

  const columns: TableProps<Permission>['columns'] = [
    {
      dataIndex: 'name',
      title: t('activerecord.attributes.permission.name'),
      render: (text, record) => (
        <Link to={`/permissions/${record.id}`} className="col-clickable">
          {text}
        </Link>
      )
    },
    {
      dataIndex: 'usersCount',
      title: t('activerecord.attributes.permission.usersCount'),
      render: (text, record) => (
        <Link to={`/permissions/${record.id}`} className="col-clickable">
          {text}
        </Link>
      )
    }
  ];

  return (
    <Page title={t('activerecord.models.permission.other')} actions={<PermissionActions />}>
      <Table
        dataSource={data}
        rowKey={(record) => String(record.id)}
        className="page-table"
        loading={isFetching}
        pagination={false}
        columns={columns}
      />
    </Page>
  );
};

export default Permissions;
