import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import { Menu } from 'antd';
import { t } from '@gowgates/utils';

import usePermissions from '../../hooks/usePermissions';

export const Nav = () => {
  const match = useMatch({ path: '/tenant/:tab', end: false });
  const { permissions } = usePermissions();

  const menuItems = [
    permissions.updateTenant
      ? {
          key: 'general',
          label: <Link to="general">{t('tenant.details')}</Link>
        }
      : null,
    permissions.manageApiKeys
      ? {
          key: 'api-keys',
          label: <Link to="api-keys">{t('activerecord.models.apiKey.other')}</Link>
        }
      : null,
    permissions.manageWebhooks
      ? {
          key: 'webhooks',
          label: <Link to="webhooks">{t('activerecord.models.webhook.other')}</Link>
        }
      : null,
    permissions.updateTenant
      ? {
          key: 'dashboard-columns',
          label: (
            <Link to="dashboard-columns">
              {t('activerecord.attributes.tenant.dashboardColumns')}
            </Link>
          )
        }
      : null,
    permissions.updateTenant
      ? {
          key: 'tasks-columns',
          label: <Link to="tasks-columns">{t('activerecord.attributes.tenant.tasksColumns')}</Link>
        }
      : null,
    permissions.updateTenant
      ? {
          key: 'claims-columns',
          label: (
            <Link to="claims-columns">{t('activerecord.attributes.tenant.claimsColumns')}</Link>
          )
        }
      : null
  ];

  return (
    <Menu
      selectedKeys={match?.params?.tab ? [match?.params?.tab] : []}
      mode="horizontal"
      className="box-menu"
      items={menuItems}
    />
  );
};
