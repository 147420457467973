export const HighlightText = ({
  searchWords = [],
  text = ''
}: {
  searchWords: string[];
  text: string;
}) => {
  const highlightText = (text = '', searchWords: string[] = []) => {
    const lowerCaseSearchWords = searchWords.map((w = '') => w.toLowerCase());
    const regex = new RegExp(`(${lowerCaseSearchWords.join('|')})`, 'gi');
    const parts = text.split(regex);

    return parts.map((part, index) =>
      lowerCaseSearchWords.includes(part.toLowerCase()) ? (
        <span key={index} className="text-highlighted">
          {part}
        </span>
      ) : (
        <span key={index}>{part}</span>
      )
    );
  };

  return <span>{highlightText(text || '', searchWords || [])}</span>;
};
