import { App, Button, Flex, Table } from 'antd';
import { Outlet, useLocation } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { UseTableProps, useTable } from '@gowgates/core';
import { t } from '@gowgates/utils';
import { ExtendedDangerPopconfirm, useAppConfigs } from '@gowgates/dynamic-fields';

import { ApiKey, AppConfigs } from '../../../types';
import { deleteApiKey, getApiKeys } from '../../../api/endpoints';

export const ApiKeys = () => {
  const { message } = App.useApp();
  const searchString = useLocation().search;
  const { appConfigs } = useAppConfigs();

  const onSuccess = () => {
    message.success(t('apiKey.deleteSuccess'));
  };

  const onError = () => {
    message.error(t('globals.unexpectedError'));
  };

  const tableSchema: UseTableProps<ApiKey> = {
    model: 'apiKey',
    rowUrlPattern: `:id${searchString}`,
    frontendSort: true,
    columns: [
      {
        dataIndex: 'name',
        width: '80%',
        sorter: (first, second) => (first.name.toLowerCase() < second.name.toLowerCase() ? -1 : 1)
      },
      {
        dataIndex: 'enabled',
        render: (_value, webhook) => t(`globals.yesNo.${webhook.enabled}`),
        width: '12.5%',
        filters: (appConfigs as AppConfigs)?.global.boolean,
        onFilter: (value, record) => record.enabled === value
      },
      {
        render: (_value, apiKey) => (
          <Flex justify="center" align="center">
            <ExtendedDangerPopconfirm
              deleteFn={() => deleteApiKey(apiKey.id)}
              invalidateQueries={['apiKeys']}
              permission
              onSuccess={onSuccess}
              onError={onError}
              message={t('apiKey.deleteMessage')}
            />
          </Flex>
        ),
        width: '7.5%'
      }
    ]
  };

  const { handleTableChange, columns } = useTable<ApiKey>(tableSchema);

  const { isFetching, data: apiKeys } = useQuery({
    queryKey: ['apiKeys'],
    queryFn: () => getApiKeys()
  });

  return (
    <Flex vertical gap={16}>
      <Flex justify="flex-end">
        <Link to={`new${searchString}`}>
          <Button icon={<PlusOutlined />} type="primary">
            {t('apiKey.new')}
          </Button>
        </Link>
      </Flex>
      <Table
        rowKey={(record) => String(record.id)}
        columns={columns}
        dataSource={apiKeys}
        onChange={handleTableChange}
        loading={isFetching}
        pagination={false}
      />
      <Outlet />
    </Flex>
  );
};
