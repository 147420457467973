import React from 'react';
import { Divider } from 'antd';

type InnerBoxProps = {
  title?: React.ReactNode;
  className?: string;
  noLine?: boolean;
  children: React.ReactNode;
};

const InnerBox = ({ title, children, className = '', noLine = false }: InnerBoxProps) => (
  <section className={`inner-box ${className}`}>
    {title &&
      (noLine ? (
        <h4 className="inner-box-title">{title}</h4>
      ) : (
        <Divider orientation="left">{title}</Divider>
      ))}

    {children}
  </section>
);

export default InnerBox;
