import { Form, FormInstance, FormListFieldData, Input } from 'antd';

import { File } from '@gowgates/dynamic-fields';

import { Document } from '../../types';

type DocumentUploaderProps = {
  form: FormInstance;
  document: FormListFieldData;
  remove: (documentName: FormListFieldData['name']) => void;
};

const DocumentUploader = ({ form, document, remove }: DocumentUploaderProps) => {
  const formDocument = form.getFieldValue('documents')[document.name];

  const onDestroy = async () => {
    const documents = form.getFieldValue('documents').map((doc: Document) => {
      if (doc.id === formDocument.id) {
        return { ...doc, _destroy: true };
      }

      return doc;
    });
    form.setFieldsValue({ documents });
  };

  const onRemove = () => {
    remove(document.name);
  };

  // eslint-disable-next-line no-underscore-dangle
  if (formDocument._destroy) {
    return (
      <Form.Item name={[document.name, '_destroy']} hidden>
        <Input />
      </Form.Item>
    );
  }

  if (formDocument.id) {
    return (
      <File
        url={formDocument.file.url}
        type={formDocument.file.type}
        name={formDocument.file.name}
        onDestroy={onDestroy}
      />
    );
  }

  return (
    <>
      <File
        type={formDocument.file.type}
        name={formDocument.file.name}
        onRemove={onRemove}
        hasErrors={form.getFieldError(['documents', document.name, 'file']).length > 0}
      />

      <Form.Item name={[document.name, 'file']} hidden>
        <Input />
      </Form.Item>
    </>
  );
};

export default DocumentUploader;
