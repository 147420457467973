import { useQuery } from '@tanstack/react-query';

import { AnyObject } from '@gowgates/utils';
import { client, extractData } from '@gowgates/api-client';

import { ResourceUser } from '../../types';

export const resourceUsersQueryKey = (filters: AnyObject = {}) => {
  const flatFilters = Object.keys(filters)
    .map((k) => [k, filters[k]])
    .flat();

  return ['resourceUsers', ...flatFilters];
};

const getResourceUsers = (params: AnyObject) =>
  client()
    .get('resources/users', { params })
    .then(extractData<ResourceUser[]>);

export const useResourceUsersQuery = (filters: AnyObject = {}) => {
  const { data: users, ...query } = useQuery({
    queryKey: resourceUsersQueryKey(filters),
    queryFn: () => getResourceUsers(filters),
    placeholderData: [],
    staleTime: Infinity
  });

  const userFilters = users?.map((u) => ({ text: u.name, value: u.id }));

  return {
    ...query,
    users,
    userFilters
  };
};
