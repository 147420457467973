import { Divider, Space, Switch, Tooltip, Typography } from 'antd';

import { t } from '@gowgates/utils';

import { AppConfigs } from '../../../types/app-configs';

type SectionDividerProps = {
  fileConfig: AppConfigs['fileConfig'];
  updateFileConfigVal: (key: keyof AppConfigs['fileConfig'], val: string | boolean) => void;
  labelKey: keyof AppConfigs['fileConfig'];
  toggleKey?: keyof AppConfigs['fileConfig'];
};

const toogleKeyValue = (
  fileConfig: AppConfigs['fileConfig'],
  toggleKey: keyof AppConfigs['fileConfig']
): boolean => {
  const toogleValue = fileConfig[toggleKey];
  const value = typeof toogleValue === 'boolean' ? toogleValue : false;

  return value;
};

export const SectionDivider = ({
  labelKey,
  toggleKey,
  fileConfig,
  updateFileConfigVal
}: SectionDividerProps) =>
  fileConfig ? (
    <Divider orientation="left">
      <Space size="middle" align="baseline">
        <Typography.Paragraph editable={{ onChange: (val) => updateFileConfigVal(labelKey, val) }}>
          {fileConfig[labelKey]}
        </Typography.Paragraph>

        {toggleKey && (
          <Tooltip
            title={t(`globals.${fileConfig[toggleKey] ? 'section_enabled' : 'section_disabled'}`)}
          >
            <Switch
              checked={toogleKeyValue(fileConfig, toggleKey)}
              size="small"
              onChange={(val) => updateFileConfigVal(toggleKey, val)}
            />
          </Tooltip>
        )}
      </Space>
    </Divider>
  ) : null;
