import { Space, Upload } from 'antd';

import { t } from '@gowgates/utils';
import { DateFormatter } from '@gowgates/dynamic-fields';
import { UserAvatar, useUpdateUserMutation, User } from '@gowgates/core';

type ProfileSummaryProps = {
  user: User;
};
const ProfileSummary = ({ user }: ProfileSummaryProps) => {
  const { updateUser: updateUserAvatar, isPending: isUploadingAvatar } = useUpdateUserMutation({
    id: user?.id,
    isMe: true
  });

  return (
    <aside className="page-summary">
      <Space direction="vertical" size="middle" className="w-100">
        <Space direction="vertical" className="w-100 text-center">
          <Upload
            name="avatar"
            beforeUpload={(avatar) => {
              updateUserAvatar({ avatar });

              return false;
            }}
            accept="image/*"
            showUploadList={false}
          >
            <UserAvatar size={60} user={user} loading={isUploadingAvatar} className="pointer" />
          </Upload>
        </Space>

        <dl>
          <dt>{t('activerecord.attributes.user.createdAt')}</dt>
          <dd>
            <DateFormatter value={user.createdAt} />
          </dd>
        </dl>
      </Space>
    </aside>
  );
};

export default ProfileSummary;
