import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { Table } from 'antd';

import { t } from '@gowgates/utils';
import { AvatarAndName, User } from '@gowgates/core';

import { getScope } from '../../api/endpoints';
import FullLoader from '../../components/FullLoader';
import UserCol from '../users/UserCol';

const ScopeUsersTable = () => {
  const scopeId = Number(useParams().scopeId);

  const { isLoading, data: scope } = useQuery({
    queryKey: ['scope', scopeId],
    queryFn: () => getScope(scopeId)
  });

  if (isLoading) {
    return <FullLoader />;
  }

  if (!scope) return;

  return (
    <Table
      dataSource={scope.members}
      rowKey={(record) => record.id}
      loading={isLoading}
      pagination={false}
      className="box-table"
    >
      <Table.Column
        title={t('activerecord.attributes.user.name')}
        key="name"
        render={(record) => (
          <UserCol user={record}>
            <AvatarAndName user={record} />
          </UserCol>
        )}
      />
      <Table.Column<User>
        title={t('activerecord.attributes.user.email')}
        dataIndex="email"
        render={(text, record) => <UserCol user={record}>{text}</UserCol>}
      />
    </Table>
  );
};

export default ScopeUsersTable;
