import { t } from '@gowgates/utils';
import { DateFormatter } from '@gowgates/dynamic-fields';

import useCaseManagers from '../../../hooks/useCaseManagers';
import useClaim from '../../../hooks/useClaim';
import useAppConfigs from '../../../hooks/useAppConfigs';

const FileDetailsDescriptions = () => {
  const { appConfigs } = useAppConfigs();
  const { userName } = useCaseManagers();
  const { claim } = useClaim();

  if (!appConfigs) return null;
  const fileConfig = appConfigs.fileConfig;

  return (
    <dl className="cg-descriptions">
      <dt>{fileConfig.notifiedAtLabel}</dt>
      <dd>
        <DateFormatter value={claim.notifiedAt} />
      </dd>

      {fileConfig.conflictFileActive && claim.conflictFile && (
        <>
          <dt>{fileConfig.conflictFileLabel}</dt>
          <dd>{t(`globals.yesNo.${claim.conflictFile}`)}</dd>

          {claim.conflictFile && (
            <>
              <dt>{t('activerecord.attributes.claim.conflictFileType')}</dt>
              <dd>{t(`claim.conflictFileTypes.${claim.conflictFileType}`)}</dd>

              {claim.conflictFileType === 'cgw' && claim.conflictClaim && (
                <>
                  <dt>{t('activerecord.attributes.claim.conflictClaimId')}</dt>
                  <dd>{claim.conflictClaim.label}</dd>
                </>
              )}

              {claim.conflictFileType === 'external' && (
                <>
                  <dt>{t('activerecord.attributes.claim.conflictFileNumber')}</dt>
                  <dd>{claim.conflictFileNumber}</dd>

                  <dt>{t('activerecord.attributes.claim.conflictCaseManagerId')}</dt>
                  <dd>{userName(claim.conflictCaseManagerId)}</dd>
                </>
              )}
            </>
          )}
        </>
      )}

      {fileConfig.vulnerableCustomerActive && (
        <>
          <dt>{fileConfig.vulnerableCustomerLabel}</dt>
          <dd>
            {claim.vulnerableCustomer !== null
              ? t(`globals.yesNo.${claim.vulnerableCustomer}`)
              : '-'}
          </dd>

          {claim.vulnerableCustomer && (
            <>
              <dt>{t('activerecord.attributes.claim.vulnerabilityReason')}</dt>
              <dd>{claim.vulnerabilityReason || '-'}</dd>
            </>
          )}
        </>
      )}
    </dl>
  );
};

export default FileDetailsDescriptions;
