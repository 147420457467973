import { Table, TableProps } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';

import { t } from '@gowgates/utils';

import { ButtonAction, TaskStructure, TaskTrigger } from '../../../types';
import ButtonActionCol from './ButtonActionCol';

type ButtonActionDescriptionsProps = {
  taskTrigger: TaskTrigger;
  taskStructure: TaskStructure;
  title?: string;
};

const ButtonActionDescriptions = ({
  taskTrigger,
  taskStructure,
  title
}: ButtonActionDescriptionsProps) => {
  const journeyId = Number(useParams().journeyId);
  const queryClient = useQueryClient();
  const taskStructures = queryClient.getQueryData<TaskStructure[]>(['taskStructures', journeyId]);

  const columns: TableProps<ButtonAction>['columns'] = [
    {
      dataIndex: 'type',
      render: (text) => t(`buttonAction.types.${text}`)
    },
    {
      render: (_text, record) => (
        <ButtonActionCol
          buttonAction={record}
          taskStructure={taskStructure}
          taskStructures={taskStructures}
        />
      )
    }
  ];

  return (
    <>
      <h4>{title || t('activerecord.models.buttonAction.other')}</h4>

      <Table
        dataSource={taskTrigger.buttonActions}
        rowKey={(record) => record.id}
        pagination={false}
        className="box-table"
        showHeader={false}
        columns={columns}
      />
    </>
  );
};

export default ButtonActionDescriptions;
