import { t } from '@gowgates/utils';
import { Scope, ScopeModel } from '@gowgates/core';
import { Form, FormListFieldData, Input, Select, Space } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import useFileConfig from '../../hooks/useFileConfig';

type AttributeUpdatedType = (prevValues: Scope, currentValues: Scope) => boolean;

type Props = {
  rule: FormListFieldData;
  remove: (fieldName: FormListFieldData['name']) => void;
};

export const RuleConfig = ({ rule, remove }: Props) => {
  const { name, key, ...restField } = rule;
  const { claimantStructure, clientStructure, coverStructure } = useFileConfig();

  const nonClaimFields = {
    claimant: claimantStructure?.map((field) => ({ value: field.name, label: field.label })),
    client: clientStructure?.map((field) => ({ value: field.name, label: field.label })),
    cover: coverStructure?.map((field) => ({ value: field.name, label: field.label })),
    claim: [{}]
  };

  const models = [
    { value: 'claim', label: t('activerecord.models.claim.one') },
    { value: 'claimant', label: t('activerecord.models.claimant.one') },
    { value: 'client', label: t('activerecord.models.client.one') },
    { value: 'cover', label: t('activerecord.models.cover.one') }
  ];

  // TODO: replace with dynamic operators
  const operators = [{ value: 'eq', label: 'Is equal to' }];

  const modelUpdated: AttributeUpdatedType = (prevValues, currentValues) =>
    prevValues.rules[name]?.model !== currentValues.rules[name]?.model;

  return (
    <Space style={{ display: 'flex', marginBottom: 8 }} align="baseline">
      <Form.Item {...restField} name={[name, 'model']}>
        <Select options={models} style={{ width: 100 }} />
      </Form.Item>

      <Form.Item noStyle shouldUpdate={modelUpdated}>
        {({ getFieldValue }) =>
          getFieldValue('rules')[name]?.model &&
          getFieldValue('rules')[name].model !== 'claim' && (
            <Form.Item name={[name, 'field']}>
              <Select
                options={nonClaimFields[getFieldValue('rules')[name].model as ScopeModel]}
                style={{ width: 200 }}
              />
            </Form.Item>
          )
        }
      </Form.Item>

      <Form.Item {...restField} name={[name, 'operator']}>
        <Select options={operators} style={{ width: 200 }} />
      </Form.Item>

      <Form.Item {...restField} name={[name, 'value']}>
        <Input />
      </Form.Item>

      <MinusCircleOutlined onClick={() => remove(name)} />
    </Space>
  );
};
