import { Form, Modal } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { createIntegration } from '../../api/endpoints';

import { Integration } from '../../types';
import ModalFormFooter from '../../components/ModalFormFooter';
import IntegrationForm from './Form';

type NewIntegrationModalProps = {
  isOpen?: boolean;
  setOpen: (open: boolean) => void;
};

const NewIntegrationModal = ({ isOpen = false, setOpen }: NewIntegrationModalProps) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: Integration) => createIntegration(values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({ queryKey: ['integrations'] });
    }
  });

  return (
    <Modal title={t('integration.new')} open={isOpen} onCancel={closeModal} footer={null}>
      <Form form={form} layout="vertical" onFinish={mutate} disabled={isLoading}>
        <IntegrationForm />

        <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
      </Form>
    </Modal>
  );
};

export default NewIntegrationModal;
