import { Button, Flex, Form, Popover } from 'antd';
import { useLocation, useNavigate } from 'react-router';
import { useState } from 'react';
import qs from 'query-string';
import { ControlOutlined } from '@ant-design/icons';
import isEmpty from 'lodash.isempty';

import { t } from '@gowgates/utils';

type TableFiltersProps = {
  content: React.ReactNode;
  disabled?: boolean;
};

export const TableFilters = ({ content, disabled = false }: TableFiltersProps) => {
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const search = qs.parse(useLocation().search);
  const { page, sort, q, ...rest } = search;
  const buttonType = isEmpty(rest) ? 'default' : 'primary';

  const onOpenChange = (newOpen: boolean) => {
    if (!newOpen) {
      applyFilters();
    } else {
      form.setFieldsValue(search);
      setOpen(true);
    }
  };

  const applyFilters = () => {
    navigate({ search: qs.stringify({ ...search, ...form.getFieldsValue() }) });
    setOpen(false);
  };

  const resetFilters = () => {
    form.resetFields();
    setOpen(false);
    navigate({ search: '' });
  };

  return (
    <Popover
      open={open}
      onOpenChange={onOpenChange}
      content={
        <Form form={form} layout="vertical" disabled={disabled}>
          {content}

          <Flex justify="space-between">
            <Button onClick={resetFilters}>{t('globals.clear')}</Button>
            <Button type="primary" onClick={applyFilters} className="uppercase">
              {t('globals.ok')}
            </Button>
          </Flex>
        </Form>
      }
      trigger="click"
    >
      <Button icon={<ControlOutlined />} type={buttonType} />
    </Popover>
  );
};
