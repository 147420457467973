import {
  Row,
  Col,
  Form,
  Input,
  Tooltip,
  Space,
  Typography,
  FormInstance,
  FormListFieldData,
  InputRef
} from 'antd';
import {
  MinusCircleOutlined,
  DragOutlined,
  ArrowRightOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useNavigate } from 'react-router-dom';

import { FormItem, ListItem } from '@gowgates/dynamic-fields';
import { AnyObject } from '@gowgates/utils';

type OptionProps = {
  listItem: FormListFieldData;
  add: (defaultValue?: AnyObject, insertIndex?: number | undefined) => void;
  form: FormInstance<ListItem>;
  addRef: (element: InputRef | null, id: number) => void;
  focusElement: (index?: number | null) => void;
};

const Option = ({ listItem, add, form, addRef, focusElement }: OptionProps) => {
  const navigate = useNavigate();
  /* @ts-expect-error useSortable id accepts any object */
  const { attributes, listeners, setNodeRef, transform } = useSortable({ id: listItem });

  const optionId = form.getFieldValue(['children', listItem.name, 'id']);
  const hasChildren = form.getFieldValue(['children', listItem.name, 'hasChildren']);
  const style = { transform: CSS.Transform.toString(transform) };

  const addOption = () => {
    add({});
    focusElement();
  };

  const deleteItem = (position: number) => {
    form.setFieldValue(['children', position, '_destroy'], true);
  };

  const showSubLevel = (position: number) => {
    navigate(`/lists/${form.getFieldValue(['children', position, 'id'])}`);
  };

  const destroyValueUpdated = (prev: ListItem, current: ListItem) => {
    const optionPosition = form.getFieldValue(['children', listItem.name, 'position']);
    const prevOptionData = prev.children.find((o) => o.position === optionPosition);
    const currentOptionData = current.children.find((o) => o.position === optionPosition);

    return Boolean(
      prevOptionData && currentOptionData && prevOptionData._destroy !== currentOptionData._destroy
    );
  };

  return (
    <Form.Item noStyle shouldUpdate={destroyValueUpdated}>
      {({ getFieldValue }) => (
        <Row
          align="middle"
          className={`field-choice ${
            getFieldValue(['children', listItem.name, '_destroy']) === true ? 'd-none' : ''
          }`}
          gutter={10}
          ref={setNodeRef}
          style={style}
        >
          <Col span={18}>
            <FormItem name={[listItem.name, 'name']}>
              <Input
                onPaste={(event) => {
                  const { value } = event.target as HTMLInputElement;
                  const pastedValues = (event.clipboardData.getData('text') || '').split('\n');

                  if (pastedValues.length > 1) {
                    event.preventDefault();
                    event.stopPropagation();

                    const oldChildren = [...form.getFieldValue('children')];
                    // adds the first pasted value to the input we are pasting on
                    oldChildren[listItem.name].name =
                      (value || '') + ((pastedValues || []).shift() || '').trim();

                    // creates an array with the remaining options keys and values
                    const remainingOptions = pastedValues.map((pastedValue) => ({
                      name: pastedValue.trim()
                    }));

                    form.setFieldsValue({ children: [...oldChildren, ...remainingOptions] });

                    focusElement();
                  }
                }}
                onPressEnter={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  addOption();
                }}
                onKeyDown={(event) => {
                  // event.keyCode === 8 -> backspace
                  if (
                    event.keyCode === 8 &&
                    (event.target as HTMLInputElement).value === '' &&
                    listItem.name !== 0
                  ) {
                    deleteItem(listItem.name);
                    focusElement(listItem.name - 1);
                  }
                }}
                ref={(element) => addRef(element, listItem.name)}
              />
            </FormItem>
          </Col>

          <Form.Item name={[listItem.name, 'id']} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={[listItem.name, '_destroy']} hidden>
            <Input />
          </Form.Item>
          <Form.Item name={[listItem.name, 'position']} hidden>
            <Input />
          </Form.Item>

          <Col span={2}>
            <Space>
              <Tooltip title="Remove option">
                <MinusCircleOutlined
                  className="remove-choice"
                  onClick={() => deleteItem(listItem.name)}
                />
              </Tooltip>

              <Tooltip title="Drag & drop to move option">
                <DragOutlined className="move-choice" {...attributes} {...listeners} />
              </Tooltip>

              {optionId && (
                <Typography.Link onClick={() => showSubLevel(listItem.name)}>
                  {typeof hasChildren === 'undefined' || hasChildren ? (
                    <Tooltip title="See children">
                      <ArrowRightOutlined />
                    </Tooltip>
                  ) : (
                    <Tooltip title="Add children">
                      <PlusCircleOutlined />
                    </Tooltip>
                  )}
                </Typography.Link>
              )}
            </Space>
          </Col>
        </Row>
      )}
    </Form.Item>
  );
};

export default Option;
