import { Row } from 'antd';
import { Field } from '../../types';
import { DynamicFormItem } from './DynamicFormItem';

type DynamicFormItemProps = {
  fields?: Field[];
  namespace?: string[];
};

export const DynamicForm = ({ fields, namespace }: DynamicFormItemProps) => (
  <Row gutter={24}>
    {fields?.map((field) => (
      <DynamicFormItem key={field.id || field.position} field={field} namespace={namespace} />
    ))}
  </Row>
);
