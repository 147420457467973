import { useCallback } from 'react';
import { To, useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { App, Form, Modal } from 'antd';

import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { useGoBack } from '@gowgates/core';

import { ApiKey } from '../../../types';
import { updateApiKey } from '../../../api/endpoints';
import { ApiKeyFormFields } from '../../../components/ApiKeyFormFields';
import { EDIT_API_KEY_FORM_ID } from './configs';

export const EditApiKeyModal = () => {
  const { message } = App.useApp();
  const apiKeyId = useParams<{ apiKeyId: string }>().apiKeyId;
  const queryClient = useQueryClient();
  const [form] = Form.useForm<ApiKey>();
  const { goBack, navigate } = useGoBack();

  const closeModal = useCallback(
    (to?: To) => {
      form.resetFields();
      to ? navigate(to) : goBack({ fallback: window.location.pathname });
    },
    [form, navigate, goBack]
  );

  const apiKeys = queryClient.getQueryData<ApiKey[]>(['apiKeys']);
  const apiKey = apiKeys?.find((w) => String(w.id) === apiKeyId);

  const { isPending, mutate } = useMutation({
    mutationFn: (values: Partial<ApiKey>) => updateApiKey(apiKeyId, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: () => {
      message.success(t('apiKey.saved'));
      queryClient.invalidateQueries({ queryKey: ['apiKeys'] });

      closeModal();
    }
  });

  return (
    <Modal
      title={apiKey?.name}
      onCancel={() => closeModal()}
      okButtonProps={{
        loading: isPending,
        htmlType: 'submit',
        form: EDIT_API_KEY_FORM_ID
      }}
      open
      okText={t('globals.save')}
      cancelText={t('globals.cancel')}
    >
      <Form
        id={EDIT_API_KEY_FORM_ID}
        onFinish={mutate}
        layout="vertical"
        form={form}
        disabled={isPending}
        initialValues={apiKey}
      >
        <ApiKeyFormFields />
      </Form>
    </Modal>
  );
};
