import { Alert } from 'antd';
import { FrownOutlined } from '@ant-design/icons';

import { t } from '@gowgates/utils';

import { ClaimWithStructure } from '../../../../types';

type ClaimComplaintProps = {
  claim: ClaimWithStructure;
};

const ClaimComplaint = ({ claim }: ClaimComplaintProps) => {
  if (!claim.complaint) {
    return null;
  }

  return (
    <Alert
      message={t('claim.complaintExplanation', {
        type: t(`claim.complaints.${claim.complaint}`).toLowerCase()
      })}
      type="error"
      showIcon
      icon={<FrownOutlined />}
    />
  );
};

export default ClaimComplaint;
