import React from 'react';
import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { t } from '@gowgates/utils';

import { Item } from '../../../../types';

const statuses: { [key in Item['status']]: string | undefined } = {
  paid: 'green',
  declined: 'error',
  withdrawn: 'warning',
  draft: undefined,
  submitted: undefined
};

const icons: { [key in Item['status']]: React.ReactNode | undefined } = {
  paid: <CheckCircleOutlined />,
  declined: <CloseCircleOutlined />,
  draft: undefined,
  submitted: undefined,
  withdrawn: undefined
};

type ItemStatusTagProps = {
  item: Item;
};

const ItemStatusTag = ({ item }: ItemStatusTagProps) => (
  <Tag icon={icons[item.status]} color={statuses[item.status]}>
    {t(`item.statuses.${item.status}`)}
  </Tag>
);

export default ItemStatusTag;
