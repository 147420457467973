import { Document, DocumentStructure } from '../../../types';

type DocumentDescriptionProps = {
  documents?: Document[];
  structure: DocumentStructure;
};
const DocumentDescription = ({ documents = [], structure }: DocumentDescriptionProps) => {
  const document = documents.find((doc) => doc.type === structure.name);

  return (
    <>
      <dt>{structure.label}</dt>
      <dd>
        {document?.file ? (
          <a href={document.file.url} target="_blank" rel="noopener noreferrer">
            {document.file.name}
          </a>
        ) : (
          '-'
        )}
      </dd>
    </>
  );
};

export default DocumentDescription;
