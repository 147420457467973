import { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { RouterProvider } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';
import cloneDeep from 'lodash.clonedeep';

import { Loader, useBrandConfigs } from '@gowgates/core';

import { useTenantQuery } from '../../hooks/useTenantQuery';
import { SimpleTenant } from '../../types';

type TenantProviderProps = {
  router: ReturnType<typeof createBrowserRouter>;
  tenantQueryFn: () => Promise<SimpleTenant>;
};
export const TenantProvider = ({ router, tenantQueryFn }: TenantProviderProps) => {
  const { isLoading, tenant } = useTenantQuery(tenantQueryFn);
  const { setBrandConfigs } = useBrandConfigs();

  useEffect(() => {
    if (!tenant) return;

    const configs = cloneDeep(tenant);
    if (configs?.logo && tenant.name) {
      configs.logo.alt = tenant.name;
    }

    setBrandConfigs(configs);
  }, [setBrandConfigs, tenant]);

  if (isLoading) {
    return <Loader />;
  }

  const tenantTheme = tenant && {
    token: {
      colorPrimary: tenant.primaryColor,
      colorLink: tenant.primaryColor
    }
  };

  return (
    <ConfigProvider theme={tenantTheme}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
};
