import { Form, Input, Select } from 'antd';

import { Field, FormItem } from '@gowgates/dynamic-fields';

type FieldExpressionProps = {
  fields?: Field[];
};

const FieldExpression = ({ fields = [] }: FieldExpressionProps) => (
  <Form.Item
    noStyle
    shouldUpdate={(prevValues, currentValues) => prevValues.evalType !== currentValues.evalType}
  >
    {({ getFieldValue }) =>
      getFieldValue('evalType') === 'direct' ? (
        <FormItem name="fieldName" model="reportField">
          <Select options={fields} showSearch fieldNames={{ value: 'name', options: 'false' }} />
        </FormItem>
      ) : (
        <FormItem name="fieldExpression" model="reportField">
          <Input />
        </FormItem>
      )
    }
  </Form.Item>
);

export default FieldExpression;
