import { t } from '@gowgates/utils';

import { ButtonAction, TaskStructure } from '../../../types';

type ButtonActionColProps = {
  buttonAction: ButtonAction;
  taskStructure: TaskStructure;
  taskStructures?: TaskStructure[];
};
const ButtonActionCol = ({
  buttonAction,
  taskStructure,
  taskStructures = []
}: ButtonActionColProps) => {
  const findTaskStructure = (id: number) =>
    taskStructures?.find((taskStructure) => taskStructure.id === id)?.name;

  if (buttonAction.type === 'system') {
    return t(`buttonAction.actions.${buttonAction.action}`);
  }

  if (buttonAction.type === 'abort_task' || buttonAction.type === 'create_task') {
    return findTaskStructure(buttonAction.relatedTaskStructureId);
  }

  return (
    <>
      {t(`activerecord.attributes.${taskStructure.entity}.${buttonAction.field}`)}
      {buttonAction.fieldChangeType === 'hardcoded' ? (
        <>
          <>: </>
          {buttonAction.fieldValue}
        </>
      ) : (
        <>
          <>: (</>
          {t(`buttonAction.fieldChangeTypes.${buttonAction.fieldChangeType}`)}
          <>)</>
        </>
      )}
    </>
  );
};

export default ButtonActionCol;
