import React from 'react';
import { Button, Space } from 'antd';
import { t } from '@gowgates/utils';

type AnyFunction = () => void;

type CustomTableFilterProps = {
  clearFilters: AnyFunction;
  confirm: AnyFunction;
  handleReset: (clearFilters: AnyFunction, confirm: AnyFunction) => void;
  disabled: boolean;
  children: React.ReactNode;
};

export const CustomTableFilter = ({
  clearFilters,
  confirm,
  handleReset,
  disabled,
  children
}: CustomTableFilterProps) => (
  <div
    className="table-custom-filter"
    onKeyDown={(e) => e.stopPropagation()}
    role="menuitem"
    tabIndex={0}
  >
    <div className="custom-filter-input">{children}</div>
    <Space className="d-flex justify-content-between">
      <Button
        onClick={() => clearFilters && handleReset(clearFilters, confirm)}
        size="small"
        type="link"
        disabled={disabled}
      >
        {t('globals.reset')}
      </Button>
      <Button type="primary" onClick={() => confirm()} size="small">
        {t('globals.ok')}
      </Button>
    </Space>
  </div>
);
