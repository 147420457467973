import { Row, Form, Checkbox, Button, App } from 'antd';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { Permission } from '@gowgates/core';

import { getPermission, updatePermission } from '../../api/endpoints';
import InnerBox from '../../components/InnerBox';
import { showSavedMessage } from '../../utils/messages';
import FullLoader from '../../components/FullLoader';
import { PermissionValue } from './PermissionValue';

const PermissionForm = () => {
  const { message } = App.useApp();
  const permissionId = Number(useParams().permissionId);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { isLoading, data: permission } = useQuery({
    queryKey: ['permission', permissionId],
    queryFn: () => getPermission(permissionId)
  });

  const { isPending: isMutating, mutate } = useMutation({
    mutationFn: (values: Permission) => updatePermission(permissionId, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['myPermissions'] });
      queryClient.setQueryData(['permission', permissionId], data);
      showSavedMessage(message);
    }
  });

  if (isLoading) {
    return <FullLoader />;
  }

  if (!permission) return;

  const isDisabled = isMutating || !permission.permissions.update;

  return (
    <Form form={form} onFinish={mutate} initialValues={permission} disabled={isDisabled}>
      <Form.Item name="settings">
        <Checkbox.Group style={{ width: '100%' }}>
          <InnerBox title="Dashboard" className="w-100">
            <Row>
              <PermissionValue name="global_statistics" />
              <PermissionValue name="users_statistics" />
            </Row>
          </InnerBox>

          <InnerBox title="Claims" className="w-100">
            <Row>
              <PermissionValue name="claims_index" />
              <PermissionValue name="claims_show" />
              <PermissionValue name="claims_create" />
              <PermissionValue name="claims_update" />
              <PermissionValue name="claims_destroy" />
              <PermissionValue name="claims_assign_to_me" />
              <PermissionValue name="claims_assign" />
              <PermissionValue name="claims_update_insurer_number" />
              <PermissionValue name="claims_update_bank_details" />
              <PermissionValue name="claims_update_reserve" />
              <PermissionValue name="claims_add_complaint" />
              <PermissionValue name="claims_remove_complaint" />
              <PermissionValue name="claims_add_importance" />
              <PermissionValue name="claims_remove_importance" />
              <PermissionValue name="claims_show_data" />
              <PermissionValue name="claims_show_client_details" />
              <PermissionValue name="claims_show_bank_details" />
              <PermissionValue name="claims_show_personal_details" />
              <PermissionValue name="claims_show_documents" />
              <PermissionValue name="claims_create_documents" />
              <PermissionValue name="claims_update_documents" />
              <PermissionValue name="claims_destroy_documents" />
              <PermissionValue name="claims_show_items" />
              <PermissionValue name="claims_create_items" />
              <PermissionValue name="claims_update_items" />
              <PermissionValue name="claims_destroy_items" />
              <PermissionValue name="claims_create_sections" />
              <PermissionValue name="claims_update_sections" />
              <PermissionValue name="claims_destroy_sections" />
              <PermissionValue name="claims_show_finance" />
              <PermissionValue name="claims_show_notes" />
              <PermissionValue name="claims_create_notes" />
              <PermissionValue name="claims_export_notes" />
              <PermissionValue name="claims_destroy_notes" />
              <PermissionValue name="claims_show_tasks" />
              <PermissionValue name="claims_create_tasks" />
              <PermissionValue name="claims_update_tasks" />
              <PermissionValue name="claims_show_other_roles_tasks" />
              <PermissionValue name="claims_complete_other_roles_tasks" />
              <PermissionValue name="claims_cancel_other_roles_tasks" />
              <PermissionValue name="claims_show_others_tasks" />
              <PermissionValue name="claims_complete_others_tasks" />
              <PermissionValue name="claims_cancel_others_tasks" />
              <PermissionValue name="claims_reassign_tasks" />
              <PermissionValue name="claims_reassign_tasks_to_me" />
              <PermissionValue name="claims_reopen" />
              <PermissionValue name="claims_export" />
              <PermissionValue name="claims_export_tasks" />
              <PermissionValue name="claims_resend_emails" />
              <PermissionValue name="claims_withdraw" />
            </Row>
          </InnerBox>

          <InnerBox title="Users" className="w-100">
            <Row>
              <PermissionValue name="users_index" />
              <PermissionValue name="users_show" />
              <PermissionValue name="users_create" />
              <PermissionValue name="users_update" />
              <PermissionValue name="users_update_role" />
              <PermissionValue name="users_show_scopes" />
              <PermissionValue name="users_update_scopes" />
              <PermissionValue name="users_show_permissions" />
              <PermissionValue name="users_update_permissions" />
              <PermissionValue name="users_reset_password" />
              <PermissionValue name="users_disable_otp" />
              <PermissionValue name="users_block" />
              <PermissionValue name="users_unblock" />
              <PermissionValue name="users_send_confirmation" />
              <PermissionValue name="users_confirm" />
              <PermissionValue name="users_send_unlock" />
              <PermissionValue name="users_unlock" />
            </Row>
          </InnerBox>

          <InnerBox title="Reports" className="w-100">
            <Row>
              <PermissionValue name="claims_all" />
              <PermissionValue name="claims_export_all" />
            </Row>
          </InnerBox>

          <InnerBox title="User roles" className="w-100">
            <Row>
              <PermissionValue name="roles_show" />
              <PermissionValue name="roles_create" />
              <PermissionValue name="roles_update" />
              <PermissionValue name="roles_destroy" />
            </Row>
          </InnerBox>

          <InnerBox title="Scopes" className="w-100">
            <Row>
              <PermissionValue name="scopes_show" />
              <PermissionValue name="scopes_create" />
              <PermissionValue name="scopes_update" />
              <PermissionValue name="scopes_destroy" />
            </Row>
          </InnerBox>

          <InnerBox title="Permissions" className="w-100">
            <Row>
              <PermissionValue name="permissions_show" />
              <PermissionValue name="permissions_create" />
              <PermissionValue name="permissions_update" />
              <PermissionValue name="permissions_destroy" />
            </Row>
          </InnerBox>

          <InnerBox title="Integrations" className="w-100">
            <Row>
              <PermissionValue name="integrations_show" />
              <PermissionValue name="integrations_create" />
              <PermissionValue name="integrations_update" />
              <PermissionValue name="integrations_destroy" />
            </Row>
          </InnerBox>

          <InnerBox title="File Config" className="w-100">
            <Row>
              <PermissionValue name="file_config_manage" />
            </Row>
          </InnerBox>

          <InnerBox title="Claim structures" className="w-100">
            <Row>
              <PermissionValue name="claim_structures_manage" />
            </Row>
          </InnerBox>

          <InnerBox title="Processes" className="w-100">
            <Row>
              <PermissionValue name="journeys_manage" />
            </Row>
          </InnerBox>

          <InnerBox title="Lists" className="w-100">
            <Row>
              <PermissionValue name="list_items_manage" />
            </Row>
          </InnerBox>

          <InnerBox title="Payments" className="w-100">
            <Row>
              <PermissionValue name="list_payments" />
              <PermissionValue name="export_payments" />
            </Row>
          </InnerBox>

          <InnerBox title="Tenant" className="w-100">
            <Row>
              <PermissionValue name="update_tenant" />
              <PermissionValue name="manage_api_keys" />
              <PermissionValue name="manage_webhooks" />
            </Row>
          </InnerBox>

          <InnerBox title="Email templates" className="w-100">
            <Row>
              <PermissionValue name="email_templates_index" />
              <PermissionValue name="email_templates_show" />
              <PermissionValue name="email_templates_create" />
              <PermissionValue name="email_templates_update" />
              <PermissionValue name="email_templates_destroy" />
            </Row>
          </InnerBox>
        </Checkbox.Group>
      </Form.Item>

      <div className="d-flex justify-content-end">
        {permission.permissions.update && (
          <Button type="primary" htmlType="submit" loading={isDisabled}>
            {t('globals.save')}
          </Button>
        )}
      </div>
    </Form>
  );
};

export default PermissionForm;
