import { useMatch } from 'react-router';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';

import { t } from '@gowgates/utils';

const ScopeNav = () => {
  const match = useMatch({ path: '/scopes/:scopeId/:tab', end: false });

  return (
    <Menu
      selectedKeys={match?.params?.tab ? [match?.params?.tab] : undefined}
      mode="horizontal"
      className="box-menu"
      items={[
        {
          key: 'scopes',
          label: <Link to="scopes">{t('globals.configurations')}</Link>
        },
        {
          key: 'users',
          label: <Link to="users">{t('activerecord.models.user.other')}</Link>
        }
      ]}
    />
  );
};

export default ScopeNav;
