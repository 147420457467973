import { Input } from 'antd';

import { FormItem, DynamicForm } from '@gowgates/dynamic-fields';

import useIntegrations from '../../../hooks/useIntegrations';
import useFileConfig from '../../../hooks/useFileConfig';

type ClientDetailsFormProps = {
  nested?: boolean;
};

const ClientDetailsForm = ({ nested = false }: ClientDetailsFormProps = {}) => {
  const { clientStructure } = useFileConfig();
  const { hasInsightIntegrations } = useIntegrations();
  const dynamicNamespace = nested ? ['client', 'data'] : ['data'];
  const fixedNamespace = nested ? ['client'] : [];

  return (
    <>
      {hasInsightIntegrations && (
        <>
          <FormItem name={[...fixedNamespace, 'insightId']} model="client">
            <Input disabled />
          </FormItem>

          <FormItem name={[...fixedNamespace, 'insightLedger']} hidden>
            <Input />
          </FormItem>

          <FormItem name={[...fixedNamespace, 'insightSalesTeamId']} model="client" hidden>
            <Input />
          </FormItem>

          <FormItem name={[...fixedNamespace, 'insightSalesTeamName']} model="client">
            <Input disabled />
          </FormItem>

          <FormItem name={[...fixedNamespace, 'insightServiceTeamId']} model="client" hidden>
            <Input />
          </FormItem>

          <FormItem name={[...fixedNamespace, 'insightServiceTeamName']} model="client">
            <Input disabled />
          </FormItem>
        </>
      )}

      <DynamicForm fields={clientStructure} namespace={dynamicNamespace} />
    </>
  );
};

export default ClientDetailsForm;
